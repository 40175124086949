import React from 'react'
import { AxiosPromise } from 'axios'

import { ExerciseTransition, Typography, ScoreResponse, ArrowRight } from '../..'
import { GetSanaScoreOptions } from '../../types'
import { onboardingExercises } from './onboardingExercises'
import './OnboardingBook.scss'
import { onRightOrLeftKeyboardArrows } from '../../utils'

interface OnboardingBookProps {
  onFinish: () => void
  country?: string
  getSanaScore: (options: GetSanaScoreOptions) => AxiosPromise<ScoreResponse>
  withDetailedScore?: boolean
  onMount?: () => void
}

interface IState {
  index: number
}

class OnboardingBook extends React.Component<OnboardingBookProps, IState> {
  state: IState = {
    index: 0
  }
  private keyBindings: any

  componentDidMount() {
    if (this.props.withDetailedScore) {
      this.keyBindings = this.bindKeyboardArrows()
    }
    if (this.props.onMount) this.props.onMount()
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.keyBindings)
  }

  next = () => {
    this.setState(({ index }) => {
      if (index + 1 > onboardingExercises.length - 1) {
        this.props.onFinish()
        return { index }
      } else {
        return { index: index + 1 }
      }
    })
  }

  prev = () => {
    this.setState(({ index }) => {
      if (index > 0) {
        return { index: index - 1 }
      }
    })
  }

  bindKeyboardArrows = () =>
    onRightOrLeftKeyboardArrows(
      () => {
        // right
        this.next()
      },
      () => {
        // left
        this.prev()
      }
    )

  render() {
    const { getSanaScore, withDetailedScore, country } = this.props
    return (
      <div className="onboarding-book">
        <ExerciseTransition
          currentIndex={this.state.index}
          country={country}
          trackExerciseActivity={() => {}}
          onCurrentExerciseCompleted={this.props.withDetailedScore ? () => {} : this.next}
          exercises={onboardingExercises}
          getSanaScore={getSanaScore}
          displayDetailedScore={withDetailedScore}
        />
        {withDetailedScore && (
          <div className="onboarding-book__tip">
            <Typography variant="body">{'⇦ Navigate between exercises with arrows ⇨'}</Typography>
          </div>
        )}
        <div className="onboarding-book__navigation">
          <ArrowRight onClick={this.prev} />
          <ArrowRight onClick={this.next} />
        </div>
      </div>
    )
  }
}

export default OnboardingBook
