import React, { useState, useCallback, useEffect } from 'react'

import { ComprehensionExerciseData, InjectedSoundProps, CharacterMood } from '../../../types'
import { Typography, LottieCharacter, withSoundEffectsContext } from '../../../index'
import { ExerciseBaseProps } from '../types'

import './ComprehensionExercise.scss'

interface IAnswerProps {
  option: string
  answer: number
  index: number
  disable: boolean
  onWrongAnswer: () => void
  onGoodAnswer: () => void
}

const Answer: React.FC<IAnswerProps> = ({ option, answer, index, onGoodAnswer, onWrongAnswer, disable }) => {
  const [state, setState] = useState<'--correct' | '--wrong' | ''>('')
  const checkAnswer = useCallback(
    (option: number) => {
      if (option === answer) {
        setState('--correct')
        onGoodAnswer()
      } else {
        setState('--wrong')
        onWrongAnswer()
      }
    },
    // eslint-disable-next-line
    [option, answer, onWrongAnswer]
  )

  const finishAnimation = useCallback(() => {
    setState((prevState) => (prevState === '--correct' ? prevState : ''))
  }, [])

  const className = `comprehension-exercise__answer${state}`

  return (
    <button
      disabled={disable}
      className={className}
      onClick={() => checkAnswer(index)}
      onAnimationEnd={finishAnimation}>
      <Typography variant={window.innerWidth < 600 ? 'exerciseS' : 'exerciseM'}>{option}</Typography>
    </button>
  )
}

interface IProps extends ExerciseBaseProps, InjectedSoundProps {}

interface IState {
  answersCount: number
  characterMood: CharacterMood
  answeredCorrectly: boolean
}

const ComprehensionExercise: React.FC<IProps> = ({ exercise, onCurrentExerciseCompleted, playSound }) => {
  const { answer, options, question } = exercise.data as ComprehensionExerciseData
  const [state, setState] = useState<IState>({
    answersCount: 0,
    characterMood: 'idle',
    answeredCorrectly: false
  })

  const changeCharacterMood = (mood: CharacterMood) => {
    setState((state) => ({ ...state, characterMood: mood }))
  }

  const delayedNextExerciseTransition = useCallback(() => {
    setState((s) => ({
      ...s,
      characterMood: 'excited',
      answeredCorrectly: true
    }))
    playSound('correct')
    playSound('exercise-finished', () => changeCharacterMood('idle'))

    const score = (1 / (state.answersCount + 1)) * 100

    setTimeout(() => onCurrentExerciseCompleted(~~score), 2000)
    // eslint-disable-next-line
  }, [state.answersCount])

  useEffect(() => {
    setState({
      answersCount: 0,
      characterMood: 'idle',
      answeredCorrectly: false
    })
  }, [exercise])

  return (
    <div className="comprehension-exercise">
      <Typography variant={window.innerWidth < 600 ? 'exerciseM' : 'exerciseL'}>{question}</Typography>
      <div className="comprehension-exercise__answers">
        {options.map((option, index) => (
          <Answer
            key={option}
            answer={answer}
            index={index}
            option={option}
            disable={state.answeredCorrectly}
            onWrongAnswer={() => {
              playSound('incorrect')
              setState((state) => ({
                ...state,
                characterMood: 'wrong',
                answersCount: state.answersCount + 1
              }))
            }}
            onGoodAnswer={delayedNextExerciseTransition}
          />
        ))}
      </div>
      <LottieCharacter
        mood={state.characterMood}
        changeCharacterMood={changeCharacterMood}
        characterName="Abbe"
        className="comprehension-exercise__character"
      />
    </div>
  )
}

export default withSoundEffectsContext(ComprehensionExercise)
