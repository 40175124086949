import { Button, Typography } from '@astrid/components'
import { makeStyles } from '@material-ui/core'
import { CheckCircleRounded } from '@material-ui/icons'
import { Colors } from 'MuiTheme'
import React from 'react'
import { useHistory } from 'react-router'
import { ROUTES } from 'routes/routes'

interface Props {
  title: string
  learnerId: string
  onBackToProfile?: () => void
  onBackToTaskList?: () => void
}

const useStyles = makeStyles((theme) => ({
  completed: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(4)
  },
  checkCircle: {
    color: Colors.green,
    fontSize: '10rem'
  }
}))

export const Confirmation = ({ title, learnerId, onBackToProfile, onBackToTaskList }: Props) => {
  const classes = useStyles()
  const history = useHistory()

  const handleBackToProfileClick = () => {
    onBackToProfile?.()
    history.push(`${ROUTES.LEARNERS}/${learnerId}`)
  }

  const handleBackToTaskListClick = () => {
    onBackToTaskList?.()
    history.push(ROUTES.TASKS)
  }

  return (
    <div className={classes.completed}>
      <CheckCircleRounded className={classes.checkCircle} />
      <Typography variant="h3">{title}</Typography>
      <div className={classes.buttons}>
        <Button size="small" variant="outlined" onClick={handleBackToProfileClick}>
          Back to learner profile
        </Button>
        <Button size="small" color="action" onClick={handleBackToTaskListClick}>
          Back to task list
        </Button>
      </div>
    </div>
  )
}
