import { EVENT } from 'analytics/events'

interface Step {
  title: string
  task: string
  clickEvent: EVENT
}

export const steps: Step[] = [
  {
    title: 'Self-study results',
    task: 'Review self-study results',
    clickEvent: EVENT.TUTOR_CLICK_TASK_SELF_STUDY_RESULTS
  },
  {
    title: 'Speaking Challenge',
    task: 'Review Speaking Challenge',
    clickEvent: EVENT.TUTOR_CLICK_TASK_SPEAKING_CHALLENGE
  },
  {
    title: 'Feedback video',
    task: 'Record and send feedback video',
    clickEvent: EVENT.TUTOR_CLICK_TASK_FEEDBACK_VIDEO
  },
  {
    title: 'Speaking Challenge reformulation',
    task: 'Review Speaking Challenge reformulation',
    clickEvent: EVENT.TUTOR_CLICK_TASK_SPEAKING_CHALLENGE_REFORMULATION
  },
  {
    title: 'Performance evaluation',
    task: 'Evaluate performance',
    clickEvent: EVENT.TUTOR_CLICK_TASK_PERFORMANCE_EVALUATION
  }
]
