import { Button } from '@astrid/components'
import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AddRounded, Delete, RefreshRounded } from '@material-ui/icons'
import { AxiosRequestConfig } from 'axios'
import { ConfirmPopup } from 'components/ConfirmPopup/ConfirmPopup'
import { Modal } from 'components/Modal/Modal'
import { UploadButton } from 'components/UploadButton/UploadButton'
import React, { useState } from 'react'
import { VideoPreview } from './VideoPreview'
import { aspectRatio, VideoRecorder } from './VideoRecorder'

const useStyles = makeStyles((theme) => ({
  video: {
    width: 216,
    display: 'block',
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius
  },
  deleteButton: {
    marginLeft: 'auto',
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    width: 216,
    height: 216 / aspectRatio
  },
  videoRecorder: {
    width: `calc((100vh - 100px) * ${aspectRatio})`,
    height: `calc(100vh - 100px)`,
    minHeight: 280,
    minWidth: 280 * aspectRatio,
    maxHeight: 720,
    maxWidth: 720 * aspectRatio
  }
}))

interface Props {
  file?: string
  text?: string
  onUpload: (video: File, config?: AxiosRequestConfig) => Promise<void>
  onRemove?: () => Promise<void>
}

type UploadState =
  | { type: 'initial' }
  | { type: 'uploading'; progress: number }
  | { type: 'uploaded'; videoUrl: string }
  | { type: 'error'; data: { file: File; url: string } }

export const VideoUploader = ({ file, onUpload, onRemove, text }: Props) => {
  const classes = useStyles()
  const [uploadState, setUploadState] = useState<UploadState>(
    file ? { type: 'uploaded', videoUrl: file } : { type: 'initial' }
  )
  const [showModal, setShowModal] = useState(false)
  const [isDeletingVideo, setIsDeletingVideo] = useState(false)

  const handleSubmit = async (videoData: File, videoUrl: string) => {
    setShowModal(false)

    const config = {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const percentage = Math.floor((progressEvent.loaded / videoData.size) * 100)
        setUploadState({ type: 'uploading', progress: percentage })
      }
    }

    try {
      await onUpload(videoData, config)
      setUploadState({ type: 'uploaded', videoUrl })
    } catch (error) {
      setUploadState({ type: 'error', data: { file: videoData, url: videoUrl } })
    }
  }

  const handleRemove = async () => {
    if (onRemove) {
      try {
        setIsDeletingVideo(true)
        await onRemove()
        setUploadState({ type: 'initial' })
      } catch {
      } finally {
        setIsDeletingVideo(false)
      }
    }
  }

  return uploadState.type === 'uploaded' ? (
    <>
      <VideoPreview src={uploadState.videoUrl} className={classes.video} />
      {!!onRemove && (
        <ConfirmPopup
          onConfirm={handleRemove}
          text="Are you sure you want to delete the video?"
          disabled={isDeletingVideo}
          cancelText="No"
          confirmText="Yes">
          <Button
            variant="outlined"
            label="Delete video"
            icon={<Delete fontSize="small" />}
            size="small"
            className={classes.deleteButton}
            disabled={isDeletingVideo}
          />
        </ConfirmPopup>
      )}
    </>
  ) : (
    <>
      <UploadButton
        onClick={() =>
          uploadState.type === 'error' ? handleSubmit(uploadState.data.file, uploadState.data.url) : setShowModal(true)
        }
        disabled={uploadState.type === 'uploading'}
        className={classes.uploadButton}>
        {uploadState.type === 'initial' && (
          <>
            <AddRounded fontSize="large" />
            <span>{text === undefined ? 'Upload video' : text}</span>
          </>
        )}
        {uploadState.type === 'uploading' && (
          <>
            <CircularProgress value={uploadState.progress} size={24} color="inherit" variant="determinate" />
            <span>Uploading...</span>
          </>
        )}
        {uploadState.type === 'error' && (
          <>
            <RefreshRounded fontSize="large" />
            <span>Try uploading again</span>
          </>
        )}
      </UploadButton>
      {uploadState.type === 'error' && (
        <Button
          variant="outlined"
          label="Cancel"
          size="small"
          className={classes.deleteButton}
          onClick={() => setUploadState({ type: 'initial' })}
        />
      )}
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <VideoRecorder onSubmit={handleSubmit} className={classes.videoRecorder} />
      </Modal>
    </>
  )
}
