import { Typography } from '@astrid/components'
import { Grid, makeStyles, TextField } from '@material-ui/core'
import { CheckRounded } from '@material-ui/icons'
import { ScreenNames, trackButtonClickEvent, trackOptionSelectEvent, trackTypingEvent } from 'analytics/analytics'
import { AlertContext } from 'components/AlertContext/AlertContext'
import { DefinitionListItem } from 'components/DefinitionListItem/DefinitionListItem'
import { RadioOption } from 'components/RadioOption/RadioOption'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router'
import { useCreateEvaluationMutation } from 'store/services/StudySet/studySetApi'
import { StudySetEvaluation, StudySetModel } from 'store/services/StudySet/types'
import { Confirmation } from '../components/Confirmation'
import { Step, StepProps } from '../components/Step'

type DidPassValue = 'true' | 'false' | ''

interface Props extends StepProps {
  studySet: StudySetModel
  evaluation?: StudySetEvaluation
  objective: string
  onComplete?: () => void
}

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 0
  }
}))

export const Evaluation = ({ evaluation, objective, studySet, onComplete, ...rest }: Props) => {
  const classes = useStyles()
  const { showAlert } = useContext(AlertContext)
  const { studySetId, learnerId } = useParams<{ studySetId: string; learnerId: string }>()
  const [createEvaluation] = useCreateEvaluationMutation()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [feedbackValue, setFeedbackValue] = useState<string>(evaluation?.commentFromTutor || '')
  const [didPassValue, setDidPassValue] = useState<DidPassValue>(
    (evaluation ? evaluation.learnerMetGoal.toString() : '') as DidPassValue
  )

  useTrackScreenShown(isSubmitted ? ScreenNames.TutorSetCompleted : ScreenNames.PerformanceEvaluation)

  const isInvalidForm = didPassValue === '' || feedbackValue === ''

  const handleSubmit = async () => {
    if (!isInvalidForm) {
      try {
        await createEvaluation({
          id: studySetId,
          evaluation: {
            learnerMetGoal: didPassValue === 'true',
            commentFromTutor: feedbackValue
          }
        }).unwrap()

        onComplete?.()

        setIsSubmitted(true)
      } catch (error) {
        showAlert('Failed to send evaluation: ' + error)
      }
    }
  }

  const stepProps: StepProps = {
    title: isSubmitted ? '' : rest.title,
    nextButtonProps: {
      ...rest.nextButtonProps,
      onClick: evaluation || isSubmitted ? undefined : handleSubmit,
      hideIcon: true,
      disabled: isInvalidForm,
      title: (
        <>
          <span>Save and send to parent</span>
          <CheckRounded />
        </>
      )
    },
    previousButtonProps: {
      ...rest.previousButtonProps,
      onClick: isSubmitted ? undefined : rest.previousButtonProps?.onClick
    }
  }

  return (
    <Step {...stepProps}>
      {isSubmitted ? (
        <Confirmation
          title="Tutor Set completed!"
          learnerId={learnerId}
          onBackToProfile={() => trackButtonClickEvent({ componentName: 'back to learner profile' })}
          onBackToTaskList={() => trackButtonClickEvent({ componentName: 'back to task list' })}
        />
      ) : (
        <Grid container direction="column" spacing={4}>
          <Grid item xs={6}>
            <dl>
              <DefinitionListItem label="Learning goal" position="top">
                {objective}
              </DefinitionListItem>
            </dl>
            <Typography variant="h4" className={classes.heading}>
              How well did the learner achieve the learning goal in the Tutor Set?
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container direction="column" spacing={1}>
              {[
                { value: 'true', label: 'Mastered the learning goal' },
                { value: 'false', label: 'Needs more practice' }
              ].map((option, index) => (
                <Grid item xs={12} key={option.value}>
                  <RadioOption
                    name="grade"
                    id={`grade_${index}`}
                    onChange={() => {
                      setDidPassValue(option.value as DidPassValue)

                      trackOptionSelectEvent({
                        componentName: 'performance evaluation',
                        componentValue: option.value === 'true' ? 'mastered learning goal' : 'needs more practice'
                      })
                    }}
                    checked={didPassValue === option.value}
                    disabled={!!evaluation}>
                    {option.label}
                  </RadioOption>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="feedback"
              id="feedback"
              fullWidth
              required
              multiline
              minRows={10}
              size="small"
              variant="outlined"
              placeholder="Feedback on learner’s performance. This will also be sent to the parent."
              value={feedbackValue}
              onChange={(event) => setFeedbackValue(event.target.value)}
              onBlur={() => trackTypingEvent({ componentName: 'performance evaluation notes' })}
              disabled={!!evaluation}
            />
          </Grid>
        </Grid>
      )}
    </Step>
  )
}
