import { CloseIcon } from '@astrid/components'
import { Drawer as MuiDrawer, DrawerProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from 'MuiTheme'
import React, { PropsWithChildren } from 'react'

export type CloseReason = 'backdrop' | 'close button' | 'escape key'

interface Props extends DrawerProps {
  onClose: (reason: CloseReason) => void
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 60,
    height: '100%',
    width: 950,
    borderLeft: `2px solid ${Colors.lightGrey}`,
    padding: `0 ${theme.spacing(6)}px ${theme.spacing(2)}px ${theme.spacing(6)}px`
  },
  closeButton: {
    position: 'absolute',
    top: 60 + theme.spacing(1),
    right: theme.spacing(2),
    width: 40,
    height: 40,
    borderRadius: '50%',
    cursor: 'pointer'
  }
}))

export const Drawer = ({ open, onClose, children }: PropsWithChildren<Props>) => {
  const classes = useStyles()

  return (
    <MuiDrawer
      open={open}
      onClose={(_, reason) => onClose(reason === 'backdropClick' ? 'backdrop' : 'escape key')}
      anchor="right">
      <div className={classes.wrapper}>
        {children}
        <CloseIcon
          className={classes.closeButton}
          role="button"
          onClick={() => onClose('close button')}
          aria-label="Close"
        />
      </div>
    </MuiDrawer>
  )
}
