import React, { useState, useEffect } from 'react'

import Typography from '../Typography/Typography'

interface IProps {
  seconds: number
  className?: string
  restartCountdown?: any
  delayCountdown?: number
}

let countdownInterval: any

const Countdown: React.FC<IProps> = ({ seconds, className, restartCountdown, delayCountdown, children }) => {
  const [count, setCount] = useState(seconds)

  const start = () => {
    setCount(seconds)
    setTimeout(
      () => {
        countdownInterval = setInterval(() => {
          setCount((prevCount) => {
            const newCount = prevCount - 1
            if (newCount <= 0) {
              clearInterval(countdownInterval)
              return 0
            } else {
              return newCount
            }
          })
        }, 1000)
      },
      delayCountdown ? delayCountdown : 0
    )
  }

  useEffect(start, [restartCountdown])

  useEffect(() => {
    return clearInterval(countdownInterval)
  }, [])

  if (count > 0) {
    return (
      <Typography className={className} variant="exerciseXL">
        {count}
      </Typography>
    )
  } else return <>{children}</>
}

export default Countdown
