import React from 'react'

import { WordRaceExerciseData, InjectedSoundProps, CharacterMood } from '../../../types'
import {
  Typography,
  AudioRecorder,
  Countdown,
  LottieCharacter,
  withSoundEffectsContext,
  CheckmarkIcon
} from '../../../index'
import { ExerciseBaseProps } from '../types'

import './WordRaceExercise.scss'

const WORD_RACE_CONFIG = {
  MIN_SUCCESS_SCORE: 50,
  AUTO_RECORD_COUNTDOWN: 3 // [s]
}

const Word: React.FC<{ word: string; score: number }> = ({ word, score }) => {
  const isSuccess = score >= WORD_RACE_CONFIG.MIN_SUCCESS_SCORE
  const className = `word-race-exercise__word${isSuccess ? '--success' : '--wrong'}`
  return (
    <span className={className}>
      {isSuccess && <CheckmarkIcon />}
      <Typography variant="exerciseM">{word}</Typography>
    </span>
  )
}

interface IProps extends ExerciseBaseProps, InjectedSoundProps {}

interface IState {
  wordIndex: number
  wordScores: { word: string; score: number }[]
  displayWords: boolean
  displayResults: boolean
  characterMood: CharacterMood
}

class WordRaceExercise extends React.Component<IProps, IState> {
  state: IState = {
    wordIndex: 0,
    wordScores: [],
    displayWords: true,
    displayResults: false,
    characterMood: 'idle'
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.exercise._id !== this.props.exercise._id) {
      this.setState({
        displayWords: true,
        displayResults: false,
        wordIndex: 0,
        wordScores: [],
        characterMood: 'idle'
      })
    }

    if (this.state.displayResults && this.state.displayResults !== prevState.displayResults) {
      const { playSound, onCurrentExerciseCompleted } = this.props
      let correctAnswers = 0
      const { wordScores } = this.state
      wordScores.forEach((score) => {
        if (score.score >= WORD_RACE_CONFIG.MIN_SUCCESS_SCORE) correctAnswers += 1
      })
      const percentageScore = (correctAnswers / wordScores.length) * 100

      if (~~percentageScore >= 60) {
        this.changeCharacterMood('excited')
        playSound('exercise-finished', () => this.changeCharacterMood('idle'))
      } else {
        this.changeCharacterMood('wrong')
        playSound('incorrect')
      }

      setTimeout(() => {
        this.changeCharacterMood('idle')
        onCurrentExerciseCompleted(~~percentageScore)
      }, 4000)
    }
  }

  changeCharacterMood = (mood: CharacterMood) => {
    this.setState({
      characterMood: mood
    })
  }

  handleRateWord = async (recording: Blob, word: string) => {
    const { exercise } = this.props
    const { words } = exercise.data as WordRaceExerciseData

    this.setState(({ wordIndex }) => {
      const nextIndex = wordIndex + 1
      return {
        wordIndex: nextIndex,
        displayWords: nextIndex < words.length
      }
    })

    try {
      const {
        data: { overall_score }
      } = await this.props.getSanaScore({ audio: recording, phrase: word })
      this.setState(({ wordScores }) => {
        const updatedWordScores = [...wordScores, { word, score: overall_score ? overall_score : 0 }]
        return {
          wordScores: updatedWordScores,
          displayResults: words.length === updatedWordScores.length
        }
      })
    } catch (error) {
      this.setState(({ wordScores }) => {
        const updatedWordScores = [...wordScores, { word, score: 0 }]
        return {
          wordScores: updatedWordScores,
          displayResults: words.length === updatedWordScores.length
        }
      })
    }
  }

  render() {
    const { wordIndex, wordScores, displayWords, displayResults, characterMood } = this.state
    const { exercise } = this.props
    const { words } = exercise.data as WordRaceExerciseData

    return (
      <div className="word-race-exercise">
        <div className="word-race-exercise__content">
          <Countdown
            delayCountdown={800}
            className="word-race-exercise__content"
            seconds={3}
            restartCountdown={exercise._id}>
            {displayWords && <Typography variant="exerciseXL">{words[wordIndex]}</Typography>}
            {displayResults &&
              wordScores.map(({ score, word }, index) => <Word key={`${word}${index}`} score={score} word={word} />)}
            <AudioRecorder autoRecorder wordsToRecord={words} onStopWordRecording={this.handleRateWord} />
          </Countdown>
        </div>
        <LottieCharacter
          neverBored
          characterName="Alva"
          mood={characterMood}
          changeCharacterMood={this.changeCharacterMood}
          className="word-race-exercise__character"
        />
      </div>
    )
  }
}

export default withSoundEffectsContext(WordRaceExercise)
