import { PriorityHighRounded } from '@material-ui/icons'
import { DateLabel } from 'components/DateLabel/DateLabel'
import { getShortDateFormat, getTimeBetween } from 'helpers/Date/Date'
import React from 'react'

interface Props {
  deadline: number
  className?: string
}

export const Countdown = ({ deadline, className }: Props) => {
  let timeLeft = getTimeBetween(Date.now(), deadline)
  let variant: 'default' | 'overdue' | 'urgent' = 'default'

  if (timeLeft.startsWith('-')) {
    variant = 'overdue'
    timeLeft = timeLeft.substring(1)
  } else if (timeLeft.indexOf('minute') > -1 || timeLeft === '1 hour') {
    variant = 'urgent'
  }

  return (
    <DateLabel title={`Deadline: ${getShortDateFormat(deadline)}`} variant={variant} className={className}>
      {variant === 'overdue' && (
        <>
          <PriorityHighRounded /> Overdue:{' '}
        </>
      )}
      {timeLeft}
    </DateLabel>
  )
}
