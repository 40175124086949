import { configureStore } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'

export const baseApi = createApi({
  reducerPath: 'baseApi',
  tagTypes: ['Tutor', 'Tasks', 'ActiveTasks', 'ArchivedStudySets'],
  baseQuery,
  endpoints: () => ({})
})

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
  devTools: process.env.NODE_ENV !== 'production'
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
