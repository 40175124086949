import { Typography } from '@astrid/components'
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Avatar } from 'components/Avatar/Avatar'
import { Label } from 'components/Label/Label'
import { SlimCard } from 'components/SlimCard/SlimCard'
import { TableCellLink } from 'components/TableCellLink/TableCellLink'
import React from 'react'
import { ROUTES } from 'routes/routes'
import { ActiveLearnerModel } from 'store/services/Learner/types'

const useStyles = makeStyles((theme) => ({
  table: {
    '& tr:last-child td': {
      borderBottom: 0
    }
  },
  heading: {
    fontWeight: 'bold',
    lineHeight: 1
  },
  learner: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  headCell: {
    verticalAlign: 'bottom'
  }
}))

interface Props {
  learners: ActiveLearnerModel[]
}

export const LearnerTable = ({ learners }: Props) => {
  const classes = useStyles()

  return (
    <TableContainer component={(props) => <SlimCard {...props} noPadding />}>
      <Table aria-label="customized table" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headCell}>
              <Typography variant="body" className={classes.heading}>
                Name
              </Typography>
            </TableCell>
            <TableCell className={classes.headCell}>
              <Label>Placement test</Label>
              <Typography variant="body" className={classes.heading}>
                Level
              </Typography>
            </TableCell>
            <TableCell className={classes.headCell}>
              <Label>Tutor Sets</Label>
              <Typography variant="body" className={classes.heading}>
                Active
              </Typography>
            </TableCell>
            <TableCell className={classes.headCell}>
              <Label>Tutor Sets</Label>
              <Typography variant="body" className={classes.heading}>
                Completed
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {learners.map((learner) => (
            <TableRow key={learner._id} hover>
              <TableCell padding="none">
                <TableCellLink to={`${ROUTES.LEARNERS}/${learner._id}`}>
                  <div className={classes.learner}>
                    <Avatar size="small" url={learner.avatar} />
                    <Typography variant="body">
                      {learner.firstName} {learner.lastName}
                    </Typography>
                  </div>
                </TableCellLink>
              </TableCell>
              <TableCell padding="none">
                <TableCellLink to={`${ROUTES.LEARNERS}/${learner._id}`}>
                  <Typography variant="body">{learner.level}</Typography>
                </TableCellLink>
              </TableCell>
              <TableCell align="right" padding="none">
                <TableCellLink to={`${ROUTES.LEARNERS}/${learner._id}`}>
                  <Typography variant="body">{learner.lessonsActiveCount}</Typography>
                </TableCellLink>
              </TableCell>
              <TableCell align="right" padding="none">
                <TableCellLink to={`${ROUTES.LEARNERS}/${learner._id}`}>
                  <Typography variant="body">{learner.lessonsCompletedCount}</Typography>
                </TableCellLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
