import { IExercise } from '../../index'
import { ExerciseType } from '../../types/Exercise'

const demoBookExercises: IExercise[] = [
  {
    _id: 'demo0',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: 'Second star to the right.'
    }
  },
  {
    _id: 'demo1',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: 'Never is an awfully long time.'
    }
  },
  {
    _id: 'demo2',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: 'You’re braver than you believe.'
    }
  },
  {
    _id: 'demo3',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: 'One sees clearly only with the heart.'
    }
  },
  {
    _id: 'demo4',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: 'All you need is confidence in yourself.'
    }
  },
  {
    _id: 'demo5',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: 'This is a fierce bad rabbit.'
    }
  },
  {
    _id: 'demo6',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: 'I am the strongest girl in the world.'
    }
  },
  {
    _id: 'demo7',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: 'You are a dreamer.'
    }
  },
  {
    _id: 'demo8',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: "You're mad, bonkers, completely off your head."
    }
  },
  {
    _id: 'demo9',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: 'She was a real princess.'
    }
  },
  {
    _id: 'demo10',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: 'I knew who I was this morning.'
    }
  },
  {
    _id: 'demo11',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: 'Never do anything by halves.'
    }
  },
  {
    _id: 'demo12',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: 'With opportunity the world is very interesting.'
    }
  },
  {
    _id: 'demo13',
    type: ExerciseType.ReadSentence,
    points: 0,
    data: {
      sentence: "I'm not young enough to know everything."
    }
  }
]

export { demoBookExercises }
