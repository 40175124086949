export interface Book {
  _id: string
  exercises: string[] // array of exercises ids
  imageUrl?: string
  title: string
  visible: boolean
  order?: number
  author: string
  illustrator: string
  publisher: string
  difficultyLevel: number
  recommended: boolean
  totalPoints: number
  cefrLevel?: CEFRLevels
  hashtags: string[]
  keywords: string[]
  targetCountries: string[]
  bookType: BookType
  isAstridClassic?: boolean
}

export enum BookType {
  Reading = 'Reading',
  Listening = 'Listening',
  Mix = 'Mix'
}

export enum BookBranding {
  NONE = 'None',
  ASTRID_CLASSIC = 'Astrid Classic',
  WATERBEAR = 'Waterbear'
}

export enum CEFRLevels {
  A11 = 'A1.1',
  A12 = 'A1.2',
  A13 = 'A1.3',
  A21 = 'A2.1',
  A22 = 'A2.2',
  A23 = 'A2.3',
  B11 = 'B1.1',
  B12 = 'B1.2',
  B13 = 'B1.3',
  B21 = 'B2.1',
  B22 = 'B2.2',
  B23 = 'B2.3',
  C1plus = 'C1+'
}

export enum BookRate {
  GOOD = 'good',
  BAD = 'bad'
}
