import { InsightsModel } from './types'
import { baseApi } from 'store/store'

export const insightsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    loadInsights: builder.query<InsightsModel, string>({
      query: (userId) => ({ url: `/insight/${userId}/for-tutor?period=7days`, method: 'get' })
    }),

    loadStudySetInsights: builder.query<InsightsModel, string>({
      query: (studySetId) => ({ url: `/insight/for-lesson-instance/${studySetId}`, method: 'get' })
    })
  })
})

export const { useLoadInsightsQuery, useLoadStudySetInsightsQuery } = insightsApi
