import { Button, Typography } from '@astrid/components'
import { Grid, makeStyles } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { ScreenNames, trackButtonClickEvent, trackTypingEvent } from 'analytics/analytics'
import { AlertContext } from 'components/AlertContext/AlertContext'
import { AutoSaveField } from 'components/AutoSaveField/AutoSaveField'
import { ConfirmPopup } from 'components/ConfirmPopup/ConfirmPopup'
import { VideoPreview } from 'components/Video/VideoPreview'
import { aspectRatio, VideoRecorder } from 'components/Video/VideoRecorder'
import { useAppDispatch } from 'hooks/redux'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { Colors } from 'MuiTheme'
import React, { useContext, useRef, useState } from 'react'
import { useParams } from 'react-router'
import {
  studySetApi,
  useCreateSpeakingChallengeEntryMutation,
  useUpdateStudySetNotesMutation
} from 'store/services/StudySet/studySetApi'
import {
  MainTutorTaskType,
  SpeakingChallengeComponent,
  SpeakingChallengeLogEntry,
  StudySetModel
} from 'store/services/StudySet/types'
import { Confirmation } from '../components/Confirmation'
import { Step, StepProps } from '../components/Step'

const videoContainerWidth = 256
const videoContainerHeight = videoContainerWidth / aspectRatio

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
    '&:first-child': {
      marginTop: 0
    }
  },
  video: {
    borderRadius: theme.shape.borderRadius,
    width: videoContainerWidth
  },
  videoRecordingContainer: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    width: videoContainerWidth,
    height: videoContainerHeight,
    position: 'relative',
    background: Colors.veryLightGrey
  },
  deleteButton: {
    marginLeft: 'auto'
  }
}))

interface Props extends StepProps {
  studySet: StudySetModel
  screenName: ScreenNames
  speakingChallengeEntry?: SpeakingChallengeLogEntry
  task: SpeakingChallengeComponent['task']
  notes: string
  sendText?: string
  skipConfirmation?: boolean
  onPlayVideo?: () => void
  onPauseVideo?: () => void
  onStartRecording?: (attempt: number) => void
  onVideoSubmitted?: () => void
}

type VideoUploadStatus =
  | { type: 'idle' }
  | { type: 'uploading'; percentage: number }
  | { type: 'uploaded' }
  | { type: 'error'; error: string }

export const SpeakingChallenge = ({
  studySet,
  screenName,
  speakingChallengeEntry,
  task,
  notes,
  sendText,
  skipConfirmation,
  onPlayVideo,
  onPauseVideo,
  onStartRecording,
  onVideoSubmitted,
  ...rest
}: Props) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { showAlert } = useContext(AlertContext)
  const { studySetId, learnerId } = useParams<{ learnerId: string; studySetId: string }>()
  const [video, setVideo] = useState<{ file: File; url: string } | null>(null)
  const [videoUploadStatus, setVideoUploadStatus] = useState<VideoUploadStatus>({ type: 'idle' })
  const attempt = useRef(0)
  const [updateNotes] = useUpdateStudySetNotesMutation()
  const [createSpeakingChallengeEntry] = useCreateSpeakingChallengeEntryMutation()
  const isVideoUploaded = videoUploadStatus.type === 'uploaded'
  const shouldShowConfirmation = isVideoUploaded && !skipConfirmation

  useTrackScreenShown(
    screenName === ScreenNames.FeedbackVideo && shouldShowConfirmation ? ScreenNames.FeedbackVideoSent : screenName
  )

  const handleSaveNotes = async (notes: string) => {
    await updateNotes({ id: studySetId, notes }).unwrap()
    dispatch(
      studySetApi.util.updateQueryData('loadActiveTasks', learnerId, (drafts) => {
        const draftTask = drafts.find(
          (draft) =>
            draft.taskType === MainTutorTaskType.SpeakingChallengeFeedback && draft.lessonInstance._id === studySet._id
        )
        if (draftTask && draftTask.taskType === MainTutorTaskType.SpeakingChallengeFeedback) {
          draftTask.lessonInstance.tutorNotes = notes
        }
      })
    )
  }

  const handleNext = async () => {
    if (!video) {
      return
    }

    setVideoUploadStatus({ type: 'uploading', percentage: 0 })
    const formData = new FormData()
    formData.append('video', video.file)

    try {
      await createSpeakingChallengeEntry({
        id: studySetId,
        data: formData,
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentage = Math.floor((progressEvent.loaded / video.file.size) * 100)
          setVideoUploadStatus({ type: 'uploading', percentage })
        }
      }).unwrap()

      onVideoSubmitted?.()
      attempt.current = 0

      if (skipConfirmation && rest.nextButtonProps?.onClick) {
        rest.nextButtonProps.onClick()
        return
      }

      setVideoUploadStatus({ type: 'uploaded' })
    } catch (error) {
      const errorMessage = `Failed to upload video: ${error as string}`
      setVideoUploadStatus({ type: 'error', error: errorMessage })
      showAlert(errorMessage)
    }
  }

  const stepProps: StepProps =
    speakingChallengeEntry && !isVideoUploaded
      ? rest
      : {
          title: isVideoUploaded ? '' : rest.title,
          nextButtonProps: {
            ...rest.nextButtonProps,
            title:
              videoUploadStatus.type === 'uploading'
                ? `Sending... ${videoUploadStatus.percentage}%`
                : sendText ?? 'Send video',
            disabled: video === null,
            loading: videoUploadStatus.type === 'uploading' && { progressPercentage: videoUploadStatus.percentage },
            hideIcon: videoUploadStatus.type === 'uploading',
            onClick: isVideoUploaded ? undefined : handleNext
          },
          previousButtonProps: {
            ...rest.previousButtonProps,
            disabled: videoUploadStatus.type === 'uploading',
            onClick: isVideoUploaded ? undefined : rest.previousButtonProps?.onClick
          }
        }

  return (
    <Step {...stepProps}>
      {shouldShowConfirmation ? (
        <Confirmation
          title="Feedback video sent"
          learnerId={learnerId}
          onBackToProfile={() => trackButtonClickEvent({ componentName: 'back to learner profile' })}
          onBackToTaskList={() => trackButtonClickEvent({ componentName: 'back to task list' })}
        />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={4}>
            {speakingChallengeEntry ? (
              <VideoPreview
                className={classes.video}
                src={speakingChallengeEntry.videoUrl}
                onPlay={onPlayVideo}
                onPause={onPauseVideo}
                onError={() => showAlert('Failed to load video')}
              />
            ) : (
              <>
                {video ? (
                  <div className={classes.video}>
                    <VideoPreview
                      className={classes.video}
                      src={video.url}
                      controls
                      onPlay={onPlayVideo}
                      onPause={onPauseVideo}
                    />
                    <ConfirmPopup
                      onConfirm={() => {
                        setVideo(null)
                        trackButtonClickEvent({
                          componentName: 'delete feedback video',
                          interactionValue: 'delete'
                        })
                      }}
                      disabled={videoUploadStatus.type === 'uploading'}
                      text="Are you sure you want to start over?"
                      cancelText="No"
                      confirmText="Yes">
                      <Button
                        variant="outlined"
                        label="Delete video"
                        icon={<Delete fontSize="small" />}
                        size="small"
                        className={classes.deleteButton}
                        disabled={videoUploadStatus.type === 'uploading'}
                      />
                    </ConfirmPopup>
                  </div>
                ) : (
                  <div className={classes.videoRecordingContainer}>
                    <VideoRecorder
                      onSubmit={(video) => setVideo({ file: video, url: URL.createObjectURL(video) })}
                      onStartRecording={() => onStartRecording?.(++attempt.current)}
                      width={videoContainerWidth}
                      height={videoContainerHeight}
                      autosave
                    />
                  </div>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h4" className={classes.heading}>
              {studySet.title}
            </Typography>
            <AutoSaveField
              onSave={handleSaveNotes}
              onBlur={() => trackTypingEvent({ componentName: 'Speaking challenge feedback notes' })}
              placeholder="Write comments for yourself here."
              initialValue={notes}
            />
            {!!task.speakingChallenge.textQuestion && (
              <>
                <Typography variant="h4" className={classes.heading}>
                  Speaking Challenge Question
                </Typography>
                <Typography variant="body">{task.speakingChallenge.textQuestion}</Typography>
              </>
            )}

            {!!task.tutorInstruction && (
              <>
                <Typography variant="h4" className={classes.heading}>
                  Feedback Instructions
                </Typography>
                <Typography variant="body">{task.tutorInstruction}</Typography>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Step>
  )
}
