import React, { PropsWithChildren, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import { Button } from '@astrid/components'

interface ConfirmPopupProps {
  onConfirm: () => void
  text?: string
  cancelText?: string
  confirmText?: string
  disabled?: boolean
}

export const ConfirmPopup = ({
  onConfirm,
  text,
  cancelText,
  confirmText,
  disabled,
  children
}: PropsWithChildren<ConfirmPopupProps>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleConfirm = () => {
    setIsOpen(false)
    onConfirm()
  }

  return (
    <>
      <div onClick={() => !disabled && setIsOpen(true)}>{children}</div>
      <Dialog open={isOpen}>
        <DialogContent>
          <DialogContentText>{text || 'Are you sure?'}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="outlined" onClick={() => setIsOpen(false)}>
            {cancelText || 'Cancel'}
          </Button>
          <Button size="small" color="action" onClick={handleConfirm}>
            {confirmText || 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
