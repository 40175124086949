import React from 'react'
import cx from 'classnames'

import Typography from '../Typography/Typography'

import './Button.scss'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string
  icon?: JSX.Element
  variant?: 'filled' | 'outlined' | 'flat'
  size?: 'default' | 'small'
  color?: 'primary' | 'secondary' | 'action' | 'default' | 'confirm' | 'light-blue' | 'blue-grey' | 'green'
  className?: string
  style?: React.CSSProperties
}

const Button: React.FC<ButtonProps> = ({
  variant = 'filled',
  size = 'default',
  color = 'default',
  label,
  icon,
  className,
  children,
  ...rest
}) => {
  const classNames = cx(
    `button--${size}`,
    `button--${variant}`,
    `button--color-${color}`,
    {
      'button--spacing-small': !!(icon && label && size === 'small'),
      'button--spacing-default': !!(icon && label && size === 'default')
    },
    className
  )

  return (
    <button className={classNames} {...rest}>
      {children ? (
        children
      ) : (
        <>
          {icon}
          {label && <Typography variant="button">{label}</Typography>}
        </>
      )}
    </button>
  )
}

export default Button
