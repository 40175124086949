import { Button } from '@astrid/components'
import { makeStyles } from '@material-ui/core/styles'
import { FiberManualRecord, StopRounded } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { Timer } from 'components/Timer/Timer'
import { useMicrophoneCheck } from 'hooks/useMicrophoneCheck'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { VideoPreview } from './VideoPreview'

export const aspectRatio = 9 / 16
const mimeType = MediaRecorder.isTypeSupported('video/webm') ? 'video/webm' : 'video/mp4'
const fileExtension = mimeType.split('/')[1]
const videoConstraints = {
  aspectRatio: { min: aspectRatio, ideal: aspectRatio, max: aspectRatio },
  height: { ideal: 1280, max: 1280 },
  facingMode: 'user'
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',

    '&::before': {
      content: 'attr(data-recorder-status)',
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      top: 0,
      left: 0,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: theme.palette.text.secondary
    }
  },
  webcam: {
    objectFit: 'cover'
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  hide: {
    visibility: 'hidden'
  },
  overlay: {
    display: 'flex',
    justifyContent: 'space-around',
    position: 'absolute',
    width: '100%',
    padding: theme.spacing(2)
  },
  top: { top: 0 },
  bottom: { bottom: 0 },
  middle: { top: 0, bottom: 0, alignItems: 'center', height: 0, margin: 'auto' },
  warning: {
    margin: `30px ${theme.spacing(2)}px`,
    padding: `0 ${theme.spacing(1)}px`
  }
}))

interface Props {
  onSubmit: (videoData: File, videoPreviewUrl: string) => void
  onStartRecording?: () => void
  className?: string
  width?: string | number
  height?: string | number
  autosave?: boolean
}

type VideoRecordingState =
  | { type: 'initializing' }
  | { type: 'ready' }
  | { type: 'recording' }
  | { type: 'recorded'; video: { file: File; url: string } }

export const VideoRecorder = ({ onSubmit, onStartRecording, autosave, width, height, className }: Props) => {
  const classes = useStyles()
  const webcamRef = useRef<Webcam | null>(null)
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const recordedChunksRef = useRef<Blob[]>([])
  const recordingTimeInSeconds = useRef<number>(0)
  const { startCheckingMicrophone, stopCheckingMicrophone, isMicrophoneMuted } = useMicrophoneCheck()
  const [recordingState, setRecordingState] = useState<VideoRecordingState>({ type: 'initializing' })
  const [isPlayingVideo, setIsPlayingVideo] = useState(false)

  const handleSubmit = useCallback(async () => {
    if (recordingState.type !== 'recorded') {
      throw new Error('No video file')
    }
    return onSubmit(recordingState.video.file, recordingState.video.url)
  }, [onSubmit, recordingState])

  const handleDataAvailable = useCallback(({ data }) => data.size && recordedChunksRef.current.push(data), [])

  const handleStopRecording = useCallback(() => {
    const blob = new File(recordedChunksRef.current, `video.${fileExtension}`, { type: mimeType })
    setRecordingState({ type: 'recorded', video: { file: blob, url: URL.createObjectURL(blob) } })
  }, [setRecordingState])

  const handleStartCaptureClick = useCallback(() => {
    if (!webcamRef?.current?.stream) {
      throw new Error('No webcam')
    }

    recordedChunksRef.current = []
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, { mimeType })
    mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable)
    mediaRecorderRef.current.addEventListener('stop', handleStopRecording)
    mediaRecorderRef.current.start()

    startCheckingMicrophone(webcamRef.current.stream)
    setRecordingState({ type: 'recording' })
    onStartRecording && onStartRecording()
  }, [
    webcamRef,
    setRecordingState,
    onStartRecording,
    handleDataAvailable,
    handleStopRecording,
    startCheckingMicrophone
  ])

  const handleStopCaptureClick = useCallback(async () => {
    stopCheckingMicrophone()
    mediaRecorderRef.current?.stop()
  }, [stopCheckingMicrophone])

  useEffect(() => {
    const shouldAutoSave = recordingState.type === 'recorded' && autosave
    if (shouldAutoSave) {
      handleSubmit()
    }
  }, [recordingState, autosave, handleSubmit])

  useEffect(() => {
    // cleanup
    return () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.removeEventListener('dataavailable', handleDataAvailable)
        mediaRecorderRef.current.removeEventListener('stop', handleStopRecording)
      }
    }
  }, [handleDataAvailable, handleStopRecording])

  const shouldShowCapturedVideo = recordingState.type === 'recorded'
  const shouldShowRecordButtons = recordingState.type === 'ready' || recordingState.type === 'recording'

  return (
    <div
      className={`${classes.container} ${className || ''}`}
      style={{ width, height }}
      data-recorder-status="Waiting for camera...">
      <Webcam
        muted
        mirrored
        audio={true}
        ref={webcamRef}
        videoConstraints={videoConstraints}
        width="100%"
        height="100%"
        className={`${shouldShowCapturedVideo ? classes.hide : classes.webcam}`}
        onPlaying={() => setRecordingState({ type: 'ready' })}
      />
      {shouldShowCapturedVideo && (
        <VideoPreview
          className={classes.video}
          src={recordingState.video.url}
          duration={recordingTimeInSeconds.current}
          onPlay={() => setIsPlayingVideo(true)}
          onPause={() => setIsPlayingVideo(false)}
        />
      )}

      {shouldShowCapturedVideo && !autosave && !isPlayingVideo && (
        <div className={`${classes.overlay} ${classes.middle}`}>
          <Button onClick={() => setRecordingState({ type: 'ready' })} color="blue-grey" size="small">
            Reset
          </Button>
          <Button onClick={handleSubmit} disabled={!shouldShowCapturedVideo} variant="filled" size="small">
            Save
          </Button>
        </div>
      )}
      {recordingState.type === 'recording' && (
        <>
          <Timer
            className={`${classes.overlay} ${classes.top}`}
            onUpdate={(time) => (recordingTimeInSeconds.current = time)}
            limitInSeconds={60}
          />
          {isMicrophoneMuted && (
            <div className={`${classes.overlay} ${classes.top}`}>
              <Alert elevation={0} severity="warning" className={`${classes.warning}`}>
                Can't pick up sound. Is your microphone on?
              </Alert>
            </div>
          )}
        </>
      )}

      {shouldShowRecordButtons && (
        <div className={`${classes.overlay} ${classes.bottom}`}>
          {recordingState.type === 'ready' && (
            <Button onClick={handleStartCaptureClick} variant="filled" size="small">
              <FiberManualRecord /> Record
            </Button>
          )}
          {recordingState.type === 'recording' && (
            <Button onClick={handleStopCaptureClick} variant="filled" color="secondary" size="small">
              <StopRounded /> Stop
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
