import React, { Component, createRef } from 'react'

import { ReactComponent as ChevronDown } from '../../graphics/icons/chevron-down.svg'

import './Select.scss'

export interface ISelectOption {
  value: string
  label: string | React.ComponentElement<any, any>
}

export interface SelectProps {
  name: string
  value: string
  label: string
  options: ISelectOption[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  disabled?: boolean
  style?: React.CSSProperties
  className?: string
  error?: boolean
  helperText?: string
  optionsPosition?: 'top' | 'bottom'
  testId?: string
}

export interface SelectState {
  preselectedIndex: number
  optionsOpen: boolean
  hasFocus: boolean
  search: string
}

class Select extends Component<SelectProps, SelectState> {
  private optionsRef = createRef<HTMLUListElement>()
  private containerRef = createRef<HTMLDivElement>()
  private preselectedLiRef = createRef<HTMLLIElement>()
  private preselectedInputRef = createRef<HTMLInputElement>()

  state: SelectState = {
    preselectedIndex: 0,
    optionsOpen: false,
    hasFocus: false,
    search: ''
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidUpdate(prevProps: SelectProps, prevState: SelectState) {
    if (prevState.preselectedIndex !== this.state.preselectedIndex) {
      this.preselectedLiRef.current?.scrollIntoView({ block: 'center', inline: 'center' })
    }

    if (prevState.search !== this.state.search && this.state.search !== '') {
      this.findBestMatch()
    }
  }

  private getOptionsPosition = () => {
    const { optionsPosition } = this.props
    if (optionsPosition) {
      return optionsPosition
    } else {
      const optionsRect = this.optionsRef.current?.getBoundingClientRect()
      const containerRect = this.containerRef.current?.getBoundingClientRect()

      const optionsHeight = optionsRect?.height!
      const fitsUnderInput = window.innerHeight - containerRect?.bottom! > optionsHeight
      const fitsAboveInput = containerRect?.top! - optionsHeight >= 0

      return !fitsUnderInput && fitsAboveInput ? 'top' : 'bottom'
    }
  }

  private onSelectOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(e)
    this.setState({
      optionsOpen: false,
      search: ''
    })
  }

  private openOptions = (callback?: () => void) => this.setState({ optionsOpen: true }, callback)
  private closeOptions = (callback?: () => void) => this.setState({ optionsOpen: false }, callback)

  private onArrowClick = (direction: 'up' | 'down') =>
    this.setState((prevState) => {
      const { preselectedIndex, optionsOpen } = prevState
      const optionsLength = this.props.options.length
      if (optionsOpen) {
        if (direction === 'down' && preselectedIndex + 1 < optionsLength) {
          return { ...prevState, preselectedIndex: preselectedIndex + 1 }
        } else if (direction === 'up' && preselectedIndex - 1 >= 0) {
          return { ...prevState, preselectedIndex: preselectedIndex - 1 }
        }
      } else {
        this.openOptions()
      }
    })

  private closeAndBlur = () => {
    this.setState(
      {
        optionsOpen: false,
        hasFocus: false,
        search: ''
      },
      this.props.onBlur
    )
  }

  private handleClickOutside = (event: any) => {
    if (!this.containerRef.current?.contains(event.target) && this.state.hasFocus) {
      this.closeAndBlur()
    }
  }

  private handleSearch = (event: React.KeyboardEvent) => {
    if (event.keyCode === 8) {
      // handle backspace
      this.setState(({ search }) => ({
        search: search.substring(0, search.length - 1)
      }))
      return
    }

    const value = event.nativeEvent.key
    if (/^[a-zA-Z0-9]{1}$/gim.test(value)) {
      this.setState(({ search }) => ({
        search: search + value,
        optionsOpen: true
      }))
    }
  }

  private findBestMatch = () => {
    const lowerCaseSearch = this.state.search.toLowerCase()
    let match = this.props.options.find((opt) => {
      if (typeof opt.label === 'string') {
        return opt.label.toLowerCase().startsWith(lowerCaseSearch)
      }
    })

    if (!match) {
      match = this.props.options.find((opt) => {
        if (typeof opt.label === 'string') {
          return opt.label.toLowerCase().includes(lowerCaseSearch)
        }
      })
    }

    if (match) {
      this.setState({ preselectedIndex: this.props.options.findIndex((opt) => opt.value === match!.value) })
    }
  }

  private handleKeyDown = (e: React.KeyboardEvent) => {
    switch (e.keyCode) {
      case 32: // space
        this.state.optionsOpen ? this.closeOptions() : this.openOptions()
        return
      case 27: // esc
        this.closeOptions()
        return
      case 40: // arrow down
        this.onArrowClick('down')
        return
      case 38: // arrow up
        this.onArrowClick('up')
        return
      case 13: // enter
        this.preselectedInputRef.current?.click()
        return
      case 9: // tab
        this.closeAndBlur()
        return
      default:
        this.handleSearch(e)
        return
    }
  }

  render() {
    const { className, style, disabled, error, label, options, value, helperText, testId } = this.props
    const { optionsOpen, preselectedIndex, search } = this.state
    const selectedOptionLabel = options.find((option) => option.value === value)?.label

    return (
      <div
        className={`custom-select ${className ? className : ''}`}
        ref={this.containerRef}
        style={style}
        tabIndex={-1}>
        <div
          tabIndex={0}
          data-testid={testId}
          className={`custom-select__display${disabled ? '--disabled' : error ? '--error' : ''}`}
          onFocus={() => this.setState({ hasFocus: true })}
          onKeyDown={this.handleKeyDown}
          onClick={() => (optionsOpen ? this.closeOptions() : this.openOptions())}>
          <div>
            <span className={`custom-select__display-label${selectedOptionLabel || search ? '--up' : ''}`}>
              {label}
            </span>
            {selectedOptionLabel || search || ''}
          </div>
          <ChevronDown
            style={{
              transition: '230ms all',
              transform: optionsOpen ? 'rotate(180deg)' : ''
            }}
          />
        </div>
        {helperText && <span className="custom-select__helper-text">{helperText}</span>}
        {optionsOpen && (
          <ul
            ref={this.optionsRef}
            className={`custom-select__options ${
              optionsOpen ? 'custom-select__options--open' : ''
            } custom-select__options--position-${this.getOptionsPosition()}`}>
            {options.map((option, i) => (
              <li
                key={option.value}
                ref={option.value === options[preselectedIndex].value ? this.preselectedLiRef : null}>
                <input
                  id={`${name}${i}`}
                  type="radio"
                  name={name}
                  value={option.value}
                  checked={option.value === value}
                  onChange={this.onSelectOption}
                  ref={option.value === options[preselectedIndex].value ? this.preselectedInputRef : null}
                />
                <label htmlFor={`${name}${i}`} data-preselected={option.value === options[preselectedIndex].value}>
                  {option.label}
                </label>
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
}

export default Select
