export enum UserRegion {
  UK = 'UK',
  US = 'US',
  ELT = 'ELT'
}

export interface StudentProfile {
  firstName: string
  lastName: string
  globalScore: number
  country: string
  userRegion: UserRegion
  grade: string
  avatar?: string
  school: {
    name: string
    address?: string
  }
  goals: {
    timeSpent: number
    booksCompleted: number
    exerciseCompleted: number
  }
  learningGoals: {
    fluency: number
    comprehension: number
    timeSpent: number
    booksCompleted: number
    exercisesCompleted: number
  }
  parent?: {
    name1?: string
    email1?: string
    name2?: string
    email2?: string
  }
}
