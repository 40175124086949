import React from 'react'

import { ReadSentenceExerciseData } from '../../..'
import { ExerciseBaseProps } from '../types'
import RecordingPlayer from './RecordingPlayer/RecordingPlayer'

import './ListeningExercise.scss'

interface ListeningExerciseProps extends ExerciseBaseProps {}

const ListeningExercise: React.FC<ListeningExerciseProps> = ({ exercise, onCurrentExerciseCompleted }) => {
  const { recordingUrl, imageUrl } = exercise
  const { sentence } = exercise.data as ReadSentenceExerciseData

  return (
    <div className="listening-exercise">
      <div className="listening-exercise__content">
        <img src={imageUrl} alt="Illustration" />
        <RecordingPlayer sentence={sentence} recordingUrl={recordingUrl} onEnded={onCurrentExerciseCompleted} />
      </div>
    </div>
  )
}

export default ListeningExercise
