import { BaseTextFieldProps, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Formik } from 'formik'
import React, { useCallback, useState } from 'react'
import { debounce } from 'lodash'
import { Typography } from '@astrid/components'
import { Colors } from 'MuiTheme'
import { CheckCircleRounded } from '@material-ui/icons'

const DEBOUNCE_DELAY_MILLIS = 300

const useStyles = makeStyles((theme) => ({
  '@keyframes fadeIn': {
    '0%': {
      transform: 'scale(1)'
    },

    '50%': {
      transform: 'scale(1.2)'
    }
  },
  status: {
    textAlign: 'right'
  },
  error: {
    color: Colors.cerise
  },
  success: {
    display: 'inline-flex',
    alignItems: 'center',
    color: Colors.green,
    animation: '$fadeIn .3s ease-out'
  }
}))

interface Props extends BaseTextFieldProps {
  onSave?: (value: string) => Promise<void>
  onBlur?: (value: string) => void
  initialValue?: string
}

type SaveStatus = 'saving' | 'saved' | 'error'

export const AutoSaveField = ({ onSave, onBlur, initialValue, ...rest }: Props) => {
  const classes = useStyles()
  const [status, setStatus] = useState<SaveStatus | null>(null)
  const handleSave = useCallback(
    debounce(async (value: string) => {
      setStatus('saving')
      try {
        onSave && (await onSave(value))
        setStatus('saved')
      } catch {
        setStatus('error')
      }
    }, DEBOUNCE_DELAY_MILLIS),
    []
  )

  return (
    <Formik initialValues={{ field: initialValue || '' }} onSubmit={({ field }) => handleSave(field)}>
      {({ handleSubmit, setFieldValue, values }) => (
        <div>
          <TextField
            name="field"
            id="field"
            onChange={(e) => {
              setStatus(null)
              setFieldValue('field', e.target.value)
              onSave && handleSubmit()
            }}
            onBlur={() => onBlur?.(values.field)}
            value={values.field}
            variant="outlined"
            fullWidth
            multiline
            rows={10}
            size="small"
            {...rest}
          />

          <Typography variant="body" color="secondary" className={classes.status}>
            {status === 'saved' && (
              <span className={classes.success}>
                <CheckCircleRounded />
                <span>Saved</span>
              </span>
            )}
            {status === 'error' && <span className={classes.error}>Failed to save</span>}
            &nbsp;
          </Typography>
        </div>
      )}
    </Formik>
  )
}
