import { BookType, ExerciseType, Typography } from '@astrid/components'
import { makeStyles } from '@material-ui/core'
import { HeadsetRounded, SubjectRounded } from '@material-ui/icons'
import { ComprehensionQuestion } from 'pages/task/exercises/ComprehensionQuestion'
import { ReadingPage } from './ReadingPage'
import React from 'react'
import { WordOrSentenceBuilder } from './WordOrSentenceBuilder'
import { BookExerciseResult } from 'store/services/StudySet/types'
import { WordPractice } from './WordPractice'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  heading: {
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: 0
  },
  exercises: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  }
}))

interface Props {
  bookType: BookType
  bookTitle: string
  exerciseResults?: BookExerciseResult[]
}

export const ReadingStory = ({ exerciseResults, bookType, bookTitle }: Props) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.heading}>
          {bookType === BookType.Listening && (
            <>
              <HeadsetRounded /> Listening Story - {bookTitle}
            </>
          )}
          {bookType === BookType.Reading && (
            <>
              <SubjectRounded /> Reading Story - {bookTitle}
            </>
          )}
        </Typography>
      </div>
      {exerciseResults && (
        <div className={classes.exercises}>
          {exerciseResults.map((exercise) => (
            <React.Fragment key={exercise.exerciseId}>
              {exercise.exerciseResult.exerciseResultType === 'reading-score' && (
                <ReadingPage exerciseResult={exercise.exerciseResult} noHeading />
              )}

              {exercise.exerciseResult.exerciseResultType === 'non-reading-score' && (
                <>
                  {exercise.exerciseResult.exerciseScore.type === ExerciseType.MultiChoiseQuestion && (
                    <ComprehensionQuestion exerciseResult={exercise.exerciseResult} noHeading />
                  )}

                  {exercise.exerciseResult.exerciseScore.type === ExerciseType.SentenceBuilder && (
                    <WordOrSentenceBuilder exerciseResult={exercise.exerciseResult} noHeading />
                  )}

                  {exercise.exerciseResult.exerciseScore.type === ExerciseType.WordBuilder && (
                    <WordOrSentenceBuilder exerciseResult={exercise.exerciseResult} noHeading />
                  )}

                  {exercise.exerciseResult.exerciseScore.type === ExerciseType.WordPractice && (
                    <WordPractice exerciseResult={exercise.exerciseResult} noHeading />
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  )
}
