import { ListenSentence, Typography } from '@astrid/components'
import { makeStyles } from '@material-ui/core'
import { ExtensionRounded } from '@material-ui/icons'
import { CircularProgressBar } from 'components/CircularProgressBar/CircularProgressBar'
import React, { useContext } from 'react'
import { ExerciseResult, ExerciseResultType } from 'store/services/StudySet/types'
import { getScoreColor } from 'helpers/Score'
import { AlertContext } from 'components/AlertContext/AlertContext'
import { trackAudioPauseEvent, trackAudioPlayEvent } from 'analytics/analytics'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column'
  },
  heading: {
    display: 'flex',
    gap: theme.spacing(1)
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    gap: theme.spacing(2),
    listStyle: 'none'
  },
  attempt: {
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start'
  },
  resultColumn: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginRight: 116
  },
  word: {
    flex: 1
  }
}))

interface Props {
  exerciseResult: ExerciseResult
  noHeading?: boolean
}

export const WordPractice = ({ exerciseResult, noHeading }: Props) => {
  const classes = useStyles()
  const { showAlert } = useContext(AlertContext)
  const recordings = exerciseResult.exerciseResultType === ExerciseResultType.ReadingScores && exerciseResult.recordings

  return (
    <>
      {!noHeading && (
        <Typography variant="h4" className={classes.heading}>
          <ExtensionRounded /> Pronunciation Pro
        </Typography>
      )}
      <div className={classes.wrapper}>
        {recordings &&
          recordings.map((recordingData) => (
            <div key={recordingData.recordingUrl} className={classes.resultColumn}>
              <Typography variant="body" className={classes.word}>
                {recordingData.sanaScore.target_phrase}
              </Typography>
              <CircularProgressBar
                percentage={recordingData.sanaScore.overall_score}
                size={50}
                color={getScoreColor(recordingData.sanaScore.overall_score)}
              />
              <ListenSentence
                sentence={recordingData.sanaScore.target_phrase}
                recordingUrl={recordingData.recordingUrl}
                hideText
                onPlay={() => trackAudioPlayEvent(recordingData.sanaScore.target_phrase)}
                onPause={() => trackAudioPauseEvent(recordingData.sanaScore.target_phrase)}
                onPlayError={(error) => showAlert('Could not play audio: ' + error)}
              />
            </div>
          ))}
      </div>
    </>
  )
}
