import React from 'react'
import { useSpring, animated, ForwardedProps } from 'react-spring'

import './TiltingCard.scss'

function isTouch<T>(event: React.MouseEvent<T, MouseEvent> | React.TouchEvent<T>): event is React.TouchEvent<T> {
  return event.type === 'touchmove'
}

const calculateAngles = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
  const SENSITIVITY = isTouch(event) ? 4 : 8 // lower value => more sensitive

  const w = event.currentTarget.offsetWidth
  const h = event.currentTarget.offsetHeight
  const rect = event.currentTarget.getBoundingClientRect()

  const pointer_w = isTouch<HTMLDivElement>(event) ? event.touches[0].clientX - rect.left : event.clientX - rect.left
  const pointer_h = isTouch<HTMLDivElement>(event) ? event.touches[0].clientY - rect.top : event.clientY - rect.top

  const mouse_inclanation_percent_Y = ((pointer_w - w / 2) / w) * 100
  const mouse_inclanation_percent_X = ((pointer_h - h / 2) / h) * 100

  const angleX = mouse_inclanation_percent_X / SENSITIVITY
  const angleY = -mouse_inclanation_percent_Y / SENSITIVITY

  return [angleX, angleY]
}

const makeTransform = (x: number, y: number) => {
  return `perspective(600px) rotateX(${x}deg) rotateY(${y}deg)`
}

const TiltingCard: React.FC<ForwardedProps<React.HTMLProps<HTMLDivElement>>> = ({
  children,
  className,
  style,
  ...rest
}) => {
  const [props, set] = useSpring(() => ({
    xyAngles: [0, 0],
    config: { mass: 1, tension: 600, friction: 40 }
  }))

  return (
    <animated.div
      className={`${className ? className : ''} tilting-card`}
      onMouseMove={(e) => set({ xyAngles: calculateAngles(e) })}
      onTouchMove={(e) => set({ xyAngles: calculateAngles(e) })}
      onMouseLeave={() => set({ xyAngles: [0, 0] })}
      onTouchEnd={() => set({ xyAngles: [0, 0] })}
      onTouchCancel={() => set({ xyAngles: [0, 0] })}
      style={{
        // @ts-ignore
        transform: props.xyAngles.interpolate(makeTransform),
        ...style
      }}
      {...rest}>
      {children}
    </animated.div>
  )
}

export default TiltingCard
