import { makeStyles } from '@material-ui/core/styles'
import { Colors } from 'MuiTheme'
import React, { PropsWithChildren } from 'react'
import cx from 'classnames'

interface Props {
  variant?: 'default' | 'urgent' | 'pending' | 'overdue'
  title?: string
  className?: string
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'inline-flex',
    height: 30,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    background: Colors.blueGrey,
    color: Colors.cobaltBlue
  },
  pending: {
    background: Colors.lightGrey,
    color: theme.palette.text.secondary
  },
  urgent: {
    background: Colors.veryLightPink,
    color: Colors.cerise
  },
  overdue: {
    background: Colors.veryLightPink,
    color: Colors.cerise
  }
}))

export const DateLabel = ({ variant, title, className, children }: PropsWithChildren<Props>) => {
  const classes = useStyles()

  const labelClasses = cx({
    [classes.wrapper]: true,
    [classes.urgent]: variant === 'urgent',
    [classes.pending]: variant === 'pending',
    [classes.overdue]: variant === 'overdue'
  })

  return (
    <div title={title || ''} className={`${labelClasses} ${className || ''}`}>
      {children}
    </div>
  )
}
