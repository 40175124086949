import { Button, Typography } from '@astrid/components'
import { Grid, makeStyles } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import {
  ScreenNames,
  trackButtonClickEvent,
  trackTaskClickEvent,
  trackTaskListShown,
  trackTaskShown
} from 'analytics/analytics'
import { AlertContext } from 'components/AlertContext/AlertContext'
import { SlimCard } from 'components/SlimCard/SlimCard'
import { isOverdue } from 'helpers/Date/Date'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { bodyFont, Colors } from 'MuiTheme'
import React, { useContext, useEffect } from 'react'
import { useLoadQuery } from 'store/services/Auth/authApi'
import { useLoadOverviewQuery, useUpdateReadinessMutation } from 'store/services/StudySet/studySetApi'
import { EvaluationTaskListEntry, MainTaskListEntry } from 'store/services/StudySet/types'
import { TaskTable } from './components/TaskTable'

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 0,
    marginTop: theme.spacing(2)
  },
  smallHeading: {
    margin: 0,
    marginBottom: theme.spacing(2),
    font: bodyFont,
    fontWeight: 'bold'
  },
  text: {
    marginBottom: theme.spacing(2)
  },
  request: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  statusList: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: 0,
    margin: 0,

    '& li': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2)
    }
  },
  number: {
    fontSize: '1.4rem',
    flex: 1,
    textAlign: 'right'
  }
}))

export const Tasks = () => {
  const classes = useStyles()
  const { showAlert } = useContext(AlertContext)
  const { data: user } = useLoadQuery()
  const {
    data: overview,
    isLoading: isLoadingOverview,
    isFetching: isFetchingOverview,
    error: overviewError
  } = useLoadOverviewQuery(undefined, { refetchOnMountOrArgChange: true })
  const [updateReadiness, { isLoading: isLoadingReadiness }] = useUpdateReadinessMutation()

  useTrackScreenShown(ScreenNames.TaskList)

  const handleReadiness = async (readyForNewTask: boolean) => {
    try {
      await updateReadiness({ readyForNewTask }).unwrap()
      trackButtonClickEvent({
        componentName: 'Assign new tasks',
        componentValue: readyForNewTask ? 'Start' : 'Stop'
      })
    } catch (error) {
      showAlert('Failed to change status')
    }
  }

  const handleTaskClick = (task: MainTaskListEntry | EvaluationTaskListEntry) =>
    trackTaskClickEvent({ componentName: task.type, componentValue: `Learner ID: ${task.learner._id}` })

  useEffect(() => {
    if (overview) {
      trackTaskListShown({
        screenName: ScreenNames.TaskList,
        componentName: 'Next task',
        componentValue: JSON.stringify({
          n_task: overview.currentMainTask ? 1 : 0,
          n_task_over_due: isOverdue(overview.currentMainTask?.deadline) ? 1 : 0
        })
      })

      trackTaskListShown({
        screenName: ScreenNames.TaskList,
        componentName: 'Performance evaluation',
        componentValue: JSON.stringify({
          n_task: overview.evaluationTaskList.length,
          n_task_over_due: overview.evaluationTaskList.filter((task) => isOverdue(task.deadline)).length
        })
      })

      if (overview.currentMainTask) {
        trackTaskShown({
          screenName: ScreenNames.TaskList,
          componentName: 'Next task',
          componentValue: JSON.stringify({
            learner: overview.currentMainTask.learner._id,
            task_type: overview.currentMainTask.type
          })
        })
      }

      overview.evaluationTaskList.forEach((task) => {
        trackTaskShown({
          screenName: ScreenNames.TaskList,
          componentName: 'Performance evaluation',
          componentValue: JSON.stringify({
            learner: task.learner._id,
            task_type: task.type
          })
        })
      })
    }
  }, [overview])

  const isLoading = isLoadingReadiness || isFetchingOverview

  return (
    <Grid container spacing={4}>
      <Grid item xs={8}>
        <Typography variant="h3" className={classes.heading}>
          Hi {user?.firstName || ''}
        </Typography>
        <Typography variant="body">These are your tasks for today.</Typography>
      </Grid>
      <Grid item xs={4} className={classes.request}>
        {overview?.readyForNewTask ? (
          <Button color="secondary" size="small" disabled={isLoading} onClick={() => handleReadiness(false)}>
            Stop assigning new tasks
          </Button>
        ) : (
          <Button
            color="action"
            variant="filled"
            size="small"
            disabled={!overview || isLoading}
            onClick={() => handleReadiness(true)}>
            Start assigning new tasks
          </Button>
        )}
      </Grid>
      <Grid item xs={8}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.text}>
              Next task
            </Typography>
            {overview?.currentMainTask ? (
              <TaskTable tasks={[overview.currentMainTask]} showDeadlines showTaskType onTaskClick={handleTaskClick} />
            ) : (
              <SlimCard>
                {isLoadingOverview && <Typography variant="body">Loading...</Typography>}
                {overviewError && <Typography variant="body">Failed to fetch tasks.</Typography>}
                {overview && <Typography variant="body">No tasks requested</Typography>}
              </SlimCard>
            )}
          </Grid>
          <Grid item xs={6}>
            <SlimCard>
              <Typography variant="h4" className={classes.smallHeading}>
                Available tasks
              </Typography>
              <ul className={classes.statusList}>
                <li>
                  <span>Total number of tasks:</span>
                  <span className={classes.number}>{overview?.availableTasksStatus.totalTasks}</span>
                </li>
                <li>
                  <span>Urgent tasks:</span>
                  <span className={classes.number}>{overview?.availableTasksStatus.urgentTasks}</span>
                </li>
              </ul>
            </SlimCard>
          </Grid>
          <Grid item xs={6}>
            <SlimCard>
              <Typography variant="h4" className={classes.smallHeading}>
                Completed today
              </Typography>
              <ul className={classes.statusList}>
                <li>
                  <CheckCircle htmlColor={Colors.green} />
                  <span>Tasks:</span>
                  <span className={classes.number}>{overview?.tutorDailyProgress.completedMainTasks}</span>
                </li>
                <li>
                  <CheckCircle htmlColor={Colors.green} />
                  <span>Evaluations:</span>
                  <span className={classes.number}>{overview?.tutorDailyProgress.completedEvaluations}</span>
                </li>
              </ul>
            </SlimCard>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h4" className={classes.text}>
          Performance evaluations
        </Typography>
        {overview?.evaluationTaskList && overview.evaluationTaskList.length > 0 ? (
          <TaskTable tasks={overview.evaluationTaskList} showDeadlines onTaskClick={handleTaskClick} />
        ) : (
          <SlimCard>
            {isLoadingOverview && <Typography variant="body">Loading...</Typography>}
            {overviewError && <Typography variant="body">Fetch failed.</Typography>}
            {overview?.evaluationTaskList && <Typography variant="body">No tasks to evaluate</Typography>}
          </SlimCard>
        )}
      </Grid>
    </Grid>
  )
}
