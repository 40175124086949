import { ExerciseType, Typography } from '@astrid/components'
import { makeStyles } from '@material-ui/core'
import { CheckBoxRounded, ExtensionRounded } from '@material-ui/icons'
import { ExerciseImage } from 'pages/task/components/ExerciseImage'
import { ExerciseOption } from 'pages/task/components/ExerciseOption'
import { Colors } from 'MuiTheme'
import React from 'react'
import { ExerciseBuilderData, ExerciseResult, ExerciseResultType } from 'store/services/StudySet/types'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  heading: {
    display: 'flex',
    gap: theme.spacing(1)
  },
  exercise: {
    flex: 1,
    marginTop: '-0.4rem'
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    gap: theme.spacing(2),
    listStyle: 'none'
  },
  attempt: {
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start'
  }
}))

interface Props {
  exerciseResult: ExerciseResult
  noHeading?: boolean
}

export const WordOrSentenceBuilder = ({ exerciseResult, noHeading }: Props) => {
  const classes = useStyles()
  const { imageUrl, imageTitle, type } = exerciseResult.exercise
  const data = exerciseResult.exercise.data as ExerciseBuilderData
  const exerciseScore =
    exerciseResult.exerciseResultType === ExerciseResultType.NonReadingScore && exerciseResult.exerciseScore
  const isSentence = type === ExerciseType.SentenceBuilder
  const chars = data.wordOrSentenceUs.split(isSentence ? ' ' : '')
  const distractors = data.distractorsUs || []
  const wordString = chars
    .map((characters, index) => (data.missingIndexesUs.includes(index) ? '_'.repeat(characters.length) : characters))
    .join(' ')

  return (
    <>
      {!noHeading && (
        <Typography variant="h4" className={classes.heading}>
          <ExtensionRounded /> {isSentence ? 'Sentence builder' : 'Word builder'}
        </Typography>
      )}
      <div className={classes.wrapper}>
        {!!imageUrl && <ExerciseImage src={imageUrl} alt={imageTitle} />}
        <div className={classes.exercise}>
          <Typography variant="body">{wordString}</Typography>
          <ul className={classes.options}>
            {[...data.missingIndexesUs].sort().map((i, index) => (
              <ExerciseOption key={`${i}--${index}`}>{chars[i]}</ExerciseOption>
            ))}

            {distractors.map((distractor, index) => (
              <ExerciseOption key={`${distractor}--${index}`} dashed>
                {distractor}
              </ExerciseOption>
            ))}
          </ul>
        </div>
        <div className={classes.attempt}>
          {exerciseScore && (
            <CheckBoxRounded
              fontSize="large"
              htmlColor={exerciseScore.score === 100 ? Colors.green : Colors.rosyPink}
            />
          )}
        </div>
      </div>
    </>
  )
}
