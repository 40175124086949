const HOUR = 60 * 60 * 1000
const MINUTE = 60 * 1000

/**
 * Returns date in format dd mmm hh:tt, e.g. 31 oct 08:56
 */
export const getShortDateFormat = (unixTime: number): string => {
  const date = new Date(unixTime)
  const day = date.getDate()
  const monthName = date.toLocaleString('default', { month: 'short' }).toLowerCase()
  const hour = `0${date.getHours()}`.substr(-2)
  const minute = `0${date.getMinutes()}`.substr(-2)

  return `${day} ${monthName} ${hour}:${minute}`
}

/**
 * Returns remaining time in hours or minutes between specified timestamps
 */
export const getTimeBetween = (startUnixTime: number, endUnixTime: number): string => {
  const hoursLeft =
    startUnixTime > endUnixTime
      ? Math.ceil((endUnixTime - startUnixTime) / HOUR)
      : Math.floor((endUnixTime - startUnixTime) / HOUR)

  if (hoursLeft === 0) {
    const minutesLeft =
      startUnixTime > endUnixTime
        ? Math.ceil((endUnixTime - startUnixTime) / MINUTE)
        : Math.floor((endUnixTime - startUnixTime) / MINUTE)

    return `${minutesLeft} ${Math.abs(minutesLeft) === 1 ? 'minute' : 'minutes'}`
  }

  return `${hoursLeft} ${Math.abs(hoursLeft) === 1 ? 'hour' : 'hours'}`
}

export const isOverdue = (deadlineUnixTime?: number) => {
  if (deadlineUnixTime === undefined) {
    return false
  }

  const now = new Date().getTime()
  return now > deadlineUnixTime
}

export const getYearsSince = (fromYear: number) => new Date().getUTCFullYear() - fromYear
