import React, { useState } from 'react'

import { ReplayIcon } from '../../../../index'

import './ReplaySentence.scss'

interface ReplaySentenceProps {
  recording: Blob | null
  className?: string
}

const ReplaySentence: React.FC<ReplaySentenceProps> = ({ recording, className }) => {
  const [playing, setPlaying] = useState(false)
  if (!recording) return null

  const audio = new Audio(URL.createObjectURL(recording))
  audio.onplay = () => {
    setPlaying(true)
  }
  audio.onended = () => {
    setPlaying(false)
  }

  const playAudio = () => {
    if (playing) return
    audio.play()
  }

  return (
    <button
      onClick={playAudio}
      className={`replay-sentence${playing ? '--playing' : ''} ${className ? className : ''}`}>
      <ReplayIcon />
      Replay recording
    </button>
  )
}

export default ReplaySentence
