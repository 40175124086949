import { AxiosRequestConfig } from 'axios'
import { baseApi } from 'store/store'
import { ArchivedStudySetModel, CompleteOverview, ReviewedState, StudySetEvaluation, TaskDetails } from './types'

export const studySetApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    loadOverview: builder.query<CompleteOverview, void>({
      query: () => ({ url: '/tutor-task/overview', method: 'get' }),
      providesTags: ['Tasks']
    }),

    loadActiveTasks: builder.query<TaskDetails[], string>({
      query: (userId) => ({ url: `/tutor-task/current-for-learner/${userId}`, method: 'get' }),
      providesTags: ['ActiveTasks']
    }),

    updateReadiness: builder.mutation<void, { readyForNewTask: boolean }>({
      query: ({ readyForNewTask }) => ({
        url: `tutor-task/readiness`,
        method: 'put',
        data: { readyForNewTask }
      }),
      invalidatesTags: (result) => (result ? ['Tasks'] : [])
    }),

    loadArchivedStudySets: builder.query<ArchivedStudySetModel[], string>({
      query: (userId) => ({ url: `/lesson-instance/history/user/${userId}`, method: 'get' }),
      providesTags: ['ArchivedStudySets']
    }),

    createSpeakingChallengeEntry: builder.mutation<
      void,
      { id: string; data: FormData; onUploadProgress?: AxiosRequestConfig['onUploadProgress'] }
    >({
      query: ({ id, data, onUploadProgress }) => ({
        url: `/lesson-instance/${id}/speaking-challenge-feedback`,
        method: 'post',
        data,
        onUploadProgress
      })
    }),

    createEvaluation: builder.mutation<void, { id: string; evaluation: StudySetEvaluation }>({
      query: ({ id, evaluation }) => ({ url: `/lesson-instance/${id}/evaluation`, method: 'post', data: evaluation })
    }),

    updateStudySetNotes: builder.mutation<void, { id: string; notes: string }>({
      query: ({ id, notes }) => ({
        url: `/lesson-instance/${id}/tutor-notes`,
        method: 'put',
        data: { tutorNotes: notes }
      })
    }),

    updateStudySetReviewed: builder.mutation<void, { id: string; reviewedState: ReviewedState }>({
      query: ({ id, reviewedState }) => ({
        url: `/lesson-instance/${id}/tutor-review`,
        method: 'put',
        data: reviewedState
      })
    }),

    createCheckInVideo: builder.mutation<
      void,
      { data: FormData; onUploadProgress?: AxiosRequestConfig['onUploadProgress'] }
    >({
      query: ({ data, onUploadProgress }) => ({
        url: '/tutor-check-in',
        method: 'post',
        data,
        onUploadProgress
      })
    })
  })
})

export const {
  useLoadOverviewQuery,
  useLoadActiveTasksQuery,
  useLoadArchivedStudySetsQuery,
  useCreateSpeakingChallengeEntryMutation,
  useCreateEvaluationMutation,
  useUpdateStudySetNotesMutation,
  useUpdateStudySetReviewedMutation,
  useUpdateReadinessMutation,
  useCreateCheckInVideoMutation
} = studySetApi
