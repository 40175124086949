import { Typography } from '@astrid/components'
import { makeStyles } from '@material-ui/core'
import { SubjectRounded } from '@material-ui/icons'
import { trackAudioPauseEvent, trackAudioPlayEvent } from 'analytics/analytics'
import { ExerciseImage } from 'pages/task/components/ExerciseImage'
import React from 'react'
import { ExerciseReadSentenceData, ExerciseResult, ExerciseResultType } from 'store/services/StudySet/types'
import { Sentence } from '../components/Sentence'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'flex-start'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

interface Props {
  exerciseResult: ExerciseResult
  noHeading?: boolean
}

export const ReadingPage = ({ exerciseResult, noHeading }: Props) => {
  const classes = useStyles()
  const { imageUrl, imageTitle } = exerciseResult.exercise
  const sanaScore = exerciseResult.exerciseResultType === ExerciseResultType.ReadingScore && exerciseResult.sanaScore
  const sentence = (exerciseResult.exercise.data as ExerciseReadSentenceData).sentenceUs || ''

  return (
    <>
      {!noHeading && (
        <div className={classes.header}>
          <Typography variant="h4">
            <SubjectRounded /> Reading exercise
          </Typography>
          <Typography variant="h4">Attempts:</Typography>
        </div>
      )}
      <div className={classes.wrapper}>
        {!!imageUrl && <ExerciseImage src={imageUrl} alt={imageTitle} />}
        {sanaScore && (
          <Sentence
            onPlayAudio={() => trackAudioPlayEvent(sentence)}
            onPauseAudio={() => trackAudioPauseEvent(sentence)}
            sanaScore={sanaScore}
            sentence={sentence}
            attemptCount={exerciseResult.attemptCount}
            recordingUrl={exerciseResult.recordingUrl}
          />
        )}
      </div>
    </>
  )
}
