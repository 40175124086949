import React from 'react'

import './Card.scss'
import { Typography } from '../../index'

interface CardProps {
  title?: string
  titleNote?: string
  subtitle?: string
  className?: string
}

const Card: React.FC<CardProps> = ({ title, titleNote, subtitle, children, className }) => {
  const showHeader = !!(title || subtitle)

  return (
    <div className={`astrid-card ${className ? className : ''}`}>
      {showHeader && (
        <div className="astrid-card__header">
          <Typography variant="h4">{title}</Typography>
          {titleNote ? <span className="astrid-card__note">{titleNote}</span> : null}
          <Typography variant="body">{subtitle}</Typography>
        </div>
      )}
      <div className="astrid-card__content">{children}</div>
    </div>
  )
}

export default Card
