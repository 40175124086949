import React, { PropsWithChildren, useState } from 'react'
import { AlertBox, SuccessMessage } from 'components/Snackbar/Snackbar'

interface AlertContextState {
  showAlert: (text: string, type?: 'error' | 'success') => void
}

export const AlertContext = React.createContext<AlertContextState>({ showAlert: () => {} })

export const AlertProvider = ({ children }: PropsWithChildren<{}>) => {
  const [alertSettings, setAlertSettings] = useState<{ type?: 'error' | 'success'; text: string }>({ text: '' })

  const showAlert = (text: string, type: 'error' | 'success' = 'error') => {
    setAlertSettings({ text, type })
  }

  const handleClose = () => {
    setAlertSettings({ text: '' })
  }

  const { text, type } = alertSettings
  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      {type === 'error' && <AlertBox message={text} onClose={handleClose} />}
      {type === 'success' && <SuccessMessage message={text} onClose={handleClose} />}
    </AlertContext.Provider>
  )
}
