import React, { Component, CSSProperties } from 'react'
import { buildSanaVoiceSentenceURL } from '../../../../utils'

import { PlayIcon, PauseIcon } from '../../../..'

import './RecordingPlayer.scss'

interface RecordingPlayerProps {
  sentence: string
  recordingUrl?: string
  country?: string
  className?: string
  style?: CSSProperties
  onEnded?: () => void
}

interface State {
  playing: boolean
  currentTime: number
  ended: boolean
}

class RecordingPlayer extends Component<RecordingPlayerProps, State> {
  private audio: HTMLAudioElement = new Audio()

  constructor(props: RecordingPlayerProps) {
    super(props)
    this.createAudio()
    this.audio.onplay = () => this.setState({ playing: true })
    this.audio.onended = () => {
      this.setState({ playing: false, ended: true })
      this.props.onEnded && this.props.onEnded()
    }
    this.audio.onpause = () => this.setState({ playing: false })
    this.audio.ontimeupdate = () => this.setState({ currentTime: this.audio.currentTime })
  }

  state: State = {
    playing: false,
    ended: false,
    currentTime: 0
  }

  componentDidUpdate(prevProps: RecordingPlayerProps) {
    if (prevProps.sentence !== this.props.sentence || prevProps.recordingUrl !== this.props.recordingUrl) {
      this.audio.pause()
      this.createAudio()
    }
  }

  componentWillUnmount() {
    this.audio.pause()
  }

  private createAudio = () => {
    if (this.props.recordingUrl) {
      this.audio = new Audio(this.props.recordingUrl)
    } else {
      this.audio = new Audio(buildSanaVoiceSentenceURL(this.props.sentence, this.props.country))
    }
  }

  private onClick = () => {
    if (this.state.playing) {
      this.audio.pause()
    } else {
      this.audio.play()
    }
  }

  public stopPlaying = () => {
    if (this.state.playing) {
      this.audio.pause()
    }
  }

  render() {
    const { duration } = this.audio
    const { currentTime, playing, ended } = this.state

    return (
      <div className={`recording-player ${this.props.className ? this.props.className : ''}`} style={this.props.style}>
        <div className="recording-player__progress">
          <div
            className="recording-player__progress-indicator"
            style={{ width: `${(currentTime / (isNaN(duration) ? 1 : duration)) * 100}%` }}
          />
        </div>
        <button
          className={`recording-player__button${playing ? '--playing' : ''}`}
          onClick={this.onClick}
          disabled={ended}>
          {playing ? <PauseIcon /> : <PlayIcon />}
        </button>
      </div>
    )
  }
}

export default RecordingPlayer
