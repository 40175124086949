import React from 'react'

import { SoundEffectsConsumer } from './SoundEffectsContext'
import { InjectedSoundProps } from '../../types'

export const withSoundEffectsContext = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<Omit<P, keyof InjectedSoundProps>> => (props) => (
  <SoundEffectsConsumer>
    {({ playSound }) => <Component {...(props as P)} playSound={playSound} />}
  </SoundEffectsConsumer>
)

export default withSoundEffectsContext
