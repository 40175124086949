import { Typography } from '@astrid/components'
import { makeStyles } from '@material-ui/core'
import { QuestionAnswerRounded } from '@material-ui/icons'
import { trackAudioPauseEvent, trackAudioPlayEvent } from 'analytics/analytics'
import { ExerciseImage } from 'pages/task/components/ExerciseImage'
import React from 'react'
import { ExerciseConversationData, ExerciseResult, ExerciseResultType } from 'store/services/StudySet/types'
import { Sentence } from '../components/Sentence'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 0'
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    margin: 0
  }
}))

interface Props {
  exerciseResult: ExerciseResult
  noHeading?: boolean
}

export const Conversation = ({ noHeading, exerciseResult }: Props) => {
  const classes = useStyles()
  const { imageUrl, imageTitle } = exerciseResult.exercise
  const data = exerciseResult.exercise.data as ExerciseConversationData

  return (
    <>
      {!noHeading && (
        <div className={classes.header}>
          <Typography variant="h4" className={classes.title}>
            <QuestionAnswerRounded /> Conversation activity
          </Typography>
        </div>
      )}
      <div className={classes.wrapper}>
        {!!imageUrl && (
          <div>
            <ExerciseImage src={imageUrl} alt={imageTitle} />
          </div>
        )}
        <div className={classes.dialog}>
          {data.conversationElements.map((element, index) => {
            const recording =
              exerciseResult.exerciseResultType === ExerciseResultType.ReadingScores && exerciseResult.recordings[index]

            return (
              <React.Fragment key={index}>
                <Sentence sentence={element.instructorScript} />
                {element.learnerScript && recording && (
                  <Sentence
                    onPlayAudio={() => trackAudioPlayEvent(element.learnerScript || '')}
                    onPauseAudio={() => trackAudioPauseEvent(element.learnerScript || '')}
                    sentence={element.learnerScript}
                    sanaScore={recording.sanaScore}
                    attemptCount={recording.attempts}
                    recordingUrl={recording.recordingUrl}
                  />
                )}
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </>
  )
}
