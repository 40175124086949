import { basename } from 'path'
import * as Yup from 'yup'
import { Book } from '..'

export const onRightOrLeftKeyboardArrows = (
  onRightCallback: () => void,
  onLeftCallback: () => void
): ((event: KeyboardEvent) => void) => {
  const onEventFired = (event: KeyboardEvent): void => {
    if (event.code === 'ArrowRight' || event.keyCode === 39) {
      onRightCallback()
    } else if (event.code === 'ArrowLeft' || event.keyCode === 37) {
      onLeftCallback()
    }
  }
  document.addEventListener('keyup', onEventFired)
  return onEventFired // return event function to be able to cleanup it in onWillUnmounted hook if need
}

export const ValidationRules = {
  required: Yup.string().required('Required'),
  requiredNum: Yup.number().required('Required'),
  name: Yup.string()
    .max(50, 'Must be at most 50 chars.')
    .matches(/^[a-zA-Z\u00c0-\u017e ,.'-]+$/i, 'Only letters allowed'),
  email: Yup.string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i, 'Email format is not valid.')
    .required('Required'),
  emailNotRequired: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i, 'Email format is not valid.'),
  studentPassword: Yup.string()
    .required('Required')
    .max(128, 'Password must be at most 128 chars.')
    .matches(
      /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z\u00c0-\u017e !?@#$%^&*_\-\=\+\(\)\\\{\}\[\]:;\"\'\<\>,.\/\`\~]{5,}$/,
      'Required at least 5 characters including number and upper or lower case.'
    ),
  password: Yup.string()
    .required('Required')
    .trim()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\u00c0-\u017e !?@#$%^&*_\-\=\+\(\)\\\{\}\[\]:;\"\'\<\>,.\/\`\~]{6,}$/,
      'Required at least 6 characters including number, upper & lower case.'
    )
}

export const buildSanaVoiceSentenceURL = (sentence: string, country = 'US'): string => {
  return `${process.env.REACT_APP_API_URL}/google/textToSpeech?text=${encodeURIComponent(
    sentence
  )}&country=${encodeURIComponent(country)}`
}

export const sortBooksByCEFR = (books: Book[]): Book[] => {
  const booksToReturn = []
  const booksWithCEFR = books.filter((b) => !!b.cefrLevel)
  const booksWithoutCEFR = books.filter((b) => !b.cefrLevel)

  booksToReturn.push(...booksWithCEFR.sort((a, b) => a.cefrLevel!.localeCompare(b.cefrLevel!)))
  booksToReturn.push(...booksWithoutCEFR)

  return booksToReturn
}
