import * as React from 'react'
import cx from 'classnames'

import './TextInput.scss'

export interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  name: string
  error?: boolean
  helperText?: string
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  label,
  value,
  onChange,
  placeholder,
  id,
  error,
  helperText,
  ...rest
}) => (
  <div className={cx('text-input', { 'text-input--error': error })}>
    <input type="text" name={name} id={id || name} value={value} onChange={onChange} aria-invalid={error} {...rest} />
    <label htmlFor={id || name}>{label}</label>
    {helperText && <span className="text-input__helper-text">{helperText}</span>}
  </div>
)

export default TextInput
