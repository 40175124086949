import hark from 'hark'
import { useEffect, useRef, useState } from 'react'

const WAIT_MILLIS = 2000
const MICROPHONE_THRESHOLD_DB = -100 // value bewteen -100 and 0

interface Options {
  thresholdDb?: number
  waitMillis?: number
}

export const useMicrophoneCheck = ({
  thresholdDb = MICROPHONE_THRESHOLD_DB,
  waitMillis = WAIT_MILLIS
}: Options = {}) => {
  const speechEventsRef = useRef<hark.Harker | null>(null)
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [isMicrophoneMuted, setIsMicrophoneMuted] = useState<boolean>(false)

  const startCheckingMicrophone = (stream: MediaStream) => {
    speechEventsRef.current = hark(stream, { threshold: thresholdDb })
    speechEventsRef.current.on('speaking', () => {
      setIsMicrophoneMuted(false)
      stopCheckingMicrophone()
    })
    timerRef.current = setTimeout(() => setIsMicrophoneMuted(true), waitMillis)
  }

  const stopCheckingMicrophone = () => {
    speechEventsRef.current?.stop()
    timerRef.current && clearTimeout(timerRef.current)
    speechEventsRef.current = null
    timerRef.current = null
  }

  // cleanup
  useEffect(() => stopCheckingMicrophone, [])

  return {
    startCheckingMicrophone,
    stopCheckingMicrophone,
    isMicrophoneMuted
  }
}
