import { CloseIcon, Typography } from '@astrid/components'
import { Dialog, DialogProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SlimCard } from 'components/SlimCard/SlimCard'
import { Colors } from 'MuiTheme'
import React, { PropsWithChildren, useCallback } from 'react'

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: 'hidden',
    '& > *': {
      padding: '0 !important'
    }
  },
  closeButton: {
    position: 'absolute',
    cursor: 'pointer',
    borderRadius: '100%',
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: '4px',
    width: '28px',
    height: '28px',
    backgroundColor: 'rgba(255,255,255, 0.25)',

    '&:hover': {
      backgroundColor: Colors.lightGrey
    }
  },
  title: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: 0
  }
}))

interface Props extends DialogProps {
  onClose: () => void
  hideCloseButton?: boolean
}

export const Modal = ({ children, onClose, hideCloseButton, ...rest }: PropsWithChildren<Props>) => {
  const classes = useStyles()
  const renderPaperComponent = useCallback((props) => <SlimCard {...props} noPadding />, [])

  return (
    <Dialog
      {...rest}
      PaperComponent={renderPaperComponent}
      classes={{ paper: classes.modal }}
      onClose={(_, reason) => reason !== 'backdropClick' && onClose()}>
      <>
        {rest.title && (
          <Typography variant="h4" className={classes.title}>
            {rest.title}
          </Typography>
        )}
        {children}
        {!hideCloseButton && <CloseIcon role="button" tabIndex={0} className={classes.closeButton} onClick={onClose} />}
      </>
    </Dialog>
  )
}
