import { Typography } from '@astrid/components'
import { Link, makeStyles } from '@material-ui/core'
import { ExpandMoreRounded } from '@material-ui/icons'
import { Colors } from 'MuiTheme'
import React, { PropsWithChildren, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  toggleText: {
    color: Colors.cobaltBlue,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  icon: {
    transition: '.25s',
    marginLeft: theme.spacing(1)
  },
  expanded: {
    transform: 'rotate(180deg)'
  }
}))

interface Props {
  showText: string
  hideText?: string
  onChange?: (isExpanded: boolean) => void
}

export const ExpandCollapse = ({ showText, hideText, onChange, children }: PropsWithChildren<Props>) => {
  const classes = useStyles()
  const [shouldShowContent, setShouldShowContent] = useState<boolean>(false)

  const handleClick = () => {
    const expanded = !shouldShowContent
    setShouldShowContent(expanded)
    onChange?.(expanded)
  }

  return (
    <>
      <Link component="button" variant="body2" onClick={handleClick}>
        <Typography className={classes.toggleText} variant="body">
          <span>{shouldShowContent ? hideText || showText : showText}</span>
          <ExpandMoreRounded className={`${shouldShowContent ? classes.expanded : ''} ${classes.icon}`} />
        </Typography>
      </Link>
      {shouldShowContent && children}
    </>
  )
}
