import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ErrorIcon } from '../../'
import './AlertContext.scss'

type AlertTypes = 'error'

interface AlertState {
  opened: boolean
  type: AlertTypes
  text: string
}

interface AlertContext extends AlertState {
  showAlert: (text: string, type?: AlertTypes) => void
  close: () => void
}

export const AlertContext = React.createContext<AlertContext>({
  opened: false,
  type: 'error',
  text: '',
  showAlert: () => {},
  close: () => {}
})

const HIDE_DURATION = 4000

const AlertContextProvider: React.FC = ({ children }) => {
  const [alertState, setAlertState] = useState<AlertState>({ opened: false, type: 'error', text: '' })
  const hideTimeout = useRef<NodeJS.Timeout | null>(null)
  const clearTextTimeout = useRef<NodeJS.Timeout | null>(null)

  const show = useCallback((text: string, type: AlertTypes = 'error') => {
    setAlertState({ opened: true, type, text })
    hideTimeout.current = setTimeout(close, HIDE_DURATION)
    //eslint-disable-next-line
  }, [])

  const close = useCallback(() => {
    setAlertState((state) => ({ ...state, opened: false }))
    clearTextTimeout.current = setTimeout(() => {
      setAlertState((state) => ({ ...state, text: '' }))
    }, 500)
  }, [])

  useEffect(() => {
    return () => {
      if (hideTimeout.current) clearTimeout(hideTimeout.current)
      if (clearTextTimeout.current) clearTimeout(clearTextTimeout.current)
      hideTimeout.current = null
      clearTextTimeout.current = null
    }
  }, [])

  return (
    <AlertContext.Provider
      value={{
        ...alertState,
        showAlert: show,
        close
      }}>
      {children}
    </AlertContext.Provider>
  )
}

const AlertContextConsumer: React.FC = () => {
  const { opened, text } = useContext(AlertContext)
  return (
    <>
      <div className={`alert-wrapper--${opened ? 'open' : 'closed'}`}>
        <ErrorIcon />
        <p>{text}</p>
      </div>
    </>
  )
}

export const AlertProvider: React.FC = ({ children }) => (
  <AlertContextProvider>
    {children}
    <AlertContextConsumer />
  </AlertContextProvider>
)
