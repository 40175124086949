import { Button, Card, Typography } from '@astrid/components'
import countries from '@astrid/components/src/utils/countries.json'
import { Grid, makeStyles } from '@material-ui/core'
import {
  ScreenNames,
  trackButtonClickEvent,
  trackCloseEvent,
  trackSectionShown,
  trackTaskClickEvent,
  trackTaskExpandCollapseEvent,
  trackVideoPauseEvent,
  trackVideoPlayEvent,
  trackVideoShownEvent
} from 'analytics/analytics'
import { Accordion } from 'components/Accordion/Accordion'
import { Avatar } from 'components/Avatar/Avatar'
import { Countdown } from 'components/Countdown/Countdown'
import { DefinitionListItem } from 'components/DefinitionListItem/DefinitionListItem'
import { CloseReason, Drawer } from 'components/Drawer/Drawer'
import { ExpandCollapse } from 'components/ExpandCollapse/ExpandCollapse'
import { Modal } from 'components/Modal/Modal'
import { SlimCard } from 'components/SlimCard/SlimCard'
import { VideoPreview } from 'components/Video/VideoPreview'
import { getYearsSince } from 'helpers/Date/Date'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { Colors } from 'MuiTheme'
import { Checkin } from 'pages/task/Checkin'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { ROUTES } from 'routes/routes'
import { useLoadInsightsQuery } from 'store/services/Insights/insightsApi'
import { useLoadLearnerQuery } from 'store/services/Learner/learnerApi'
import { useLoadActiveTasksQuery, useLoadArchivedStudySetsQuery } from 'store/services/StudySet/studySetApi'
import { EvaluationTutorTaskType, MainTutorTaskType, TaskDetails } from 'store/services/StudySet/types'
import { ChallengingWords } from '../task/components/ChallengingWords'
import { StudySet } from '../task/StudySet'
import { Insights } from './components/Insights'
import { TaskList } from './components/TaskList'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: theme.spacing(2),
    flexWrap: 'wrap'
  },
  pageHeading: {
    marginTop: theme.spacing(2)
  },
  list: {
    flex: 1
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${Colors.lightGrey}`,

    '&:last-child': {
      borderBottom: 'none'
    }
  },
  itemPadding: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    margin: 0,
    padding: 0,
    listStyle: 'none'
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: '100%',

    '& h4': {
      margin: 0,
      padding: theme.spacing(2)
    }
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  cardHeader: {
    borderBottom: `1px solid ${Colors.lightGrey}`
  },
  cardSection: {
    padding: theme.spacing(2)
  },
  studySetRow: {
    display: 'flex',

    '& > *': {
      flex: 1
    }
  },
  studySetTitle: {
    marginBottom: theme.spacing(1)
  },
  taskContainer: {
    borderBottom: `1px solid ${Colors.lightGrey}`,

    '&:last-child': {
      borderBottom: 'none'
    }
  },
  taskInfoContainer: {
    borderBottom: `1px solid ${Colors.lightGrey}`,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  task: {
    display: 'flex'
  },
  taskContent: {
    flex: 1,
    flexWrap: 'wrap',

    '& > *': {
      flex: '1 1 40%'
    }
  },
  taskMeta: {
    width: 300,
    justifyContent: 'right',

    '&::after': {
      content: '"›"',
      width: 46,
      height: 46,
      background: Colors.blueGrey,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      color: Colors.cobaltBlue,
      fontSize: 32
    }
  },
  checkInType: {
    marginBottom: 0
  },
  accordionContent: {
    width: '100%'
  },
  timeSpan: {
    fontSize: '0.875rem',
    padding: `0 ${theme.spacing(2)}px`,
    color: theme.palette.text.secondary
  },
  videoButton: {
    marginLeft: 'auto'
  },
  videoContainer: {
    height: '60vh',
    margin: theme.spacing(2),
    overflow: 'hidden',
    transform: 'translateZ(0)', // hack to make overflow hidden work in Safari
    borderRadius: theme.shape.borderRadius,

    '& > *': {
      height: '100%'
    }
  },
  profileListItem: {
    marginBottom: theme.spacing(1)
  }
}))

export const Learner = () => {
  const classes = useStyles()
  const [showIntroductionVideo, setShowIntroductionVideo] = useState(false)
  const { learnerId, taskId, step } =
    useParams<{ learnerId: string; studySetId?: string; step?: string; taskId?: string }>()
  const isDrawerClosed = step === undefined && taskId === undefined
  const history = useHistory()
  const { data: profile, isLoading: isLoadingProfile, isError: isErrorProfile } = useLoadLearnerQuery(learnerId)
  const { data: insights, isLoading: isLoadingInsights, isError: isErrorInsights } = useLoadInsightsQuery(learnerId)
  const {
    data: activeTasks,
    isLoading: isLoadingActive,
    isError: isErrorActive,
    refetch: refetchActive
  } = useLoadActiveTasksQuery(learnerId)
  const {
    data: archivedStudySets,
    isLoading: isLoadingArchived,
    isError: isErrorArchived,
    refetch: refetchArchived
  } = useLoadArchivedStudySetsQuery(learnerId)

  useTrackScreenShown(isDrawerClosed ? ScreenNames.LearnersProfile : undefined)

  const country = useMemo(() => countries.find((country) => profile?.country === country.code), [profile])

  const handleIntroductionVideoClick = () => {
    setShowIntroductionVideo(true)
    trackButtonClickEvent({ componentName: 'Introduction video' })
    trackVideoShownEvent({
      screenName: ScreenNames.LearnersProfile,
      componentName: 'Introduction video'
    })
  }

  const handleCloseIntroductionVideoModal = () => {
    setShowIntroductionVideo(false)
    trackButtonClickEvent({ componentName: 'Introduction video', componentValue: 'exit' })
  }

  const handlePlayVideo = () => trackVideoPlayEvent({ componentName: 'Introduction video' })

  const handlePauseVideo = () => trackVideoPauseEvent({ componentName: 'Introduction video' })

  const handleTaskClick = (task: TaskDetails, linkType?: string) =>
    trackTaskClickEvent({
      componentName: task.taskType,
      componentValue: `Task ID: ${task.taskId}`,
      interactionValue: linkType
    })

  const handleTaskExpand = (taskId: string, expanded: boolean) =>
    trackTaskExpandCollapseEvent({ componentName: 'Archived Tutor Set', componentValue: taskId, expanded })

  const handleHistoryExpand = (expanded: boolean) =>
    trackTaskExpandCollapseEvent({ componentName: 'Tutor Set history', expanded })

  const handleDrawerClose = (reason: CloseReason) => {
    history.push(`${ROUTES.LEARNERS}/${learnerId}`)
    trackCloseEvent({ componentName: 'Task drawer', reason })
  }

  useEffect(() => {
    if (isDrawerClosed) {
      refetchActive()
      refetchArchived()
    }
  }, [isDrawerClosed, refetchActive, refetchArchived])

  useEffect(() => {
    if (!isLoadingInsights && isDrawerClosed) {
      trackSectionShown({
        screenName: ScreenNames.LearnersProfile,
        componentName: 'Learners activity',
        componentValue: isErrorInsights ? 'Failed to load' : ''
      })

      trackSectionShown({
        screenName: ScreenNames.LearnersProfile,
        componentName: 'Challenging words',
        componentValue: isErrorInsights ? 'Failed to load' : ''
      })
    }
  }, [isLoadingInsights, isErrorInsights, isDrawerClosed])

  useEffect(() => {
    if (!isLoadingActive && isDrawerClosed) {
      trackSectionShown({
        screenName: ScreenNames.LearnersProfile,
        componentName: 'Next tasks',
        componentValue: isErrorActive ? 'Failed to load' : ''
      })
    }
  }, [isLoadingActive, isErrorActive, isDrawerClosed])

  useEffect(() => {
    const isArchivedSetsVisible = !isLoadingArchived && archivedStudySets && archivedStudySets.length > 0

    if (isArchivedSetsVisible && isDrawerClosed) {
      trackSectionShown({
        screenName: ScreenNames.LearnersProfile,
        componentName: 'Tutor Sets history',
        componentValue: isErrorArchived ? 'Failed to load' : ''
      })
    }
  }, [archivedStudySets, isLoadingArchived, isErrorArchived, isDrawerClosed])

  return profile ? (
    <>
      <Typography variant="h3" className={classes.pageHeading}>
        Learner Profile
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SlimCard title={`${profile.firstName || ''} ${profile.lastName || ''}`}>
                <div className={classes.wrapper}>
                  <div>
                    <Avatar url={profile.avatar} />
                  </div>
                  <dl className={classes.list}>
                    <div className={classes.row}>
                      {country && (
                        <DefinitionListItem label="Country" position="top" className={classes.profileListItem}>
                          {country.name}
                        </DefinitionListItem>
                      )}
                      <DefinitionListItem
                        label="Placement test level"
                        position="top"
                        className={classes.profileListItem}>
                        {profile.level}
                      </DefinitionListItem>
                    </div>
                    {typeof profile.birthYear === 'number' && (
                      <div className={classes.row}>
                        <DefinitionListItem label="Age" position="top" className={classes.profileListItem}>
                          {getYearsSince(profile.birthYear)}
                        </DefinitionListItem>
                      </div>
                    )}
                  </dl>
                </div>
                <Button
                  color="action"
                  size="small"
                  className={classes.videoButton}
                  disabled={!profile.introductionVideoUrl}
                  onClick={handleIntroductionVideoClick}>
                  Introduction video
                </Button>
              </SlimCard>
            </Grid>
            <Grid item xs={12}>
              <SlimCard noPadding>
                <header className={`${classes.header} ${classes.cardHeader}`}>
                  <Typography variant="h4">Learner's activity</Typography>
                  <Typography variant="body" className={classes.timeSpan}>
                    Last 7 days
                  </Typography>
                </header>
                <section className={classes.cardSection}>
                  {isLoadingInsights && (
                    <Typography variant="body" color="secondary">
                      Loading...
                    </Typography>
                  )}
                  {isErrorInsights && (
                    <Typography variant="body" color="secondary">
                      Failed to load insights.
                    </Typography>
                  )}
                  {insights && <Insights insights={insights} />}
                </section>
              </SlimCard>
            </Grid>
            <Grid item xs={12}>
              <SlimCard noPadding>
                <header className={`${classes.header} ${classes.cardHeader}`}>
                  <Typography variant="h4">Challenging words</Typography>
                  <Typography variant="body" className={classes.timeSpan}>
                    Last 7 days
                  </Typography>
                </header>

                <section className={classes.cardSection}>
                  {isLoadingInsights && (
                    <Typography variant="body" color="secondary">
                      Loading...
                    </Typography>
                  )}
                  {isErrorInsights && (
                    <Typography variant="body" color="secondary">
                      Failed to load insights.
                    </Typography>
                  )}
                  {insights && (
                    <>
                      {insights.phonemeSeqsWithWords?.length > 0 ? (
                        <ChallengingWords phonemeSeqsWithWords={insights.phonemeSeqsWithWords} />
                      ) : (
                        <Typography variant="body" color="secondary">
                          No challenging sounds or words yet
                        </Typography>
                      )}
                    </>
                  )}
                </section>
              </SlimCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <SlimCard noPadding>
                <header className={`${classes.header} ${classes.cardHeader}`}>
                  <Typography variant="h4">Next tasks</Typography>
                </header>
                {isLoadingActive && (
                  <div className={`${classes.item} ${classes.itemPadding}`}>
                    <Typography variant="body" color="secondary">
                      Loading...
                    </Typography>
                  </div>
                )}
                {isErrorActive && (
                  <div className={`${classes.item} ${classes.itemPadding}`}>
                    <Typography variant="body" color="secondary">
                      Failed to fetch tasks
                    </Typography>
                  </div>
                )}
                {activeTasks && (
                  <>
                    {activeTasks.length > 0 &&
                      activeTasks.map((task) => (
                        <div className={classes.taskContainer} key={task.taskId}>
                          {task.taskType === MainTutorTaskType.SpeakingChallengeFeedback ||
                          task.taskType === EvaluationTutorTaskType.SpeakingChallengeEvaluation ? (
                            <>
                              <Link
                                to={`${ROUTES.LEARNERS}/${learnerId}/studyset/${task.lessonInstance._id}/${
                                  task.taskType === EvaluationTutorTaskType.SpeakingChallengeEvaluation ? 4 : 0
                                }`}
                                onClick={() => handleTaskClick(task, 'Summary')}
                                className={classes.taskInfoContainer}>
                                <div className={`${classes.itemPadding}`}>
                                  <Typography variant="body" className={classes.studySetTitle}>
                                    {task.lessonInstance.title}
                                  </Typography>
                                  <div className={classes.task}>
                                    <dl className={`${classes.taskContent}`}>
                                      <DefinitionListItem label="Type" position="top">
                                        {task.taskType === MainTutorTaskType.SpeakingChallengeFeedback
                                          ? 'Speaking Challenge'
                                          : 'Performance Evaluation'}
                                      </DefinitionListItem>
                                      <DefinitionListItem label="Level" position="top">
                                        {task.lessonInstance.cefrLevel}
                                      </DefinitionListItem>
                                      <DefinitionListItem label="Learning goal" position="top">
                                        {task.lessonInstance.objective}
                                      </DefinitionListItem>
                                    </dl>
                                    <dl className={`${classes.studySetRow} ${classes.taskMeta}`}>
                                      {task.lessonInstance.state.deadline !== undefined && (
                                        <DefinitionListItem label="Answer within" position="top">
                                          <Countdown deadline={task.lessonInstance.state.deadline} />
                                        </DefinitionListItem>
                                      )}
                                    </dl>
                                  </div>
                                </div>
                              </Link>
                              <div className={`${classes.itemPadding}`}>
                                <TaskList
                                  studySet={task.lessonInstance}
                                  onListItemClick={(linkType: string) => handleTaskClick(task, linkType)}
                                />
                              </div>
                            </>
                          ) : (
                            <Link
                              to={`${ROUTES.LEARNERS}/${learnerId}/checkin/${task.taskId}`}
                              onClick={() => handleTaskClick(task)}
                              className={classes.taskInfoContainer}>
                              <div className={`${classes.itemPadding}`}>
                                <div className={classes.task}>
                                  <dl className={`${classes.studySetRow} ${classes.taskContent}`}>
                                    <DefinitionListItem label="Type" position="top" className={classes.checkInType}>
                                      Check-in
                                    </DefinitionListItem>
                                  </dl>
                                  <div className={`${classes.studySetRow} ${classes.taskMeta}`}></div>
                                </div>
                              </div>
                            </Link>
                          )}
                        </div>
                      ))}
                    {activeTasks.length === 0 && (
                      <div className={`${classes.item} ${classes.itemPadding}`}>
                        <Typography variant="body" color="secondary">
                          No tasks available
                        </Typography>
                      </div>
                    )}
                  </>
                )}
              </SlimCard>
            </Grid>
            <Grid item xs={12}>
              {archivedStudySets && archivedStudySets.length > 0 && (
                <ExpandCollapse
                  showText={`Show Tutor Set history (${archivedStudySets.length})`}
                  hideText={`Hide Tutor Set history (${archivedStudySets.length})`}
                  onChange={handleHistoryExpand}>
                  <SlimCard title="Tutor Set history" noPadding>
                    {archivedStudySets &&
                      archivedStudySets.map((studySet) => (
                        <div className={classes.item} key={studySet._id}>
                          <Accordion
                            summary={<Typography variant="body">{studySet.title}</Typography>}
                            onExpand={(expanded) => handleTaskExpand(studySet._id, expanded)}>
                            <div className={`${classes.accordionContent} ${classes.itemPadding}`}>
                              <Grid container spacing={4}>
                                <Grid item xs={8}>
                                  <dl className={classes.list}>
                                    <DefinitionListItem label="Learning goal" position="top">
                                      {studySet.objective}
                                    </DefinitionListItem>
                                    <DefinitionListItem label="Feedback to parent" position="top">
                                      {studySet.evaluation.commentFromTutor}
                                    </DefinitionListItem>
                                  </dl>
                                </Grid>
                                <Grid item xs={4}>
                                  <dl className={classes.list}>
                                    <DefinitionListItem label="Level" position="top">
                                      {studySet.cefrLevel}
                                    </DefinitionListItem>
                                    <DefinitionListItem label="Tutor" position="top">
                                      {studySet.tutor.firstName} {studySet.tutor.lastName}
                                    </DefinitionListItem>
                                    <DefinitionListItem label="Performance evaluation" position="top">
                                      {studySet.evaluation.learnerMetGoal
                                        ? 'Mastered the learning goal'
                                        : 'Needs more practice'}
                                    </DefinitionListItem>
                                  </dl>
                                </Grid>
                              </Grid>
                            </div>
                          </Accordion>
                        </div>
                      ))}
                  </SlimCard>
                </ExpandCollapse>
              )}
              {isLoadingArchived && (
                <Card title="Tutor Set history">
                  <Typography variant="body" color="secondary">
                    Loading archived Tutor Sets...
                  </Typography>
                </Card>
              )}
              {isErrorArchived && (
                <SlimCard>
                  <Typography variant="body" color="secondary">
                    Failed to load archived Tutor Sets
                  </Typography>
                </SlimCard>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Drawer open={step !== undefined || taskId !== undefined} onClose={handleDrawerClose}>
        {taskId !== undefined && <Checkin />}
        {step !== undefined && <StudySet />}
      </Drawer>

      {!!profile.introductionVideoUrl && (
        <Modal
          keepMounted
          title="Introduction video"
          open={showIntroductionVideo}
          onClose={handleCloseIntroductionVideoModal}>
          <div className={classes.videoContainer} data-testid="introVideoContainer">
            <VideoPreview
              src={profile.introductionVideoUrl}
              preload="metadata"
              onPlay={handlePlayVideo}
              onPause={handlePauseVideo}
            />
          </div>
        </Modal>
      )}
    </>
  ) : (
    <>
      {isLoadingProfile && (
        <Typography variant="body" color="secondary">
          Loading...
        </Typography>
      )}
      {isErrorProfile && (
        <Typography variant="body" color="secondary">
          Could not find user.
        </Typography>
      )}
    </>
  )
}
