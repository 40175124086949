import { ExerciseType, StudySetComponentType, Typography } from '@astrid/components'
import { Divider, makeStyles, Typography as MuiTypography } from '@material-ui/core'
import { ScreenNames } from 'analytics/analytics'
import { useAppDispatch } from 'hooks/redux'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { Colors } from 'MuiTheme'
import { ComprehensionQuestion } from 'pages/task/exercises/ComprehensionQuestion'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useLoadStudySetInsightsQuery } from 'store/services/Insights/insightsApi'
import { studySetApi, useUpdateStudySetReviewedMutation } from 'store/services/StudySet/studySetApi'
import {
  MainTutorTaskType,
  SectionTag,
  StudySetComponent,
  StudySetModel,
  StudySetSection
} from 'store/services/StudySet/types'
import { ChallengingWords } from '../../components/ChallengingWords'
import { Step, StepProps } from '../../components/Step'
import { Conversation } from '../../exercises/Conversation'
import { ReadingPage } from '../../exercises/ReadingPage'
import { ReadingStory } from '../../exercises/ReadingStory'
import { WordOrSentenceBuilder } from '../../exercises/WordOrSentenceBuilder'
import { WordPractice } from '../../exercises/WordPractice'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${theme.spacing(4)}px`
  },
  title: {
    fontSize: '1.7rem',
    margin: `${theme.spacing(1)}px 0`
  },
  attempts: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  components: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`
  },
  challengingHeading: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  section: {
    border: `1px solid ${Colors.lightGrey}`,
    padding: theme.spacing(4),
    position: 'relative',
    marginBottom: '2rem',
    borderRadius: theme.shape.borderRadius
  },
  sectionTitle: {
    position: 'absolute',
    top: -12,
    left: 0,
    margin: 0,
    marginLeft: theme.spacing(3),
    padding: `0 ${theme.spacing(1)}px`,
    background: Colors.white,
    color: Colors.grey
  }
}))

interface Props extends StepProps {
  studySet: StudySetModel
  selfStudies: StudySetSection[]
}

const sectionTitles = {
  [SectionTag.GetReady]: 'Get ready',
  [SectionTag.Learn]: 'Learn',
  [SectionTag.Practice]: 'Practice'
}

export const SelfStudyResults = ({ selfStudies, studySet, ...rest }: Props) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const { learnerId } = useParams<{ learnerId: string }>()
  const { data: insights, isFetching, isError } = useLoadStudySetInsightsQuery(studySet._id)
  const [updateReviewed] = useUpdateStudySetReviewedMutation()

  useTrackScreenShown(ScreenNames.SelfStudyResults)

  useEffect(() => {
    if (!studySet.tutorReviewedState?.selfStudyResults) {
      const reviewedState = { ...studySet.tutorReviewedState, selfStudyResults: true }

      updateReviewed({
        id: studySet._id,
        reviewedState
      })
        .unwrap()
        .then(() => {
          dispatch(
            studySetApi.util.updateQueryData('loadActiveTasks', learnerId, (drafts) => {
              const draftTask = drafts.find(
                (draft) =>
                  draft.taskType === MainTutorTaskType.SpeakingChallengeFeedback &&
                  draft.lessonInstance._id === studySet._id
              )
              if (draftTask && draftTask.taskType === MainTutorTaskType.SpeakingChallengeFeedback) {
                draftTask.lessonInstance.tutorReviewedState = reviewedState
              }
            })
          )
        })
    }
  }, [updateReviewed, studySet, dispatch, learnerId])

  const shouldAddDivider = (components: StudySetComponent[], index: number) => {
    const isLast = index === components.length - 1
    const isBook = components[index].type === StudySetComponentType.Book
    const isNextBook = !isLast && components[index + 1].type === StudySetComponentType.Book

    return !isLast && (isBook || isNextBook)
  }

  return (
    <Step {...rest}>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.title}>
          {studySet.title}
        </Typography>
        {selfStudies.length > 0 && (
          <Typography variant="h4" className={classes.attempts}>
            Attempts
          </Typography>
        )}
      </div>
      {selfStudies.length > 0 ? (
        <>
          {selfStudies.map((section) => (
            <div className={classes.section} key={section.sectionName}>
              <MuiTypography variant="h5" className={classes.sectionTitle} color="secondary">
                {sectionTitles[section.sectionName]}
              </MuiTypography>
              <div className={classes.components} data-testid="selfStudyComponents">
                {section.components.map((component, index) => (
                  <div data-testid="selfStudyComponent" key={index}>
                    {component.type === StudySetComponentType.Book && (
                      <ReadingStory
                        bookType={component.task.bookType}
                        bookTitle={component.task.bookTitle}
                        exerciseResults={component.result?.exerciseResults}
                      />
                    )}
                    {!!(
                      (component.type === StudySetComponentType.Exercise ||
                        component.type === StudySetComponentType.Video) &&
                      component.result
                    ) && (
                      <>
                        {component.result.exerciseResult.exercise.type === ExerciseType.ReadSentence && (
                          <ReadingPage exerciseResult={component.result.exerciseResult} noHeading />
                        )}
                        {component.result.exerciseResult.exercise.type === ExerciseType.MultiChoiseQuestion && (
                          <ComprehensionQuestion exerciseResult={component.result.exerciseResult} noHeading />
                        )}
                        {component.result.exerciseResult.exercise.type === ExerciseType.SentenceBuilder && (
                          <WordOrSentenceBuilder exerciseResult={component.result.exerciseResult} noHeading />
                        )}
                        {component.result.exerciseResult.exercise.type === ExerciseType.WordBuilder && (
                          <WordOrSentenceBuilder exerciseResult={component.result.exerciseResult} noHeading />
                        )}
                        {component.result.exerciseResult.exercise.type === ExerciseType.WordPractice && (
                          <WordPractice exerciseResult={component.result.exerciseResult} noHeading />
                        )}
                        {component.result.exerciseResult.exercise.type === ExerciseType.Conversation && (
                          <Conversation exerciseResult={component.result.exerciseResult} />
                        )}
                      </>
                    )}
                    {shouldAddDivider(section.components, index) && <Divider className={classes.divider} />}
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className={classes.section}>
            <MuiTypography variant="h5" className={classes.sectionTitle} color="secondary">
              Challenging sounds and words
            </MuiTypography>
            {insights && insights.phonemeSeqsWithWords.length > 0 ? (
              <ChallengingWords phonemeSeqsWithWords={insights.phonemeSeqsWithWords} extended />
            ) : (
              <>
                {!isFetching && !isError && (
                  <Typography variant="body" color="secondary">
                    No challenging sounds or words
                  </Typography>
                )}
                {isFetching && (
                  <Typography variant="body" color="secondary">
                    Loading...
                  </Typography>
                )}
                {isError && (
                  <Typography variant="body" color="secondary">
                    Failed to get challenging sounds and words
                  </Typography>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <Typography variant="body" color="secondary">
          This Tutor Set does not contain any self-studies.
        </Typography>
      )}
    </Step>
  )
}
