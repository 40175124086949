export enum EVENT {
  TUTOR_CLICK_TASK_IN_TASK_LIST = 'Tutor clicks task in task list',
  TUTOR_CLICK_LEARNER_IN_WAITING_LIST = 'Tutor clicks learner in waiting list',
  TUTOR_CLICK_VIEW_STUDY_SET_HISTORY = 'Tutor clicks View study set history',
  TUTOR_CLICK_TASK_SELF_STUDY_RESULTS = 'Tutor clicks self-study results',
  TUTOR_CLICK_TASK_SPEAKING_CHALLENGE = 'Tutor clicks Speaking Challenge',
  TUTOR_CLICK_TASK_FEEDBACK_VIDEO = 'Tutor clicks Feedback video',
  TUTOR_CLICK_TASK_SPEAKING_CHALLENGE_REFORMULATION = 'Tutor clicks Speaking Challenge reformulation',
  TUTOR_CLICK_TASK_PERFORMANCE_EVALUATION = 'Tutor clicks Performance evaluation',
  TUTOR_SAVE_NOTES = 'Tutor writes notes',
  TUTOR_CLICK_BACK_TO_TASK_LIST = 'Tutor clicks back to task list',
  TUTOR_CLICK_BACK_TO_LEARNER_PROFILE = 'Tutor clicks back to learner profile',
  TUTOR_SAVE_AND_SEND_TO_PARENT = 'Tutor saves and sends to parent',
  TUTOR_CLICK_EVALUATION_COMMENT = 'Tutor clicks evaluation comment',
  TUTOR_SELECT_NEEDS_MORE_PRACTICE = 'Tutor selects Needs more practice',
  TUTOR_SELECT_MASTERED_LEARNING_GOAL = 'Tutor selects Mastered learning goal',
  TUTOR_PLAY_AUDIO_RECORDING = 'Tutor plays audio recording',
  TUTOR_PLAY_SPEAKING_CHALLENGE_VIDEO = 'Tutor plays Speaking Challenge video',
  TUTOR_RECORD_FEEDBACK_VIDEO = 'Tutor records feedback video',
  TUTOR_SEND_FEEDBACK_VIDEO = 'Tutor sends feedback video',
  TUTOR_PLAY_SPEAKING_CHALLENGE_REFORMULATION = 'Tutor plays Speaking Challenge reformulation'
}
