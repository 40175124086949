import React, { useState, useEffect, useCallback, useRef } from 'react'

import { Typography, ListenSentence, AudioRecorder, withSoundEffectsContext, ArrowRight } from '../../../index'
import { ScoreResponse, ReadSentenceExerciseData, InjectedSoundProps } from '../../../types'
import { ExerciseBaseProps } from '../types'

import ReplaySentence from './ReplaySentence/ReplaySentence'
import ReadingScoreDisplay from './ReadingScoreDisplay/ReadingScoreDisplay'
import WordWithScore from './WordScore/WordWithScore'
import CircularProgressBar from '../../CircularProgressBar/CircularProgressBar'
import Button from '../../Button/Button'

import AbbeWithBook from '../../../graphics/abbe_with_book.png'
import AlfredWithPencil from '../../../graphics/alfred_with_pencil.png'
import AbbeAndAlva from '../../../graphics/abbe_and_alva.png'

import './ReadingExercise.scss'

interface IProps extends ExerciseBaseProps, InjectedSoundProps {}

function getRandomExerciseImage(): string {
  const images = [AbbeWithBook, AlfredWithPencil, AbbeAndAlva]
  return images[Math.floor(Math.random() * images.length)]
}

interface IState {
  mistakeCount: number
  recording: Blob | null
  sanaRate: ScoreResponse | null
  imgLoaded: boolean
  continueDisabled: boolean
  success: boolean
}

const ReadingExercise: React.FC<IProps> = ({
  exercise,
  country,
  onCurrentExerciseCompleted,
  getSanaScore,
  displayDetailedScore,
  demo,
  playSound
}) => {
  const [state, setState] = useState<IState>({
    mistakeCount: 0,
    recording: null,
    sanaRate: null,
    imgLoaded: false,
    continueDisabled: false,
    success: false
  })
  const listenSentenceRef = useRef<ListenSentence>(null)
  const isSuccess = state.sanaRate?.overall_score && state.sanaRate.overall_score >= 60
  const { sentence } = exercise.data as ReadSentenceExerciseData

  const handleRecording = async (recording: Blob) => {
    try {
      const { data } = await getSanaScore({ audio: recording, phrase: sentence, withoutAuth: demo })
      setState((state) => ({
        ...state,
        sanaRate: data,
        recording,
        success: state.success || data.overall_score >= 75
      }))
      rate(data)
    } catch (error) {
      setState((state) => ({ ...state, recording }))
    }
  }

  const rate = (data: ScoreResponse) => {
    const { overall_score } = data
    if (overall_score >= 75) {
      playSound('exercise-finished')
    } else {
      playSound('incorrect')
      setState((state) => ({
        ...state,
        mistakeCount: state.mistakeCount + 1
      }))
    }
  }

  const renderWords = () => {
    const { sanaRate } = state

    if (sanaRate && sanaRate.word_scores && sanaRate.word_scores.length > 0) {
      if (displayDetailedScore) {
        return sanaRate.word_scores.map((wordScore, i) => (
          <WordWithScore demo={demo} key={`${wordScore.word}${i}`} score={wordScore} />
        ))
      } else {
        return sanaRate.word_scores.map((word, i) => {
          const colour = word.score < 40 ? '--wrong' : word.score < 75 ? '--ok' : '--success'
          return (
            <Typography
              key={`${word}${i}`}
              variant={sanaRate.word_scores.length < 25 ? 'exerciseM' : 'exerciseS'}
              className={`reading-exercise__word${colour}`}>
              {word.word}
            </Typography>
          )
        })
      }
    }

    const words = sentence.split(' ')
    return words.map((word, i) => (
      <Typography
        key={`${word}${i}`}
        variant={words.length < 25 ? 'exerciseM' : 'exerciseS'}
        className="reading-exercise__word">
        {word}
      </Typography>
    ))
  }

  const onStartRecording = useCallback(() => {
    listenSentenceRef.current?.stopPlaying()
    setState((state) => ({ ...state, recording: null, sanaRate: null }))
  }, [])

  const pickScoreColor = (score: number) => {
    return score < 40 ? '#e80f62' : score < 75 ? '#ffdc4a' : '#5cb57b'
  }

  useEffect(() => {
    setState({
      mistakeCount: 0,
      recording: null,
      sanaRate: null,
      imgLoaded: false,
      continueDisabled: false,
      success: false
    })

    if (demo) {
      exercise.imageUrl = getRandomExerciseImage()
    }
  }, [exercise._id])

  return (
    <div className={`reading-exercise${displayDetailedScore ? '--detailed' : ''}`}>
      <div className="reading-exercise__listen-button">
        <ListenSentence
          recordingUrl={exercise.recordingUrl}
          ref={listenSentenceRef}
          sentence={sentence}
          country={country}
          jump={state.mistakeCount !== 0 && state.mistakeCount % 3 === 0}
        />
      </div>
      <div className={`reading-exercise__content`}>
        <img src={exercise.imageUrl} alt="Illustration" />
        <div className={`reading-exercise__sentence-container${isSuccess ? '--success' : ''}`}>{renderWords()}</div>
        <div className="reading-exercise__controls">
          {state.sanaRate?.overall_score !== undefined && (
            <div className="reading-exercise__controls-progress">
              <CircularProgressBar
                percentage={state.sanaRate.overall_score}
                size={60}
                strokeWidth={6}
                progressColor={pickScoreColor(state.sanaRate.overall_score)}>
                <span
                  style={{ margin: 0, padding: 0, color: pickScoreColor(state.sanaRate.overall_score), fontSize: 18 }}>
                  {state.sanaRate?.overall_score || 0}
                </span>
              </CircularProgressBar>
            </div>
          )}

          <AudioRecorder onStopRecording={handleRecording} onStartRecording={onStartRecording} />
          {demo && (
            <Typography variant="button" color="secondary" style={{ marginTop: 4 }}>
              Press and hold
            </Typography>
          )}

          {(state.success || state.mistakeCount > 4) && (
            <Button
              className="reading-exercise__controls-button"
              color="light-blue"
              onClick={() => onCurrentExerciseCompleted(undefined, true)}>
              <ArrowRight />
            </Button>
          )}
        </div>
        {displayDetailedScore && <ReadingScoreDisplay sanaRate={state.sanaRate} />}
      </div>
      {/* {state.mistakeCount > 4 && (
        <Button
          className="reading-exercise__continue-button"
          disabled={state.continueDisabled}
          size="small"
          onClick={() => {
            onCurrentExerciseCompleted()
            setState((s) => ({ ...s, continueDisabled: true }))
          }}>
          Continue
          <ArrowRight />
        </Button>
      )} */}
      {displayDetailedScore && <ReplaySentence recording={state.recording} className="reading-exercise__replay" />}
    </div>
  )
}

export default withSoundEffectsContext(ReadingExercise)
