import { Typography } from '@astrid/components'
import { makeStyles } from '@material-ui/core/styles'
import { Label } from 'components/Label/Label'
import React, { PropsWithChildren } from 'react'
import cx from 'classnames'

interface Props {
  label: string
  className?: string
  position?: 'top' | 'bottom' | 'left'
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  value: {
    margin: 0
  },
  column: {
    flexDirection: 'column'
  },
  row: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    alignItems: 'center',

    '& $label': {
      width: '80px'
    }
  },
  label: {},
  reversed: {
    flexDirection: 'column-reverse'
  }
}))

export const DefinitionListItem = ({ label, position, className, children }: PropsWithChildren<Props>) => {
  const classes = useStyles()
  const hasDefaultPosition = !position || position === 'left'

  const itemClasses = cx({
    [classes.wrapper]: true,
    [classes.reversed]: position === 'bottom',
    [classes.row]: hasDefaultPosition,
    [classes.column]: !hasDefaultPosition
  })

  return (
    <div className={`${itemClasses} ${className || ''}`}>
      <dt className={classes.label}>
        <Label>{label}</Label>
      </dt>
      <dd className={classes.value}>
        <Typography variant="body">{children}</Typography>
      </dd>
    </div>
  )
}
