import React, { useState } from 'react'
import { Snackbar as MuiSnackbar, SnackbarCloseReason, SnackbarProps } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  successMessage: {
    top: 64
  }
}))

interface AlertProps {
  message: string
  onClose?: () => void
}

export const Snackbar = (props: SnackbarProps) => {
  const [open, setOpen] = useState(true)
  const handleClose = (event: React.SyntheticEvent<any>, reason: SnackbarCloseReason) => {
    setOpen(false)
    props.onClose && props.onClose(event, reason)
  }
  return <MuiSnackbar {...props} open={open} autoHideDuration={5000} onClose={handleClose}></MuiSnackbar>
}

export const AlertBox = (props: AlertProps) => {
  return (
    <Snackbar onClose={props.onClose}>
      <MuiAlert elevation={0} variant="filled" severity="error">
        {props.message}
      </MuiAlert>
    </Snackbar>
  )
}

export const SuccessMessage = (props: AlertProps) => {
  const classes = useStyles()

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={props.onClose}
      className={classes.successMessage}>
      <MuiAlert elevation={0} variant="filled" severity="success">
        {props.message}
      </MuiAlert>
    </Snackbar>
  )
}
