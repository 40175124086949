import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 80,
    height: 60,
    flexShrink: 0
  },
  image: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 10
  }
}))

interface Props {
  src?: string
  alt?: string
}

export const ExerciseImage = ({ src, alt }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      {!!src && <img src={src} alt={alt || 'exercise image'} className={classes.image} />}
    </div>
  )
}
