import { Card as AstridCard } from '@astrid/components'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from 'MuiTheme'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: `0 4px 0 ${Colors.lightGrey}`,
    overflow: 'hidden',
    transform: 'translateZ(0)', // hack to make overflow hidden work in Safari
    position: 'relative',

    '& .astrid-card__header': {
      padding: theme.spacing(2)
    },

    '& .astrid-card__content': {
      padding: theme.spacing(2)
    }
  },
  noPadding: {
    '& .astrid-card__content': {
      padding: 0
    }
  }
}))

interface Props {
  noPadding?: boolean
}

export const SlimCard = ({ noPadding, ...rest }: Props & Parameters<typeof AstridCard>[0]) => {
  const classes = useStyles()

  return <AstridCard {...rest} className={`${classes.card} ${noPadding ? classes.noPadding : ''}`} />
}
