import React from 'react'
import { ReactComponent as DefaultAvatar } from 'assets/default-avatar.svg'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  avatarWrapper: {
    borderRadius: '50%',
    width: 80,
    height: 80,
    overflow: 'hidden'
  },
  avatar: {
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  },
  small: {
    width: 40,
    height: 40
  }
}))

interface Props {
  url?: string
  size?: 'small' | 'default'
}

export const Avatar = ({ url, size }: Props) => {
  const classes = useStyles()
  return (
    <div className={`${classes.avatarWrapper} ${size === 'small' ? classes.small : ''}`}>
      {url ? <img className={classes.avatar} src={url} alt="avatar" /> : <DefaultAvatar />}
    </div>
  )
}
