import Cookies from 'helpers/Cookies'

const TOKEN_NAME = 'tutor_token'

export class UserTokenApi {
  static getUserToken = () => Cookies.getItem(TOKEN_NAME)
  static setUserToken = (token: string) => Cookies.setItem(TOKEN_NAME, token, 100)
  static removeUserToken = () => {
    Cookies.removeItem(TOKEN_NAME)
    window.location.reload()
  }
}
