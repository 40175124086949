import React, { PropsWithChildren } from 'react'
import { Link, makeStyles } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(2)}px`,
    height: 60
  },
  right: {
    justifyContent: 'right'
  }
}))

interface Props {
  to: string
  onClick?: () => void
  align?: 'left' | 'right'
}

export const TableCellLink = ({ to, children, align, onClick }: PropsWithChildren<Props>) => {
  const classes = useStyles()
  return (
    <Link
      className={`${classes.link} ${align === 'right' ? classes.right : ''}`}
      component={RouterLink}
      to={to}
      onClick={onClick}
      underline="none">
      {children}
    </Link>
  )
}
