import React from 'react'
import { ScoreResponse } from '../../../../types'
import { Typography, CircularProgressBar } from '../../../../index'

import './ReadingScoreDisplay.scss'

interface ReadingScoreDisplayProps {
  sanaRate: ScoreResponse | null
}

const ReadingScoreDisplay: React.FC<ReadingScoreDisplayProps> = ({ sanaRate }) => {
  if (!sanaRate) return null

  const {
    assessment: { content, fluency, pronunciation }
  } = sanaRate

  const getScoreColor = (score: number) => {
    if (score < 50) {
      return 'orangered'
    } else if (score < 80) {
      return '#FFAC1F'
    } else {
      return '#43c942'
    }
  }

  return (
    <section className="reading-score-display">
      <div className="reading-score-display__layout">
        <CircularProgressBar
          justForShow
          percentage={pronunciation.score}
          size={58}
          progressColor={getScoreColor(pronunciation.score)}
          backgroundColor="#EDEDF0"
          strokeWidth={4}>
          {pronunciation.score}
        </CircularProgressBar>
        <div className="reading-score-display__description">
          <Typography variant="body">Pronunciation</Typography>
          {pronunciation.explanation.map(({ description }, i) => (
            <Typography variant="button" key={i}>
              {description}
            </Typography>
          ))}
        </div>
      </div>
      <div className="reading-score-display__layout">
        <CircularProgressBar
          justForShow
          percentage={content.score}
          size={58}
          progressColor={getScoreColor(content.score)}
          backgroundColor="#EDEDF0"
          strokeWidth={4}>
          {content.score}
        </CircularProgressBar>
        <div className="reading-score-display__description">
          <Typography variant="body">Content</Typography>
          {content.explanation.map(({ description }, i) => (
            <Typography variant="button" key={i}>
              {description}
            </Typography>
          ))}
        </div>
      </div>
      <div className="reading-score-display__layout">
        <CircularProgressBar
          justForShow
          percentage={fluency.score}
          size={58}
          progressColor={getScoreColor(fluency.score)}
          backgroundColor="#EDEDF0"
          strokeWidth={4}>
          {fluency.score}
        </CircularProgressBar>
        <div className="reading-score-display__description">
          <Typography variant="body">Fluency - {fluency.words_per_minute} words per minute</Typography>
          {fluency.explanation.map(({ description }, i) => (
            <Typography variant="button" key={i}>
              {description}
            </Typography>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ReadingScoreDisplay
