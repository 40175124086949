import React from 'react'
import lottie, { AnimationItem } from 'lottie-web'
import { CharacterName, CharacterMood } from '../../types/Character'

import AbbeBored from './lottie/Abbe/abbe_bored.json'
import AbbeExcited from './lottie/Abbe/abbe_excited.json'
import AbbeIdle from './lottie/Abbe/abbe_idle.json'
import AbbeWrong from './lottie/Abbe/abbe_no_wrong.json'
import AbbeCorrect from './lottie/Abbe/abbe_yes_correct.json'
import AbbeWave from './lottie/Abbe/abbe_wave.json'

import AlfredBored from './lottie/Alfred/alfred_bored.json'
import AlfredExcited from './lottie/Alfred/alfred_excited.json'
import AlfredIdle from './lottie/Alfred/alfred_idle.json'
import AlfredWrong from './lottie/Alfred/alfred_no_wrong.json'
import AlfredCorrect from './lottie/Alfred/alfred_yes_correct.json'
import AlfredWave from './lottie/Alfred/alfred_wave.json'

import AlvaBored from './lottie/Alva/alva_bored.json'
import AlvaExcited from './lottie/Alva/alva_excited.json'
import AlvaIdle from './lottie/Alva/alva_idle.json'
import AlvaWrong from './lottie/Alva/alva_no_wrong.json'
import AlvaCorrect from './lottie/Alva/alva_yes_correct.json'
import AlvaWave from './lottie/Alva/alva_wave.json'

const CHARACTER_MAP: { [key in CharacterName]: { [key in CharacterMood]: any } } = {
  Abbe: {
    idle: AbbeIdle,
    bored: AbbeBored,
    wave: AbbeWave,
    correct: AbbeCorrect,
    wrong: AbbeWrong,
    excited: AbbeExcited
  },
  Alfred: {
    idle: AlfredIdle,
    bored: AlfredBored,
    wave: AlfredWave,
    correct: AlfredCorrect,
    wrong: AlfredWrong,
    excited: AlfredExcited
  },
  Alva: {
    idle: AlvaIdle,
    bored: AlvaBored,
    wave: AlvaWave,
    correct: AlvaCorrect,
    wrong: AlvaWrong,
    excited: AlvaExcited
  }
}

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  characterName: CharacterName
  mood: CharacterMood
  changeCharacterMood: (mood: CharacterMood) => void
  neverBored?: boolean
}

class LottieCharacter extends React.Component<IProps> {
  private characterRef = React.createRef<HTMLDivElement>()
  private boredTimeout: any
  private animationItem: AnimationItem | null = null

  componentDidMount() {
    this.loadCharacter()
  }

  componentDidUpdate() {
    this.loadCharacter()
  }

  componentWillUnmount() {
    this.props.changeCharacterMood('idle')
    clearTimeout(this.boredTimeout)
  }

  loadCharacter = () => {
    clearTimeout(this.boredTimeout)
    const { characterName, mood, changeCharacterMood, neverBored } = this.props
    this.animationItem?.destroy()
    this.animationItem = lottie.loadAnimation({
      container: this.characterRef.current!,
      animationData: CHARACTER_MAP[characterName][mood],
      autoplay: true,
      loop: mood !== 'wrong' && mood !== 'correct',
      name: 'Character Animation',
      renderer: 'svg'
    })

    this.animationItem.addEventListener('complete', () => {
      if (mood === 'wrong' || mood === 'correct') {
        changeCharacterMood('idle')
      }
    })

    if (mood !== 'bored' && mood !== 'excited' && !neverBored) {
      this.boredTimeout = setTimeout(() => changeCharacterMood('bored'), 10000)
    }
  }

  render() {
    const { characterName, mood, changeCharacterMood, neverBored, ...rest } = this.props
    return <div ref={this.characterRef} {...rest} />
  }
}

export default LottieCharacter
