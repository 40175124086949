import React, { PropsWithChildren } from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem'
  }
}))

interface Props {
  className?: string
}

export const Label = ({ className, children }: PropsWithChildren<Props>) => {
  const classes = useStyles()
  return <span className={`${classes.label} ${className || ''}`}>{children}</span>
}
