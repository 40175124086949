import React, { useRef, useState } from 'react'
import { Delete, Add } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { Typography } from '@astrid/components'
import { UploadButton } from 'components/UploadButton/UploadButton'
import { Colors } from 'MuiTheme'
import { ConfirmPopup } from 'components/ConfirmPopup/ConfirmPopup'

interface Props {
  file: File | string | null | undefined
  onUpload: (image: File) => Promise<void>
  onRemove?: () => Promise<void>
  altText?: string
}

const SIZE_LIMIT_MB = 5

const useStyles = makeStyles((theme) => ({
  container: {
    width: '140px'
  },
  imageContainer: {
    height: '140px',
    width: '140px',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey[400],
    overflow: 'hidden',
    position: 'relative'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  highlight: {
    '&:hover $image': {
      opacity: 0.1,
      filter: 'blur(2px)'
    },

    '&:hover $deleteButton': {
      opacity: 1
    }
  },
  deleteButton: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  uploadButton: {
    width: '100%',
    height: '100%'
  },
  input: {
    width: 0,
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute'
  },
  error: {
    color: Colors.cerise
  }
}))

type Status = { type: 'IDLE' } | { type: 'IN_PROGRESS' } | { type: 'ERROR'; error: 'size' | 'format' }

export const ImageUploader = ({ file, onUpload, onRemove, altText }: Props) => {
  const classes = useStyles()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [status, setStatus] = useState<Status>({ type: 'IDLE' })

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i
    const image = e.target.files?.item(0)
    const fileName = e.target.value
    if (image) {
      if (image.size > SIZE_LIMIT_MB * 1000000) {
        setStatus({ type: 'ERROR', error: 'size' })

        return
      }
      if (!allowedExtensions.exec(fileName)) {
        setStatus({ type: 'ERROR', error: 'format' })
        return
      }

      setStatus({ type: 'IN_PROGRESS' })
      try {
        await onUpload(image)
      } catch (error) {
        console.log(error)
      }

      setStatus({ type: 'IDLE' })
    }
  }

  return file ? (
    <div className={`${classes.imageContainer} ${!!onRemove ? classes.highlight : ''}`}>
      <img
        className={classes.image}
        src={typeof file === 'string' ? file : URL.createObjectURL(file)}
        alt={altText || ''}
      />
      {!!onRemove && (
        <ConfirmPopup
          onConfirm={onRemove}
          text="Are you sure you want to delete the image?"
          confirmText="Yes"
          cancelText="No">
          <div role="button" className={`${classes.deleteButton}`}>
            <Delete fontSize="large" /> <span>Remove image</span>
          </div>
        </ConfirmPopup>
      )}
    </div>
  ) : (
    <div className={classes.container}>
      <div className={`${classes.imageContainer}`}>
        <input
          ref={inputRef}
          className={classes.input}
          type="file"
          name="image"
          id="imageUpload"
          accept=".jpg,.jpeg,.png,.gif"
          onChange={handleUpload}
          disabled={status.type === 'IN_PROGRESS'}
        />
        <UploadButton onClick={() => inputRef.current && inputRef.current.click()} className={classes.uploadButton}>
          {status.type === 'IN_PROGRESS' ? (
            <>
              <CircularProgress size={24} color="inherit" />
              <span>Uploading...</span>
            </>
          ) : (
            <>
              <Add fontSize="large" />
              <span>Upload image</span>
            </>
          )}
        </UploadButton>
      </div>
      {status.type === 'ERROR' && (
        <Typography variant="body" className={classes.error}>
          {status.error === 'size'
            ? `The file is too large. Max ${SIZE_LIMIT_MB} MB.`
            : 'Please upload a jpg, gif, or png image.'}
        </Typography>
      )}
    </div>
  )
}
