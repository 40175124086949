import { TutorEventing } from '@astrid/eventing'
import { CloseReason } from 'components/Drawer/Drawer'
import { Analytics } from './analyticsApi'

export enum ScreenNames {
  TaskList = 'Task list',
  LearnerList = 'Learner list',
  Profile = 'Profile',
  CheckIn = 'Check-in',
  CheckInSent = 'Check-in sent',
  LearnersProfile = 'Learners profile',
  SelfStudyResults = 'Self-study results',
  SpeakingChallenge = 'Speaking challenge',
  FeedbackVideo = 'Feedback video',
  FeedbackVideoSent = 'Feedback video sent',
  SpeakingChallengeReformulation = 'Speaking challenge reformulation',
  PerformanceEvaluation = 'Performance evaluation',
  TutorSetCompleted = 'Tutor set completed'
}

interface ComponentData {
  componentName: string
  componentValue?: string
}

interface TaskEventData {
  taskTitle: string
  taskId: string
  taskStatus?: string
}

interface ComponentShownEventData extends ComponentData {
  screenName: ScreenNames
}

interface ExpandComponentData extends ComponentData {
  expanded: boolean
}

/* GENERAL EVENTS */
export const trackScreenShown = (screenName: string) =>
  Analytics.track(new TutorEventing.Events.ScreenShown({ url: window.location.pathname, screen_name: screenName }))

const trackComponentShown = ({
  componentName,
  componentType,
  componentValue,
  screenName
}: ComponentShownEventData & { componentType: TutorEventing.ComponentType }) =>
  Analytics.track(
    new TutorEventing.Events.ComponentShown({
      url: window.location.pathname,
      component_type: componentType,
      component_name: componentName,
      component_value: componentValue || '',
      screen_name: screenName
    })
  )

const trackInteraction = ({
  interactionType,
  componentType,
  componentName,
  componentValue,
  interactionValue
}: {
  interactionType: TutorEventing.InteractionType
  componentType: TutorEventing.ComponentType
  interactionValue?: string
  componentName?: string
  componentValue?: string
}) =>
  Analytics.track(
    new TutorEventing.Events.InteractionTaken({
      url: window.location.pathname,
      insteraction_type: interactionType,
      component_type: componentType,
      component_name: componentName || '',
      component_value: componentValue || '',
      interaction_value: interactionValue
    })
  )

const trackOpenTask = ({
  taskType,
  taskTitle,
  taskId,
  taskStatus
}: TaskEventData & { taskType: TutorEventing.TaskType }) =>
  Analytics.track(
    new TutorEventing.Events.TaskOpened({
      task_type: taskType,
      task_title: taskTitle,
      task_id: taskId,
      task_status: taskStatus
    })
  )

const trackFinishTask = ({
  taskType,
  taskTitle,
  taskId,
  taskStatus
}: TaskEventData & { taskType: TutorEventing.TaskType }) =>
  Analytics.track(
    new TutorEventing.Events.TaskFinished({
      task_type: taskType,
      task_title: taskTitle,
      task_id: taskId,
      task_status: taskStatus
    })
  )

/* SPECIFIC EVENTS */
export const trackButtonClickEvent = ({
  componentName,
  componentValue,
  interactionValue
}: {
  componentName?: string
  componentValue?: string
  interactionValue?: string
}) =>
  trackInteraction({
    interactionType: TutorEventing.InteractionType.CLICK,
    componentType: TutorEventing.ComponentType.BUTTON,
    componentName,
    componentValue,
    interactionValue
  })

export const trackOptionSelectEvent = ({
  componentName,
  componentValue
}: {
  componentName: string
  componentValue: string
}) =>
  trackInteraction({
    interactionType: TutorEventing.InteractionType.SELECT,
    componentType: TutorEventing.ComponentType.OPTION,
    componentName,
    componentValue
  })

export const trackTaskClickEvent = ({
  componentName,
  componentValue,
  interactionValue
}: {
  componentName?: string
  componentValue?: string
  interactionValue?: string
}) =>
  trackInteraction({
    interactionType: TutorEventing.InteractionType.CLICK,
    componentType: TutorEventing.ComponentType.TASK_ONE_LINE,
    componentName,
    componentValue,
    interactionValue
  })

export const trackTaskExpandCollapseEvent = ({ componentName, componentValue, expanded }: ExpandComponentData) =>
  trackInteraction({
    interactionType: expanded ? TutorEventing.InteractionType.EXPAND : TutorEventing.InteractionType.COLLAPSE,
    componentType: TutorEventing.ComponentType.TASK_ONE_LINE,
    componentName,
    componentValue
  })

export const trackSectionExpandCollapseEvent = ({ componentName, componentValue, expanded }: ExpandComponentData) =>
  trackInteraction({
    interactionType: expanded ? TutorEventing.InteractionType.EXPAND : TutorEventing.InteractionType.COLLAPSE,
    componentType: TutorEventing.ComponentType.CARD_OR_SECTION,
    componentName,
    componentValue
  })

const trackVideoClickEvent = ({
  componentName,
  componentValue,
  interactionValue
}: {
  componentName?: string
  componentValue?: string
  interactionValue?: string
}) =>
  trackInteraction({
    interactionType: TutorEventing.InteractionType.CLICK,
    componentType: TutorEventing.ComponentType.VIDEO,
    componentName,
    componentValue,
    interactionValue
  })

export const trackTypingEvent = ({ componentName, componentValue }: ComponentData) =>
  trackInteraction({
    interactionType: TutorEventing.InteractionType.TYPING,
    componentType: TutorEventing.ComponentType.FREE_TEXT_BOX,
    componentName,
    componentValue
  })

export const trackAudioPlayEvent = (componentValue?: string) =>
  trackButtonClickEvent({
    componentName: 'audio',
    interactionValue: 'play',
    componentValue
  })

export const trackAudioPauseEvent = (componentValue?: string) =>
  trackButtonClickEvent({
    componentName: 'audio',
    interactionValue: 'pause',
    componentValue
  })

export const trackVideoPlayEvent = ({ componentName, componentValue }: ComponentData) =>
  trackVideoClickEvent({
    componentName,
    componentValue,
    interactionValue: 'play'
  })

export const trackVideoPauseEvent = ({ componentName, componentValue }: ComponentData) =>
  trackVideoClickEvent({
    componentName,
    componentValue,
    interactionValue: 'pause'
  })

export const trackCloseEvent = ({ componentName, reason }: { componentName: string; reason: CloseReason }) =>
  trackInteraction({
    interactionType:
      reason === 'escape key' ? TutorEventing.InteractionType.TYPING : TutorEventing.InteractionType.CLICK,
    componentType: TutorEventing.ComponentType.CARD_OR_SECTION,
    componentName,
    interactionValue: `closed from ${reason}`
  })

export const trackSectionShown = ({ screenName, componentName, componentValue }: ComponentShownEventData) =>
  trackComponentShown({
    componentType: TutorEventing.ComponentType.CARD_OR_SECTION,
    componentName,
    componentValue,
    screenName
  })

export const trackTaskListShown = ({ screenName, componentName, componentValue }: ComponentShownEventData) =>
  trackComponentShown({
    componentType: TutorEventing.ComponentType.TASK_LIST,
    componentName,
    componentValue,
    screenName
  })

export const trackTaskShown = ({ screenName, componentName, componentValue }: ComponentShownEventData) =>
  trackComponentShown({
    componentType: TutorEventing.ComponentType.TASK_ONE_LINE,
    componentName,
    componentValue,
    screenName
  })

export const trackVideoShownEvent = ({ screenName, componentName, componentValue }: ComponentShownEventData) =>
  trackComponentShown({
    componentType: TutorEventing.ComponentType.VIDEO,
    componentName,
    componentValue,
    screenName
  })

export const trackOpenCheckinTask = (taskId: string) =>
  trackOpenTask({ taskType: TutorEventing.TaskType.CHECK_IN_VIDEO, taskTitle: 'Checkin', taskId })

export const trackFinishCheckinTask = (taskId: string) =>
  trackFinishTask({ taskType: TutorEventing.TaskType.CHECK_IN_VIDEO, taskTitle: 'Checkin', taskId })

export const trackOpenFeedbackTask = ({ taskId, taskTitle, taskStatus }: TaskEventData) =>
  trackOpenTask({ taskType: TutorEventing.TaskType.EXERCISE_FEEDBACK, taskTitle, taskId, taskStatus })

export const trackFinishFeedbackTask = ({ taskId, taskTitle, taskStatus }: TaskEventData) =>
  trackFinishTask({ taskType: TutorEventing.TaskType.EXERCISE_FEEDBACK, taskTitle, taskId, taskStatus })

export const trackOpenPerformanceEvaluationTask = ({ taskId, taskTitle, taskStatus }: TaskEventData) =>
  trackOpenTask({ taskType: TutorEventing.TaskType.EXERCISE_PERFORMANCE_EVAL, taskTitle, taskId, taskStatus })

export const trackFinishPerformanceEvaluationTask = ({ taskId, taskTitle, taskStatus }: TaskEventData) =>
  trackFinishTask({ taskType: TutorEventing.TaskType.EXERCISE_PERFORMANCE_EVAL, taskTitle, taskId, taskStatus })
