import React from 'react'
import cx from 'classnames'

import './Typography.scss'

type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'exerciseS'
  | 'exerciseM'
  | 'exerciseL'
  | 'exerciseXL'
  | 'body'
  | 'button'
  | 'label'

export interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  variant: Variant
  color?: 'primary' | 'secondary'
  component?: React.ElementType
}

export const TypographyMap: { [variant in Variant]: { element: React.ElementType; class: string } } = {
  h1: {
    element: 'h1',
    class: 'typography__h1'
  },
  h2: {
    element: 'h2',
    class: 'typography__h2'
  },
  h3: {
    element: 'h3',
    class: 'typography__h3'
  },
  h4: {
    element: 'h4',
    class: 'typography__h4'
  },
  exerciseS: {
    element: 'p',
    class: 'typography__exercise-s'
  },
  exerciseM: {
    element: 'p',
    class: 'typography__exercise-m'
  },
  exerciseL: {
    element: 'p',
    class: 'typography__exercise-l'
  },
  exerciseXL: {
    element: 'p',
    class: 'typography__exercise-xl'
  },
  body: {
    element: 'p',
    class: 'typography__body'
  },
  button: {
    element: 'span',
    class: 'typography__button-link'
  },
  label: {
    element: 'label',
    class: 'typography__label'
  }
}

const Typography: React.FC<TypographyProps> = ({
  variant = 'body',
  children,
  component,
  className,
  color = 'primary',
  ...rest
}) => {
  const HtmlComponent = component ? component : TypographyMap[variant].element
  const classes = cx(TypographyMap[variant].class, `typography--color-${color}`, className)

  return (
    <HtmlComponent className={classes} {...rest}>
      {children}
    </HtmlComponent>
  )
}

export default Typography
