import React, { useCallback, useContext, useState } from 'react'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { NavLink, useParams, useHistory } from 'react-router-dom'
import { TextInput, Button, Typography, ValidationRules } from '@astrid/components'
import { ROUTES } from 'routes/routes'
import { AuthWrapper } from '../AuthWrapper/AuthWrapper'
import { AlertContext } from 'components/AlertContext/AlertContext'
import { useSetNewPasswordMutation } from 'store/services/Auth/authApi'

const validationSchema = Yup.object().shape({
  password: ValidationRules.password,
  password2: Yup.string()
    .oneOf([Yup.ref('password'), ''], "Passwords don't match")
    .required('Required')
})

interface FormFields {
  password: string
  password2: string
}

export const CreatePassword = () => {
  const { token } = useParams<{ token: string }>()
  const history = useHistory()
  const [setNewPassword] = useSetNewPasswordMutation()
  const { showAlert } = useContext(AlertContext)
  const [status, setStatus] = useState<'idle' | 'success' | 'error'>('idle')
  if (!token) {
    history.push(ROUTES.ROOT)
  }

  const handleSubmit = useCallback(
    async ({ password }: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
      try {
        await setNewPassword({ password, token }).unwrap()
        setStatus('success')
      } catch {
        setSubmitting(false)
        setStatus('error')
        showAlert('Could not set new password')
      }
    },
    [token, showAlert, setNewPassword]
  )

  return (
    <AuthWrapper>
      {status === 'success' ? (
        <>
          <Typography variant="h3">The new password has been set.</Typography>
          <Typography variant="body" color="secondary">
            You can now <NavLink to={ROUTES.ROOT}>log in</NavLink>
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h3">Set password for your account</Typography>
          <Formik
            initialValues={{ password: '', password2: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <TextInput
                    name="password"
                    label="Password"
                    type="password"
                    value={values.password}
                    onChange={handleChange('password')}
                    error={touched.password && !!errors.password}
                    helperText={(touched.password && errors.password) || ''}
                    onBlur={() => setFieldTouched('password')}
                  />
                  <TextInput
                    name="password2"
                    label="Confirm password"
                    type="password"
                    value={values.password2}
                    onChange={handleChange('password2')}
                    error={touched.password2 && !!errors.password2}
                    helperText={(touched.password2 && errors.password2) || ''}
                    onBlur={() => setFieldTouched('password2')}
                  />
                  <Button type="submit" disabled={isSubmitting}>
                    Set password
                  </Button>
                </form>
              )
            }}
          </Formik>
        </>
      )}
    </AuthWrapper>
  )
}
