import { makeStyles } from '@material-ui/core'
import {
  AvTimerRounded,
  FitnessCenterRounded,
  HeadsetRounded,
  RecordVoiceOverRounded,
  SpeedRounded,
  SubjectRounded
} from '@material-ui/icons'
import { DefinitionListItem } from 'components/DefinitionListItem/DefinitionListItem'
import { Colors } from 'MuiTheme'
import React from 'react'
import { InsightsModel } from 'store/services/Insights/types'

interface Props {
  insights: InsightsModel
}

const useStyles = makeStyles((theme) => ({
  activityList: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  activity: {
    fontSize: '2rem',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: Colors.cobaltBlue,

    '& svg': {
      fontSize: 'inherit'
    }
  }
}))

export const Insights = ({ insights }: Props) => {
  const classes = useStyles()

  const getMinutesStudiedColor = (minutes: number) => {
    if (minutes < 15) {
      return Colors.cerise
    } else if (minutes > 60) {
      return Colors.green
    } else {
      return Colors.pissYellow
    }
  }

  const getStoriesReadColor = (stories: number) => {
    if (stories === 0) {
      return Colors.cerise
    } else if (stories === 1) {
      return Colors.pissYellow
    } else {
      return Colors.green
    }
  }

  const getStudySetsCompletedColor = (studySets: number) => {
    if (studySets === 0) {
      return Colors.cerise
    } else {
      return Colors.green
    }
  }

  return (
    <dl className={classes.activityList}>
      <DefinitionListItem label="Reading stories" position="bottom">
        <span
          className={classes.activity}
          style={{ color: getStoriesReadColor(insights.stats.effort.readingBooksCompleted) }}>
          <SubjectRounded /> {insights.stats.effort.readingBooksCompleted}
        </span>
      </DefinitionListItem>
      <DefinitionListItem label="Level-up exercises" position="bottom">
        <span className={classes.activity}>
          <FitnessCenterRounded /> {insights.stats.effort.exercisesCompleted.userScore}
        </span>
      </DefinitionListItem>
      <DefinitionListItem label="Listening stories" position="bottom">
        <span
          className={classes.activity}
          style={{ color: getStoriesReadColor(insights.stats.effort.listeningBooksCompleted) }}>
          <HeadsetRounded /> {insights.stats.effort.listeningBooksCompleted}
        </span>
      </DefinitionListItem>
      <DefinitionListItem label="Tutor Sets" position="bottom">
        <span
          className={classes.activity}
          style={{
            color: getStudySetsCompletedColor(insights.stats.effort.lessonsCompleted.userScore)
          }}>
          <RecordVoiceOverRounded /> {insights.stats.effort.lessonsCompleted.userScore}
        </span>
      </DefinitionListItem>
      <DefinitionListItem label="Minutes studied" position="bottom">
        <span
          className={classes.activity}
          style={{ color: getMinutesStudiedColor(insights.stats.effort.timeSpent.userScore) }}>
          <AvTimerRounded /> {insights.stats.effort.timeSpent.userScore}
        </span>
      </DefinitionListItem>
      <DefinitionListItem label="Avg. words per minute" position="bottom">
        <span className={classes.activity}>
          <SpeedRounded /> {insights.stats.progress.fluency.userScore}
        </span>
      </DefinitionListItem>
    </dl>
  )
}
