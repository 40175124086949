import { Button } from '@astrid/components'
import { makeStyles, TextField } from '@material-ui/core'
import { Formik } from 'formik'
import React from 'react'
import { TutorModel } from 'store/services/Auth/types'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  biography: Yup.string().optional()
})

interface Props {
  onSubmit: (biography: string) => void
  user: TutorModel
  isLoading?: boolean
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginLeft: 'auto'
  }
}))

export const PresentationForm = ({ onSubmit, user, isLoading }: Props) => {
  const classes = useStyles()

  return (
    <Formik
      enableReinitialize
      initialValues={{ biography: user.biography || '' }}
      onSubmit={(values) => onSubmit(values.biography)}
      validationSchema={validationSchema}>
      {({ handleChange, handleSubmit, values, errors, touched, setFieldTouched, dirty }) => (
        <>
          <TextField
            name="biography"
            label="Introduce yourself to the parents"
            id="biography"
            variant="outlined"
            fullWidth
            multiline
            minRows={10}
            maxRows={19}
            size="small"
            onChange={handleChange('biography')}
            value={values.biography}
            error={touched.biography && !!errors.biography}
            helperText={(touched.biography && errors.biography) || ''}
            onBlur={() => setFieldTouched('biography')}
          />
          <Button
            className={classes.button}
            size="small"
            color="action"
            type="submit"
            onClick={() => handleSubmit()}
            disabled={isLoading || !dirty}>
            Save
          </Button>
        </>
      )}
    </Formik>
  )
}
