import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { RouteStack } from 'routes/RouteStack'
import { theme } from 'MuiTheme'
import { ThemeProvider } from '@material-ui/styles'
import { BrowserRouter as Router } from 'react-router-dom'
import { AlertProvider } from 'components/AlertContext/AlertContext'
import { store } from 'store/store'

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <AlertProvider>
          <Router basename={process.env.PUBLIC_URL}>
            <RouteStack />
          </Router>
        </AlertProvider>
      </ReduxProvider>
    </ThemeProvider>
  )
}
