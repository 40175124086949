import React, { PropsWithChildren } from 'react'
import { makeStyles } from '@material-ui/core'
import { Colors } from 'MuiTheme'
import { Typography } from '@astrid/components'
import { CheckCircleRounded } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${Colors.lightGrey}`,
    background: Colors.veryLightGrey,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,

    'input:not(:disabled) + &:hover': {
      color: Colors.cobaltBlue,
      borderColor: Colors.cobaltBlue,
      cursor: 'pointer'
    },

    'input:checked + &': {
      color: Colors.cobaltBlue,
      borderColor: Colors.cobaltBlue,

      '& $icon': {
        display: 'block'
      }
    }
  },
  icon: {
    display: 'none'
  },
  radioButton: {
    display: 'none'
  }
}))

interface Props {
  name: string
  id: string
  disabled: boolean
  checked: boolean
  onChange: () => void
}

export const RadioOption = ({ name, id, disabled, checked, onChange, children }: PropsWithChildren<Props>) => {
  const classes = useStyles()

  const inputProps = { name, id, disabled, onChange, checked }

  return (
    <>
      <input {...inputProps} type="radio" className={classes.radioButton} />
      <label htmlFor={id} className={classes.option}>
        <Typography variant="body">{children}</Typography>
        <CheckCircleRounded className={classes.icon} />
      </label>
    </>
  )
}
