import React from 'react'
import { Colors } from 'MuiTheme'

interface Props {
  percentage: number
  size: number
  strokeWidth?: number
  color: string
  backgroundColor?: string
  className?: string
}

export const CircularProgressBar = ({
  percentage,
  size,
  strokeWidth = 8,
  color,
  backgroundColor,
  className
}: Props) => {
  const progress = Math.round(Math.min(Math.max(percentage, 0), 100)) // limit percentage to range 0-100
  const radius = (size - strokeWidth) / 2
  const dashArray = radius * Math.PI * 2
  const dashOffset = dashArray - (dashArray * progress) / 100

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} color={color} className={className || ''}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        style={{
          fill: 'none',
          stroke: Colors.lightGrey,
          strokeWidth: `${strokeWidth}px`
        }}
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        style={{
          fill: 'none',
          stroke: backgroundColor || 'white',
          strokeWidth: `${strokeWidth - 4}px`
        }}
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        transform={`rotate(-90 ${size / 2} ${size / 2})`} // Start progress marker at 12 O'Clock
        style={{
          fill: 'none',
          stroke: 'currentcolor',
          strokeWidth: `${strokeWidth}px`,
          strokeDasharray: dashArray.toFixed(2),
          strokeDashoffset: dashOffset.toFixed(2),
          strokeLinecap: progress === 100 ? 'butt' : 'round'
        }}
      />
      <text
        className="circle__text"
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        style={{
          fill: 'currentcolor',
          fontWeight: 'bold',
          fontSize: `${0.32 * size}px`
        }}>
        {progress}
      </text>
    </svg>
  )
}
