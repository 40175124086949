import React, { useCallback, useContext, useState } from 'react'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'

import { Typography, TextInput, Button, AlertContext, ValidationRules } from '@astrid/components'
import { useLazySendRecoverPasswordEmailQuery } from 'store/services/Auth/authApi'
import { printApiMessage } from 'api/apiMessages'

import './RecoverPassword.scss'

interface FormValues {
  email: string
}

interface RecoverPasswordProps {
  closeModal: () => void
}

const validationSchema = Yup.object().shape({
  email: ValidationRules.email
})

const RecoverPassword = ({ closeModal }: RecoverPasswordProps) => {
  const { showAlert } = useContext(AlertContext)
  const [success, setSuccess] = useState(false)
  const [sendRecoverPasswordEmail] = useLazySendRecoverPasswordEmailQuery()
  const handleSubmit = useCallback(
    async ({ email }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      try {
        await sendRecoverPasswordEmail(email).unwrap()
        setSuccess(true)
      } catch ({ message }) {
        showAlert(printApiMessage((message as string) || undefined))
        setSubmitting(false)
      }
    },
    [sendRecoverPasswordEmail, showAlert]
  )

  return (
    <Formik initialValues={{ email: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Typography variant="h3">Recover password</Typography>
            {success ? (
              <Typography variant="exerciseS">Email sent. Please check your inbox.</Typography>
            ) : (
              <TextInput
                name="recover_email"
                label="Account Email"
                value={values.email}
                onChange={handleChange('email')}
                error={touched.email && !!errors.email}
                helperText={(touched.email && errors.email) || ''}
                onBlur={() => setFieldTouched('email')}
              />
            )}
            <div className="recover-password__buttons">
              {!success && <Button disabled={isSubmitting}>Send recover email</Button>}
              <Button disabled={isSubmitting} color="secondary" onClick={closeModal}>
                Close
              </Button>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

export default RecoverPassword
