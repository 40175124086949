import { Typography } from '@astrid/components'
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Avatar } from 'components/Avatar/Avatar'
import { Countdown } from 'components/Countdown/Countdown'
import { SlimCard } from 'components/SlimCard/SlimCard'
import { TableCellLink } from 'components/TableCellLink/TableCellLink'
import React from 'react'
import { ROUTES } from 'routes/routes'
import { EvaluationTaskListEntry, MainTaskListEntry, MainTutorTaskType } from 'store/services/StudySet/types'

const useStyles = makeStyles((theme) => ({
  table: {
    '& tr:last-child td': {
      borderBottom: 0
    }
  },
  heading: {
    fontWeight: 'bold'
  },
  learner: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  countdown: {
    width: '100%'
  }
}))

interface Props {
  tasks: (MainTaskListEntry | EvaluationTaskListEntry)[]
  onTaskClick?: (task: MainTaskListEntry | EvaluationTaskListEntry) => void
  showDeadlines?: boolean
  showTaskType?: boolean
}

export const TaskTable = ({ tasks, onTaskClick, showDeadlines, showTaskType }: Props) => {
  const classes = useStyles()
  const getUrl = (learnerId: string) => `${ROUTES.LEARNERS}/${learnerId}`

  return (
    <TableContainer component={(props) => <SlimCard {...props} noPadding />}>
      <Table aria-label="customized table" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body" className={classes.heading}>
                Learner
              </Typography>
            </TableCell>
            {showTaskType && (
              <TableCell width="50%">
                <Typography variant="body" className={classes.heading}>
                  Task type
                </Typography>
              </TableCell>
            )}
            {showDeadlines && (
              <TableCell width="190">
                <Typography variant="body" className={classes.heading}>
                  Answer within
                </Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map((task) => (
            <TableRow key={task.learner._id} hover>
              <TableCell padding="none">
                <TableCellLink to={getUrl(task.learner._id)} onClick={() => onTaskClick?.(task)}>
                  <div className={classes.learner}>
                    <Avatar url={task.learner.avatar} size="small" />
                    <Typography variant="body">
                      {task.learner.firstName} {task.learner.lastName}
                    </Typography>
                  </div>
                </TableCellLink>
              </TableCell>
              {showTaskType && (
                <TableCell padding="none">
                  <TableCellLink to={getUrl(task.learner._id)} onClick={() => onTaskClick?.(task)}>
                    <Typography variant="body">
                      {task.type === MainTutorTaskType.SpeakingChallengeFeedback ? 'Tutor Set' : 'Check-in'}
                    </Typography>
                  </TableCellLink>
                </TableCell>
              )}
              {showDeadlines && (
                <TableCell align="right" padding="none">
                  <TableCellLink to={getUrl(task.learner._id)} onClick={() => onTaskClick?.(task)}>
                    {task.deadline ? (
                      <Countdown deadline={task.deadline} className={classes.countdown} />
                    ) : (
                      <span>&nbsp;</span>
                    )}
                  </TableCellLink>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
