export type ReadSentenceExerciseData = { sentence: string }
export type WordRaceExerciseData = { words: string[] }
export type BuildingExerciseData = { wordOrSentence: string; missingIndexes: number[] }
export type ComprehensionExerciseData = { answer: number; options: string[]; question: string }

export enum ExerciseType {
  ReadWordRace = 'ReadWordRace',
  ReadSentence = 'ReadSentence',
  WordBuilder = 'WordBuilder',
  SentenceBuilder = 'SentenceBuilder',
  MultiChoiseQuestion = 'MultiChoiseQuestion',
  WordPractice = 'WordPractice',
  Conversation = 'Conversation'
}

export interface IExercise {
  _id: string
  data: ReadSentenceExerciseData | WordRaceExerciseData | BuildingExerciseData | ComprehensionExerciseData
  imageUrl?: string
  recordingUrl?: string
  points: number
  type: ExerciseType
}
