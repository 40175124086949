import {
  ScreenNames,
  trackButtonClickEvent,
  trackFinishFeedbackTask,
  trackFinishPerformanceEvaluationTask,
  trackOpenFeedbackTask,
  trackOpenPerformanceEvaluationTask,
  trackVideoPauseEvent,
  trackVideoPlayEvent
} from 'analytics/analytics'
import { isOverdue } from 'helpers/Date/Date'
import React, { useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router'
import { ROUTES } from 'routes/routes'
import { useLoadActiveTasksQuery, useUpdateStudySetReviewedMutation } from 'store/services/StudySet/studySetApi'
import { MainTutorTaskType } from 'store/services/StudySet/types'
import { StepProps } from './components/Step'
import { steps } from './steps'
import { Evaluation } from './steps/Evaluation'
import { SelfStudyResults } from './steps/SelfStudyResults/SelfStudyResults'
import { SpeakingChallenge } from './steps/SpeakingChallenge'

export const StudySet = () => {
  const { learnerId, studySetId, step } = useParams<{ learnerId: string; studySetId: string; step: string }>()
  const history = useHistory()
  const isTaskStarted = useRef(false)
  const [updateStudySetReviewed] = useUpdateStudySetReviewedMutation()
  const { studySet, isFetching, task } = useLoadActiveTasksQuery(learnerId, {
    selectFromResult: ({ data: tasks, isFetching }) => {
      const task = tasks?.find((task) => 'lessonInstance' in task && task.lessonInstance._id === studySetId)
      const studySet = task && 'lessonInstance' in task ? task.lessonInstance : undefined
      return {
        studySet,
        task,
        isFetching
      }
    }
  })

  useEffect(() => {
    const didOpenTask = task && studySet && !isTaskStarted.current
    if (didOpenTask) {
      isTaskStarted.current = true
      const taskData = {
        taskId: task.taskId,
        taskTitle: studySet.title,
        taskStatus: isOverdue(studySet.state.deadline) ? 'overdue' : 'on time'
      }

      task.taskType === MainTutorTaskType.SpeakingChallengeFeedback
        ? trackOpenFeedbackTask(taskData)
        : trackOpenPerformanceEvaluationTask(taskData)
    }
  }, [task, studySet])

  const handleVideoSubmitted = () => {
    if (task && studySet) {
      trackFinishFeedbackTask({
        taskTitle: studySet.title,
        taskId: task.taskId,
        taskStatus: isOverdue(studySet.state.deadline) ? 'overdue' : 'on time'
      })
    }
  }
  const handleEvaluationSubmitted = () => {
    if (task && studySet) {
      trackFinishPerformanceEvaluationTask({
        taskTitle: studySet.title,
        taskId: task.taskId,
        taskStatus: isOverdue(studySet.state.deadline) ? 'overdue' : 'on time'
      })
    }
  }

  const selfStudies = studySet?.sections || []
  const speakingChallengeEntries = studySet?.speakingChallenge?.result?.log || []
  const [speakingChallengeAttempt, speakingChallengeFeedback, speakingChallengeReformulation] = speakingChallengeEntries
  const currentStepIndex = parseInt(step)
  const studySetStepUrl = `${ROUTES.LEARNERS}/${learnerId}/studyset/${studySetId}`
  const getStepProps = (overrides?: Partial<StepProps>): StepProps => ({
    title: overrides?.title ?? steps[currentStepIndex].title,
    previousButtonProps: {
      title: currentStepIndex > 0 ? steps[currentStepIndex - 1].task : '',
      onClick:
        currentStepIndex > 0
          ? () => {
              trackButtonClickEvent({
                componentName: 'previous',
                componentValue: steps[currentStepIndex - 1].task
              })
              history.push(`${studySetStepUrl}/${currentStepIndex - 1}`)
            }
          : undefined,
      ...overrides?.previousButtonProps
    },
    nextButtonProps: {
      title: currentStepIndex < steps.length - 1 ? steps[currentStepIndex + 1].task : '',
      onClick:
        currentStepIndex < steps.length - 1
          ? () => {
              trackButtonClickEvent({
                componentName: 'next',
                componentValue: steps[currentStepIndex + 1].task
              })
              history.push(`${studySetStepUrl}/${currentStepIndex + 1}`)
            }
          : undefined,
      ...overrides?.nextButtonProps
    }
  })

  return studySet ? (
    <>
      {currentStepIndex === 0 && (
        <SelfStudyResults
          {...getStepProps({ nextButtonProps: { disabled: !speakingChallengeAttempt } })}
          selfStudies={selfStudies}
          studySet={studySet}
        />
      )}

      {!!studySet.speakingChallenge && (
        <>
          {/* Learner's speaking challenge - first try */}
          {currentStepIndex === 1 && speakingChallengeAttempt && (
            <SpeakingChallenge
              {...getStepProps()}
              screenName={ScreenNames.SpeakingChallenge}
              speakingChallengeEntry={speakingChallengeAttempt}
              task={studySet.speakingChallenge.task}
              notes={studySet.tutorNotes}
              studySet={studySet}
              onPlayVideo={() => {
                if (!studySet.tutorReviewedState?.speakingChallenge) {
                  updateStudySetReviewed({
                    id: studySet._id,
                    reviewedState: { ...studySet.tutorReviewedState, speakingChallenge: true }
                  })
                }
                trackVideoPlayEvent({ componentName: 'Speaking challenge' })
              }}
              onPauseVideo={() => trackVideoPauseEvent({ componentName: 'Speaking challenge' })}
            />
          )}

          {/* Feedback video view */}
          {currentStepIndex === 2 && speakingChallengeAttempt && (
            <SpeakingChallenge
              {...getStepProps({ nextButtonProps: { disabled: !speakingChallengeReformulation } })}
              screenName={ScreenNames.FeedbackVideo}
              speakingChallengeEntry={speakingChallengeFeedback}
              task={studySet.speakingChallenge.task}
              notes={studySet.tutorNotes}
              sendText="Send feedback video"
              studySet={studySet}
              onStartRecording={(attempt: number) =>
                trackButtonClickEvent({
                  componentName: 'record video',
                  componentValue: `attempt: ${attempt}`,
                  interactionValue: 'start'
                })
              }
              onVideoSubmitted={handleVideoSubmitted}
              onPlayVideo={() => trackVideoPlayEvent({ componentName: 'Feedback' })}
              onPauseVideo={() => trackVideoPauseEvent({ componentName: 'Feedback' })}
            />
          )}

          {/* Learner's speaking challenge - second try */}
          {currentStepIndex === 3 && speakingChallengeReformulation && (
            <SpeakingChallenge
              {...getStepProps()}
              screenName={ScreenNames.SpeakingChallengeReformulation}
              speakingChallengeEntry={speakingChallengeReformulation}
              task={studySet.speakingChallenge.task}
              notes={studySet.tutorNotes}
              studySet={studySet}
              onPlayVideo={() => {
                if (!studySet.tutorReviewedState?.speakingChallengeReformulation) {
                  updateStudySetReviewed({
                    id: studySet._id,
                    reviewedState: { ...studySet.tutorReviewedState, speakingChallengeReformulation: true }
                  })
                }

                trackVideoPlayEvent({ componentName: 'Speaking challenge reformulation' })
              }}
              onPauseVideo={() => trackVideoPauseEvent({ componentName: 'Speaking challenge reformulation' })}
            />
          )}
        </>
      )}

      {currentStepIndex === 4 && (
        <Evaluation
          {...getStepProps()}
          evaluation={studySet.evaluation}
          objective={studySet.objective}
          studySet={studySet}
          onComplete={handleEvaluationSubmitted}
        />
      )}
    </>
  ) : (
    <span>{isFetching ? 'Loading...' : 'Could not find Tutor Set.'}</span>
  )
}
