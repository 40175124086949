import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Colors } from 'MuiTheme'

const useStyles = makeStyles((theme) => ({
  timer: {
    color: 'white',
    textShadow: '0 0 1px black',
    fontSize: '1.5rem'
  },
  alert: {
    color: Colors.rosyPink
  }
}))

interface Props {
  className?: string
  limitInSeconds?: number
  onUpdate?: (time: number) => void
}

export const Timer = ({ className, limitInSeconds, onUpdate }: Props) => {
  const classes = useStyles()
  const [recordingTimeInSeconds, setRecordingTimeInSeconds] = useState(0)
  const timerRef = useRef<ReturnType<typeof setInterval>>()

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setRecordingTimeInSeconds((seconds) => seconds + 1)
    }, 1000)

    // cleanup
    return () => timerRef.current && clearInterval(timerRef.current)
  }, [])

  useEffect(() => {
    onUpdate && onUpdate(recordingTimeInSeconds)
  }, [recordingTimeInSeconds, onUpdate])

  const fullMinutes = Math.floor(recordingTimeInSeconds / 60).toString()
  const minutesPart = fullMinutes.length > 1 ? fullMinutes : `0${fullMinutes}`
  const secondsPart = ('0' + (recordingTimeInSeconds % 60)).slice(-2)

  const showAlert = limitInSeconds !== undefined && recordingTimeInSeconds > limitInSeconds

  return (
    <div className={`${classes.timer} ${className || ''} ${showAlert ? classes.alert : ''}`}>
      {minutesPart}:{secondsPart}
    </div>
  )
}
