import { Card, Button, Typography } from '@astrid/components'
import { Grid, makeStyles } from '@material-ui/core'
import { ScreenNames } from 'analytics/analytics'
import { AxiosRequestConfig } from 'axios'
import { AlertContext } from 'components/AlertContext/AlertContext'
import { DefinitionListItem } from 'components/DefinitionListItem/DefinitionListItem'
import { ImageUploader } from 'components/ImageUploader/ImageUploader'
import { VideoUploader } from 'components/Video/VideoUploader'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import React, { useCallback, useContext, useEffect } from 'react'
import {
  useLoadQuery,
  useRemoveAvatarMutation,
  useUpdateAvatarMutation,
  useUpdateMutation
} from 'store/services/Auth/authApi'
import { UserTokenApi } from 'store/services/Auth/userTokenApi'
import VideoApi from 'store/services/Video/videoApi'
import { PresentationForm } from './components/PresentationForm'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: '667px',
    margin: 'auto'
  },
  container: {
    marginBottom: theme.spacing(2)
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4)
  },
  heading: {
    marginTop: 0
  }
}))

export const Profile = () => {
  const classes = useStyles()
  const { showAlert } = useContext(AlertContext)
  const { data: user, isFetching: isFetchingUser } = useLoadQuery()
  const [removeAvatar, { error: avatarRemoveError }] = useRemoveAvatarMutation()
  const [updateAvatar, { error: avatarUpdateError }] = useUpdateAvatarMutation()
  const [updateProfile, { isLoading: isLoadingProfile, error: profileError }] = useUpdateMutation()

  useTrackScreenShown(ScreenNames.Profile)

  useEffect(() => {
    profileError && showAlert(`Failed to update introduction: ${profileError}`)
  }, [profileError, showAlert])

  useEffect(() => {
    avatarRemoveError && showAlert(`Failed to remove profile image: ${avatarRemoveError}`)
  }, [avatarRemoveError, showAlert])

  useEffect(() => {
    avatarUpdateError && showAlert(`Failed to upload profile image: ${avatarUpdateError}`)
  }, [avatarUpdateError, showAlert])

  const handleUploadImage = useCallback(
    async (image: File) => {
      const formData = new FormData()
      formData.append('avatar', image)
      await updateAvatar(formData).unwrap()
    },
    [updateAvatar]
  )

  const handleRemoveImage = useCallback(async () => {
    await removeAvatar().unwrap()
  }, [removeAvatar])

  const handleUploadVideo = useCallback(
    async (video: File, config?: AxiosRequestConfig) => {
      const formData = new FormData()
      formData.append('video', video)
      formData.append('hasLength', 'false')
      try {
        const { data } = await VideoApi.upload(formData, config)
        await updateProfile({ introductionVideoId: data._id }).unwrap()
      } catch (error) {
        showAlert(`Failed to upload video: ${error}`)
        throw error
      }
    },
    [showAlert, updateProfile]
  )

  const handleRemoveVideo = useCallback(async () => {
    try {
      await updateProfile({ introductionVideoId: null }).unwrap()
    } catch (error) {
      showAlert(`Failed to delete video: ${error}`)
      throw error
    }
  }, [showAlert, updateProfile])

  return user ? (
    <div className={classes.wrapper}>
      <Card>
        <Typography variant="h3" className={classes.heading}>
          Profile
        </Typography>
        <Grid container spacing={6} className={classes.container}>
          <Grid item>
            <ImageUploader file={user.avatarUrl} onUpload={handleUploadImage} onRemove={handleRemoveImage} />
          </Grid>
          <Grid item xs>
            <dl>
              <DefinitionListItem label="Name">
                {user.firstName} {user.lastName}
              </DefinitionListItem>
              <DefinitionListItem label="Email">{user.email}</DefinitionListItem>
            </dl>
          </Grid>
        </Grid>

        <Typography variant="h3" className={classes.heading}>
          Introduction
        </Typography>
        <Grid container spacing={6} className={classes.container}>
          <Grid item>
            <VideoUploader
              text="Record introduction video to the learners"
              file={user.introductionVideoUrl}
              onUpload={handleUploadVideo}
              onRemove={handleRemoveVideo}
            />
          </Grid>
          <Grid item xs>
            <PresentationForm
              user={user}
              isLoading={isLoadingProfile || isFetchingUser}
              onSubmit={(biography: string) => updateProfile({ biography })}
            />
          </Grid>
        </Grid>

        <div className={classes.footer}>
          <Button size="small" color="secondary" onClick={UserTokenApi.removeUserToken} label="Logout" />
        </div>
      </Card>
    </div>
  ) : (
    <Typography variant="body" color="secondary">
      User not found.
    </Typography>
  )
}
