import React, { Component } from 'react'
import MediaRecorderPolyfill from 'audio-recorder-polyfill'

import { PermissionsPopup } from '../..'
import { ReactComponent as MicrophoneIcon } from '../../graphics/icons/microphone.svg'

import './AudioRecorder.scss'

interface IProps {
  autoRecorder?: boolean
  wordsToRecord?: string[]
  onStartRecording?: () => void
  onStopRecording?: (audio: Blob) => void
  onStopWordRecording?: (audio: Blob, word: string) => void
}

interface IState {
  isRecording: boolean
  recordingIndex: number
  permissionsPopupOpen: boolean
}

const createMediaRecorder = (stream: MediaStream): MediaRecorder => {
  return new MediaRecorderPolyfill(stream)

  /** TODO: This could be changed in the future to use built in MediaRecorder when available. */
  // return new MediaRecorder(stream)
}

class AudioRecorder extends Component<IProps, IState> {
  readonly state: IState = {
    isRecording: false,
    recordingIndex: 0,
    permissionsPopupOpen: false
  }
  private mediaRecorder: MediaRecorder | null = null
  private recordBlob: Blob | null = null
  private stream: MediaStream | null = null
  private buttonRef = React.createRef<HTMLButtonElement>()
  private permissionPopupDelayer: any = null

  async componentDidMount() {
    await this.initMediaRecorder()
    this.buttonRef.current?.addEventListener(
      'touchstart',
      (event) => {
        event.preventDefault()
        if (!this.state.isRecording) this.toggleRecording(true)
      },
      {
        passive: false,
        capture: true
      }
    )
  }

  componentWillUnmount() {
    this.stream?.getTracks().forEach((track) => track.stop())
  }

  private initMediaRecorder = async () => {
    const constraints: MediaStreamConstraints = { audio: true, video: false }

    try {
      this.permissionPopupDelayer = setTimeout(() => this.setState({ permissionsPopupOpen: true }), 600)
      this.stream = await navigator.mediaDevices.getUserMedia(constraints)
      clearTimeout(this.permissionPopupDelayer)
      this.permissionPopupDelayer = null
      this.setState({ permissionsPopupOpen: false })
    } catch (error) {
      this.setState({ permissionsPopupOpen: false })
      console.log(error)
      return
    }

    this.mediaRecorder = createMediaRecorder(this.stream)

    this.mediaRecorder.addEventListener('start', () => {
      if (this.props.onStartRecording) {
        this.props.onStartRecording()
      }
    })

    this.mediaRecorder.addEventListener('stop', () => {
      const { onStopRecording, onStopWordRecording, wordsToRecord } = this.props
      if (this.recordBlob) {
        if (onStopRecording) onStopRecording(this.recordBlob)
        if (onStopWordRecording && wordsToRecord?.length) {
          onStopWordRecording(this.recordBlob, wordsToRecord[this.state.recordingIndex])
          this.setState(this.incrementRecordingIndex, this.recordOneWord)
        }
      }
    })

    this.mediaRecorder.addEventListener('dataavailable', (event) => {
      this.recordBlob = (event as BlobEvent).data
    })

    if (this.props.autoRecorder && this.props.wordsToRecord?.length) {
      this.recordOneWord()
    }
  }

  private incrementRecordingIndex = (state: IState): IState => ({ ...state, recordingIndex: state.recordingIndex + 1 })

  private recordOneWord = () => {
    if (this.state.recordingIndex < this.props.wordsToRecord!.length) {
      this.start()
      setTimeout(() => {
        this.stop()
      }, 2000)
    }
  }

  private start = () => {
    if (this.mediaRecorder) {
      const recordingState: RecordingState = this.mediaRecorder.state
      if (recordingState !== 'recording') {
        this.mediaRecorder.start()
        document.addEventListener('mouseup', this.turnOffRecording)
      }
    }
  }

  private stop = () => {
    if (this.mediaRecorder) {
      const recordingState: RecordingState = this.mediaRecorder.state
      if (recordingState !== 'inactive') {
        this.mediaRecorder.stop()
        document.removeEventListener('mouseup', this.turnOffRecording)
      }
    }
  }

  private toggleRecording = (isRecording?: boolean) => {
    this.setState(
      (state) => ({
        isRecording: isRecording !== undefined ? isRecording : !state.isRecording
      }),
      () => {
        const { isRecording } = this.state
        if (isRecording) {
          this.start()
        } else {
          this.stop()
        }
      }
    )
  }

  private turnOffRecording = () => this.toggleRecording(false)

  render() {
    if (this.props.autoRecorder) {
      return (
        <PermissionsPopup
          open={this.state.permissionsPopupOpen}
          onClose={() => this.setState({ permissionsPopupOpen: false })}
        />
      )
    }

    const { isRecording } = this.state
    const className = `audio-recorder${isRecording ? '--recording' : ''}`
    return (
      <>
        <div className={className}>
          <button
            ref={this.buttonRef}
            onTouchEnd={this.turnOffRecording}
            onTouchCancel={this.turnOffRecording}
            onMouseDown={() => this.toggleRecording(true)}>
            <MicrophoneIcon />
          </button>
        </div>
        <PermissionsPopup
          open={this.state.permissionsPopupOpen}
          onClose={() => this.setState({ permissionsPopupOpen: false })}
        />
      </>
    )
  }
}

export default AudioRecorder
