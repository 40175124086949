import { makeStyles } from '@material-ui/core/styles'
import React, { PropsWithChildren } from 'react'

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    padding: '.9rem',

    '& > *': {
      display: 'flex',
      flexDirection: 'column'
    },

    '&:hover': {
      color: theme.palette.grey[500]
    }
  }
}))

export const UploadButton = ({
  children,
  className,
  ...rest
}: PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>) => {
  const classes = useStyles()

  return (
    <button className={`${classes.button} ${className}`} {...rest}>
      {children}
    </button>
  )
}
