import { Colors } from 'MuiTheme'
import { WordScore } from 'store/services/StudySet/types'

const SCORE_THRESHOLDS = {
  LOW: 40,
  HIGH: 90
}

interface CharactersScore {
  characters: string
  score?: number
}

type SentencePart = { type: 'characters'; characters: string } | { type: 'scored-word'; word: string; score: number }

export const getScoreColor = (score: number) => {
  if (score < SCORE_THRESHOLDS.LOW) {
    return Colors.rosyPink
  } else if (score < SCORE_THRESHOLDS.HIGH) {
    return Colors.pissYellow
  } else {
    return Colors.green
  }
}

/**
 * Returns all sentence parts, including non-scored characters
 */
export const getSentenceParts = (sentence: string, wordScores: WordScore[]): SentencePart[] => {
  const sentenceParts: SentencePart[] = []
  let currentIndex = 0

  for (const wordScore of wordScores) {
    const { indices, score } = wordScore

    const charactersBeforeWord = sentence.slice(currentIndex, wordScore.indices.start)
    if (charactersBeforeWord) {
      sentenceParts.push({ type: 'characters', characters: charactersBeforeWord })
    }

    currentIndex = wordScore.indices.end + 1

    const scoredWord = sentence.slice(indices.start, currentIndex)
    sentenceParts.push({ type: 'scored-word', word: scoredWord, score })
  }

  const charactersAfterWords = sentence.slice(currentIndex, sentence.length)
  if (charactersAfterWords) {
    sentenceParts.push({ type: 'characters', characters: charactersAfterWords })
  }

  return sentenceParts
}
