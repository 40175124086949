import React from 'react'
import { createPortal } from 'react-dom'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import cx from 'classnames'

import { ExerciseType, IExercise, BuildingExerciseData, CharacterMood } from '../../../types'
import { withSoundEffectsContext, Typography, LottieCharacter } from '../../../index'
import { InjectedSoundProps } from '../../../types'
import { ExerciseBaseProps } from '../types'

import './BuildingExercise.scss'

export function shuffleArray<T>(array: T[]): T[] {
  const arrayClone = [...array]
  for (let i = arrayClone.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = arrayClone[i]
    arrayClone[i] = arrayClone[j]
    arrayClone[j] = temp
  }
  return arrayClone
}

export const isTouchDevice = () => 'ontouchstart' in window || navigator.msMaxTouchPoints > 0

interface IState {
  missing: number[]
  wrongIndexes: number[]
  totalAnswers: number
  missingIndexesCount: number
  splittedWordOrSentence: string[] | null
  characterMood: CharacterMood
}

interface IProps extends ExerciseBaseProps, InjectedSoundProps {}

class BuildingExercise extends React.Component<IProps, IState> {
  state: IState = {
    missing: [],
    wrongIndexes: [],
    totalAnswers: 0,
    missingIndexesCount: 0,
    splittedWordOrSentence: [],
    characterMood: 'idle'
  }

  componentDidMount() {
    this.loadExerciseData()
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.exercise._id !== this.props.exercise._id) {
      this.loadExerciseData()
    }

    if (this.state.missing.length === 0 && prevState.missing.length !== 0) {
      this.changeCharacterMood('excited')
      this.props.playSound('exercise-finished', () => this.changeCharacterMood('idle'))
      const { missingIndexesCount, totalAnswers } = this.state
      const percentageScore = (missingIndexesCount / totalAnswers) * 100
      setTimeout(() => this.props.onCurrentExerciseCompleted(~~percentageScore), 4000)
    }
  }

  loadExerciseData = () => {
    const { missingIndexes } = this.props.exercise.data as BuildingExerciseData
    this.setState({
      missing: shuffleArray(missingIndexes),
      missingIndexesCount: missingIndexes.length,
      totalAnswers: 0,
      wrongIndexes: [],
      splittedWordOrSentence: this.splitWordOrSentence(this.props.exercise),
      characterMood: 'idle'
    })
  }

  onDragEnd = (result: DropResult) => {
    const { destination, draggableId } = result
    if (!destination || destination.droppableId === 'candidate-pool') return

    const { playSound } = this.props
    if (destination.droppableId === draggableId) {
      playSound('correct')
      this.setState((state) => ({
        missing: state.missing.filter((missingIndex) => missingIndex !== parseInt(draggableId)),
        wrongIndexes: state.wrongIndexes.filter((wrongIndex) => wrongIndex !== parseInt(draggableId)),
        totalAnswers: state.totalAnswers + 1,
        characterMood: 'correct'
      }))
    } else {
      playSound('incorrect')
      if (this.state.wrongIndexes.includes(parseInt(draggableId))) return
      this.setState((state) => ({
        wrongIndexes: [...state.wrongIndexes, parseInt(draggableId)],
        totalAnswers: state.totalAnswers + 1,
        characterMood: 'wrong'
      }))
    }
  }

  changeCharacterMood = (mood: CharacterMood) => {
    this.setState({
      characterMood: mood
    })
  }

  isMissing = (index: number) => this.state.missing.includes(index)
  wasMissing = (index: number) =>
    (this.props.exercise.data as BuildingExerciseData).missingIndexes.includes(index) && !this.isMissing(index)

  splitWordOrSentence = (exercise: IExercise) => {
    const { wordOrSentence } = exercise.data as BuildingExerciseData
    switch (exercise.type) {
      case ExerciseType.SentenceBuilder:
        return wordOrSentence.split(' ')
      case ExerciseType.WordBuilder:
        return wordOrSentence.split('')
      default:
        return null
    }
  }

  render() {
    const { exercise } = this.props
    const { missing, wrongIndexes, splittedWordOrSentence } = this.state
    return (
      <div className={`building-exercise--${exercise.type === ExerciseType.SentenceBuilder ? 'sentence' : 'word'}`}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <div className="building-exercise__construction">
            {splittedWordOrSentence?.map((wordOrSentence, index) =>
              this.isMissing(index) ? (
                <DropZone key={`dropzone${index}`} exerciseType={exercise.type} missingIndex={index} />
              ) : (
                <Typography
                  key={`word${index}`}
                  variant={window.innerWidth <= 600 ? 'body' : 'exerciseS'}
                  className={`building-exercise__${this.wasMissing(index) ? 'correctly-placed' : 'word-spacing'}`}>
                  {wordOrSentence}
                </Typography>
              )
            )}
          </div>
          <LottieCharacter
            mood={this.state.characterMood}
            changeCharacterMood={this.changeCharacterMood}
            characterName={exercise.type === ExerciseType.WordBuilder ? 'Abbe' : 'Alfred'}
            className="building-exercise__character"
          />
          <Droppable droppableId="candidate-pool" direction="horizontal">
            {(provided) => (
              <div className="building-exercise__candidate-pool" ref={provided.innerRef} {...provided.droppableProps}>
                {splittedWordOrSentence &&
                  missing.map((missingIndex, index) => (
                    <Candidate
                      key={`candidate${missingIndex}`}
                      missingIndex={missingIndex}
                      index={index}
                      incorrect={wrongIndexes.includes(missingIndex)}>
                      <Typography variant={window.innerWidth <= 600 ? 'body' : 'exerciseS'}>
                        {splittedWordOrSentence[missingIndex]}
                      </Typography>
                    </Candidate>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  }
}

interface ICandidateProps {
  index: number
  missingIndex: number
  incorrect: boolean
}

const Candidate: React.FC<ICandidateProps> = ({ index, incorrect, missingIndex, children }) => {
  const portal = document.getElementById('draggable')
  if (!portal) {
    console.error('No element with ID "draggable" found! Please add it to the DOM.')
    return null
  }

  const optionalPortal = (isDragging: boolean, element: JSX.Element) => {
    if (isDragging) {
      return createPortal(element, portal)
    }
    return element
  }

  return (
    <Draggable draggableId={`${missingIndex}`} index={index}>
      {(provided, snapshot) => {
        const { isDragging } = snapshot
        const classNames = cx('building-exercise__candidate', {
          'building-exercise__candidate--dragging': isDragging,
          'building-exercise__candidate--incorrect': incorrect && !isDragging
        })

        return optionalPortal(
          isDragging && !isTouchDevice(),
          <div
            className={classNames}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}>
            {children}
          </div>
        )
      }}
    </Draggable>
  )
}

interface IDropZoneProps {
  missingIndex: number
  exerciseType: ExerciseType
}

const DropZone: React.FC<IDropZoneProps> = ({ missingIndex, exerciseType, children }) => {
  const classNames = cx({
    'building-exercise__dropzone--word': exerciseType === ExerciseType.SentenceBuilder,
    'building-exercise__dropzone--char': exerciseType === ExerciseType.WordBuilder
  })
  return (
    <Droppable droppableId={`${missingIndex}`}>
      {(provided) => (
        <div className={classNames} ref={provided.innerRef} {...provided.droppableProps}>
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default withSoundEffectsContext(BuildingExercise)
