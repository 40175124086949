import { Button, Modal, TextInput, Typography, ValidationRules } from '@astrid/components'
import { Analytics } from 'analytics/analyticsApi'
import { printApiMessage } from 'api/apiMessages'
import { AlertContext } from 'components/AlertContext/AlertContext'
import { Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { AuthWrapper } from 'routes/Auth/AuthWrapper/AuthWrapper'
import { useLoginMutation } from 'store/services/Auth/authApi'
import * as Yup from 'yup'
import RecoverPassword from '../RecoverPassword/RecoverPassword'
import styles from './Login.module.scss'

const validationSchema = Yup.object().shape({
  email: ValidationRules.email,
  password: ValidationRules.required
})

export const Login = () => {
  const [login, { data: authData, isLoading }] = useLoginMutation()
  const { showAlert } = useContext(AlertContext)
  const [recoverPasswordModalOpen, setRecoverPasswordModalOpen] = useState(false)

  useEffect(() => {
    authData && Analytics.identify(authData.user._id)
  }, [authData])

  const handleLogin = async ({ email, password }: { email: string; password: string }) => {
    try {
      await login({ email, password }).unwrap()
    } catch (error) {
      showAlert(printApiMessage((error as string) || undefined))
    }
  }

  return (
    <AuthWrapper>
      <Typography variant="h1" style={{ margin: 0 }}>
        Log in
      </Typography>
      <Typography variant="body">with your email and password</Typography>

      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={({ email, password }) => handleLogin({ email, password })}>
        {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TextInput
                name="email"
                label="Your Email"
                value={values.email}
                onChange={handleChange('email')}
                error={touched.email && !!errors.email}
                helperText={(touched.email && errors.email) || ''}
                onBlur={() => setFieldTouched('email')}
              />
              <TextInput
                name="password"
                label="Enter Password"
                type="password"
                value={values.password}
                onChange={handleChange('password')}
                error={touched.password && !!errors.password}
                helperText={(touched.password && errors.password) || ''}
                onBlur={() => setFieldTouched('password')}
              />
              <Button type="submit" variant="flat" color="action" disabled={isLoading}>
                Log in
              </Button>
            </form>
          )
        }}
      </Formik>
      <Typography
        variant="body"
        color="secondary"
        className={styles.forgot}
        onClick={() => setRecoverPasswordModalOpen(true)}>
        Forgot password?
      </Typography>
      <Modal
        variant="student"
        hideCloseButton
        open={recoverPasswordModalOpen}
        closeModal={() => setRecoverPasswordModalOpen(false)}>
        <RecoverPassword closeModal={() => setRecoverPasswordModalOpen(false)} />
      </Modal>
    </AuthWrapper>
  )
}
