import React, { useEffect, useRef } from 'react'

import { Button, CloseIcon } from '../../'
import './Modal.scss'

interface InvitationModalProps {
  open: boolean
  closeModal?: () => void
  variant: 'teacher' | 'student'
  hideCloseButton?: boolean
}

const InvitationModal: React.FC<InvitationModalProps> = ({
  open,
  closeModal,
  variant = 'student',
  hideCloseButton,
  children
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    // Outside click handler
    const handleClickOutside = (event: any) => {
      if (!contentRef.current?.contains(event.target)) {
        closeModal && closeModal()
      }
    }

    const handleEscapeClick = (event: any) => {
      if (event.keyCode === 27) {
        event
        closeModal && closeModal()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleEscapeClick)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscapeClick)
    }
  }, [contentRef])

  if (!open) return null
  if (!hideCloseButton && !closeModal)
    console.error('Modal: No closing function provided. The default close button will not work.')
  return (
    <div className="astrid-modal">
      <div className="astrid-modal__content" ref={contentRef}>
        <CloseIcon className="astrid-modal__close-icon" onClick={closeModal} />
        {children}
        {!hideCloseButton && (
          <Button
            onClick={closeModal}
            variant={variant === 'teacher' ? 'flat' : 'filled'}
            color={variant === 'teacher' ? 'action' : 'primary'}>
            Close
          </Button>
        )}
      </div>
    </div>
  )
}

export default InvitationModal
