import React from 'react'
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Typography } from '@astrid/components'
import { PhonemeSeqWithWords } from 'store/services/Insights/types'
import { Colors } from 'MuiTheme'
import { CircularProgressBar } from 'components/CircularProgressBar/CircularProgressBar'
import { getScoreColor } from 'helpers/Score'

const useStyles = makeStyles((theme) => ({
  table: {
    '& th': {
      border: 'none',
      fontSize: '1.3rem',
      fontWeight: 'bold',
      fontFamily: theme.typography.h6.fontFamily,
      paddingLeft: 0,
      verticalAlign: 'bottom'
    },

    '& td': {
      border: 'none',
      padding: 0,
      verticalAlign: 'baseline'
    },

    '&$extended td': {
      verticalAlign: 'middle'
    },

    '& $border': {
      borderLeft: `1px solid ${Colors.lightGrey}`,
      paddingLeft: theme.spacing(2)
    },
    '& $full': {
      width: '100%',
      paddingLeft: theme.spacing(2)
    }
  },
  border: {},
  full: {},
  extended: {},
  exampleWords: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'baseline',
    listStyle: 'none',
    gap: theme.spacing(1),
    padding: 0
  },
  tag: {
    background: Colors.blueGrey,
    borderRadius: theme.shape.borderRadius,
    padding: '4px 8px'
  },
  phoneme: {
    color: Colors.rosyPink,
    display: 'inline-block'
  },
  score: {
    width: 100
  }
}))

interface Props {
  phonemeSeqsWithWords: PhonemeSeqWithWords[]
  extended?: boolean
}

export const ChallengingWords = ({ phonemeSeqsWithWords, extended }: Props) => {
  const classes = useStyles()
  return (
    <Table size="small" className={`${classes.table} ${extended ? classes.extended : ''}`}>
      <TableHead>
        <TableRow>
          <TableCell>Sound</TableCell>
          {extended && <TableCell>Attempts</TableCell>}
          {extended && (
            <TableCell>
              <div className={classes.score}>Avg score of sound</div>
            </TableCell>
          )}
          <TableCell className={extended ? '' : classes.full}>Example words</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {phonemeSeqsWithWords.map((phonemeWithWords) => (
          <TableRow key={phonemeWithWords.soundsIpa}>
            <TableCell>
              <Typography variant="body" className={`${classes.tag} ${classes.phoneme}`}>
                {phonemeWithWords.soundsIpa}
              </Typography>
            </TableCell>
            {extended && (
              <TableCell>
                <Typography variant="body">{phonemeWithWords.soundAttemptCount}</Typography>
              </TableCell>
            )}
            {extended && (
              <TableCell>
                <CircularProgressBar
                  percentage={phonemeWithWords.avgSoundScore}
                  size={40}
                  color={getScoreColor(phonemeWithWords.avgSoundScore)}
                />
              </TableCell>
            )}
            <TableCell className={extended ? '' : classes.border}>
              <ul className={classes.exampleWords}>
                {phonemeWithWords.exampleWords.map((word) => (
                  <li className={classes.tag} key={word}>
                    <Typography variant="body">{word}</Typography>
                  </li>
                ))}
              </ul>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
