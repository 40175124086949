import React, { PropsWithChildren } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { ExpandMoreRounded } from '@material-ui/icons'
import { Colors } from 'MuiTheme'

const CustomAccordion = withStyles({
  root: {
    width: '100%',
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    minHeight: 0,
    '&:hover': {
      background: Colors.veryLightGrey
    },
    '&$expanded': {
      minHeight: 0,

      '&:hover': {
        background: 'none'
      }
    }
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0
    }
  },
  expanded: {},
  expandIcon: {
    paddingTop: 0,
    paddingBottom: 0
  }
}))(MuiAccordionSummary)

const AccordionDetails = withStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0
  }
})(MuiAccordionDetails)

interface Props {
  summary: React.ReactNode
  onExpand?: (expanded: boolean) => void
  defaultExpanded?: boolean
}

export const Accordion = ({ summary, onExpand, defaultExpanded, children }: PropsWithChildren<Props>) => {
  return (
    <CustomAccordion square defaultExpanded={defaultExpanded} onChange={(_, expanded) => onExpand?.(expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreRounded />}>
        {typeof summary === 'string' ? <Typography>{summary}</Typography> : summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </CustomAccordion>
  )
}
