import { ActiveLearnerModel, LearnerModel } from './types'
import { baseApi } from 'store/store'

export const learnerApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    loadLearner: builder.query<LearnerModel, string>({
      query: (userId) => ({ url: `/user/${userId}/minimal`, method: 'get' }),
      transformResponse: (response: { profile: LearnerModel }) => response.profile
    }),

    loadActiveLearners: builder.query<ActiveLearnerModel[], void>({
      query: () => ({ url: `/user/active-for-tutor`, method: 'get' }),
      transformResponse: (response: { users: ActiveLearnerModel[] }) => response.users
    })
  })
})

export const { useLoadLearnerQuery, useLoadActiveLearnersQuery } = learnerApi
