import { IExercise, ExerciseType } from '../../types/Exercise'

import AbbeWithBook from '../../graphics/abbe_with_book.png'
import AlfredWithPencil from '../../graphics/alfred_with_pencil.png'

export const onboardingExercises: IExercise[] = [
  {
    _id: '5ee098d48be050001fc9892d',
    type: ExerciseType.MultiChoiseQuestion,
    points: 10,
    data: {
      question: 'When you read to Astrid you should...',
      options: ['Clap your hands', 'Press and hold the yellow button', 'Type your answer'],
      answer: 1
    }
  },
  {
    _id: '5ed8ef957dadae00203946f2',
    type: ExerciseType.ReadSentence,
    points: 20,
    data: {
      sentence: 'Astrid is a magical schoolbook.'
    },
    imageUrl: AbbeWithBook
  },
  {
    _id: '5ed8efe87dadae00203946f3',
    type: ExerciseType.SentenceBuilder,
    points: 20,
    data: {
      wordOrSentence: 'Astrid listens as you speak.',
      missingIndexes: [1, 3]
    },
    imageUrl: AlfredWithPencil
  },
  {
    _id: '5edf878b8be050001fc98845',
    type: ExerciseType.ReadWordRace,
    points: 10,
    data: {
      words: ['Read', 'along', 'with', 'Astrid']
    }
  }
]
