import { baseApi } from 'store/store'
import { AuthCredentials, TutorModel, TutorUpdateFormModel } from './types'
import { UserTokenApi } from './userTokenApi'

interface AuthorizationResponse {
  user: TutorModel
  token: string
}

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthorizationResponse, AuthCredentials>({
      query: (data: AuthCredentials) => ({ url: '/auth/tutor', method: 'post', data }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled
          UserTokenApi.setUserToken(data.token)
        } catch {}
      },
      invalidatesTags: (result) => (result ? ['Tutor'] : [])
    }),

    sendRecoverPasswordEmail: builder.query<void, string>({
      query: (email: string) => ({ url: `/auth/tutor/reset-pass/${email}`, method: 'get' })
    }),

    setNewPassword: builder.mutation<void, { password: string; token: string }>({
      query: ({ password, token }: { password: string; token: string }) => ({
        url: '/auth/reset-pass',
        method: 'post',
        data: { password },
        headers: { authorization: token }
      })
    }),

    load: builder.query<TutorModel, void>({ query: () => ({ url: '/tutor', method: 'get' }), providesTags: ['Tutor'] }),

    updateAvatar: builder.mutation<TutorModel, FormData>({
      query: (data: FormData) => ({ url: '/tutor/avatar', method: 'put', data }),
      invalidatesTags: (result) => (result ? ['Tutor'] : [])
    }),

    removeAvatar: builder.mutation<TutorModel, void>({
      query: () => ({ url: '/tutor/avatar', method: 'delete' }),
      invalidatesTags: (result) => (result ? ['Tutor'] : [])
    }),

    update: builder.mutation<TutorModel, TutorUpdateFormModel>({
      query: (data: TutorUpdateFormModel) => ({ url: '/tutor', method: 'patch', data }),
      invalidatesTags: (result) => (result ? ['Tutor'] : [])
    })
  })
})

export const {
  useLoadQuery,
  useLoginMutation,
  useUpdateAvatarMutation,
  useRemoveAvatarMutation,
  useUpdateMutation,
  useSetNewPasswordMutation,
  useLazySendRecoverPasswordEmailQuery
} = authApi
