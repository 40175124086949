import { makeStyles } from '@material-ui/core'
import { Colors } from 'MuiTheme'
import React, { PropsWithChildren } from 'react'

const useStyles = makeStyles((theme) => ({
  option: {
    borderRadius: 10,
    padding: theme.spacing(1),
    border: '2px solid transparent',
    minWidth: '2.5rem',
    textAlign: 'center'
  },
  correct: {
    borderColor: Colors.cobaltBlue
  },
  default: {
    background: '#fff',
    borderColor: Colors.lightGrey
  },
  dashed: {
    borderStyle: 'dashed'
  }
}))

interface Props {
  correct?: boolean
  dashed?: boolean
}

export const ExerciseOption = ({ correct, dashed, children }: PropsWithChildren<Props>) => {
  const classes = useStyles()

  return (
    <div className={`${classes.option} ${correct ? classes.correct : classes.default} ${dashed ? classes.dashed : ''}`}>
      {children}
    </div>
  )
}
