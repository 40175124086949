import { BookType, CEFRLevels, ExerciseType, StudySetComponentType } from '@astrid/components'
import { TutorModel } from '../Auth/types'
import { LearnerModelBase } from '../Learner/types'

export enum SectionTag {
  GetReady = 'get-ready',
  Learn = 'learn',
  Practice = 'practice',
  SpeakingChallenge = 'speaking-challenge'
}

export type Role = 'learner' | 'tutor'
export interface StudySetStateModel {
  nextTurn: Role
  deadline?: number
}

export class ReviewedState {
  selfStudyResults?: boolean
  speakingChallenge?: boolean
  speakingChallengeReformulation?: boolean
}

export interface StudySetEvaluation {
  learnerMetGoal: boolean
  commentFromTutor: string
}

export interface StudySetSection {
  sectionName: SectionTag
  components: StudySetComponent[]
}

export type TaskDetails = {
  taskId: string
} & (
  | {
      taskType: MainTutorTaskType.SpeakingChallengeFeedback | EvaluationTutorTaskType.SpeakingChallengeEvaluation
      lessonInstance: StudySetModel
    }
  | {
      taskType: MainTutorTaskType.RecordCheckInVideo
    }
)

export interface StudySetModel {
  _id: string
  title: string
  state: StudySetStateModel
  objective: string
  cefrLevel: CEFRLevels
  sections: StudySetSection[]
  tutorNotes: string
  tutorDone: boolean
  tutorReviewedState?: ReviewedState
  userDone: boolean
  evaluation?: StudySetEvaluation
  speakingChallenge?: SpeakingChallengeComponent
}

export interface ArchivedStudySetModel {
  _id: string
  title: string
  cefrLevel: CEFRLevels
  objective: string
  evaluation: StudySetEvaluation
  tutor: Pick<TutorModel, 'firstName' | 'lastName'>
}

// Exercise
export interface ExerciseModel {
  type: ExerciseType
  imageUrl?: string
  data:
    | ExerciseReadSentenceData
    | ExerciseBuilderData
    | MultiChoiceQuestionExerciseData
    | ExerciseWordPracticeData
    | ExerciseConversationData
  points?: number
  bookId?: string
  recordingUsUrl?: string
  imageTitle?: string
  imageAttribute?: string
}

export interface ExerciseReadSentenceData {
  sentence?: string
  sentenceUs?: string
}

export interface ExerciseBuilderData {
  wordOrSentence?: string
  missingIndexes?: number[]
  wordOrSentenceUs: string
  missingIndexesUs: number[]
  distractors?: string[]
  distractorsUs?: string[]
}

export interface ExerciseWordPracticeData {
  words: string[]
}

export interface ExerciseConversationData {
  conversationElements: { instructorScript: string; learnerScript?: string }[]
}

export class MultiChoiceQuestionExerciseData {
  answer!: number
  options!: string[]
  imageOption1?: string
  imageOption2?: string
  imageOption3?: string
  imageOption4?: string
  audioOption1?: string
  audioOption2?: string
  audioOption3?: string
  audioOption4?: string
  audioOptionUs1?: string
  audioOptionUs2?: string
  audioOptionUs3?: string
  audioOptionUs4?: string
  question?: string
  questionType!: ComprehensionType
  answerType!: ComprehensionType
  audioQuestion?: string
  audioQuestionUs?: string
  imageQuestion?: string
  answerUs?: number
  optionsUs?: string[]
  questionUs?: string
}

export enum ComprehensionType {
  TEXT = 'Text',
  AUDIO = 'Audio',
  IMAGE = 'Image'
}

export enum ExerciseResultType {
  ReadingScore = 'reading-score',
  ReadingScores = 'reading-scores',
  NonReadingScore = 'non-reading-score'
}

export interface SanaScore {
  overall_score: number
  target_phrase: string
  word_scores: WordScore[]
}

export interface WordScore {
  word: string
  score: number
  indices: {
    start: number
    end: number
  }
  _id: string
}

export interface ExerciseReadResult {
  attemptCount: number
  exerciseResultType: ExerciseResultType.ReadingScore
  recordingUrl: string
  sanaScore: SanaScore
}

export interface ExerciseMultipleReadResult {
  exerciseResultType: ExerciseResultType.ReadingScores
  recordings: {
    attempts?: number
    recordingUrl: string
    sanaScore: SanaScore
  }[]
}

export interface ExerciseNonReadResult {
  exerciseResultType: ExerciseResultType.NonReadingScore
  exerciseScore: {
    exerciseId: string
    score: number
    type: ExerciseType
  }
}

export type ExerciseResult = { exercise: ExerciseModel } & (
  | ExerciseReadResult
  | ExerciseMultipleReadResult
  | ExerciseNonReadResult
)

export interface BookExerciseResult {
  exerciseId: string
  exerciseResult: ExerciseResult
}

// Component
export interface BookComponent {
  type: StudySetComponentType.Book
  task: {
    bookId: string
    bookTitle: string
    bookType: BookType
  }
  result?: {
    exerciseResults: BookExerciseResult[]
  }
}

export interface ExerciseComponent {
  type: StudySetComponentType.Exercise
  task: {
    type: ExerciseType
    exerciseId: string
    sentenceType?: BookType
  }
  result?: {
    exerciseResult: ExerciseResult
  }
}

export interface SpeakingChallengeLogEntry {
  role: Role
  videoUrl: string
}

export interface SpeakingChallengeComponent {
  type: StudySetComponentType.SpeakingChallenge
  task: {
    speakingChallenge: { textQuestion: string }
    tutorInstruction: string
  }
  result?: {
    log: SpeakingChallengeLogEntry[]
  }
}

export interface VideoComponent {
  type: StudySetComponentType.Video
  task: {
    videoId: string
  }
  result?: {
    exerciseResult: ExerciseResult
  }
}

export type StudySetComponent = { done: boolean } & (
  | BookComponent
  | ExerciseComponent
  | SpeakingChallengeComponent
  | VideoComponent
)

export enum MainTutorTaskType {
  SpeakingChallengeFeedback = 'speaking-challenge-feedback',
  RecordCheckInVideo = 'record-check-in-video'
}

export enum EvaluationTutorTaskType {
  SpeakingChallengeEvaluation = 'speaking-challenge-evaluation'
}

export type MainTaskListEntry = {
  type: MainTutorTaskType
  learner: LearnerModelBase
  deadline?: number
}

export type EvaluationTaskListEntry = {
  type: EvaluationTutorTaskType
  learner: LearnerModelBase
  deadline?: number
}

export type CompleteOverview = {
  currentMainTask?: MainTaskListEntry
  evaluationTaskList: EvaluationTaskListEntry[]
  availableTasksStatus: {
    totalTasks: number
    urgentTasks: number
  }
  tutorDailyProgress: {
    completedMainTasks: number
    completedEvaluations: number
  }
  readyForNewTask: boolean
}
