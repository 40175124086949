import React, { useEffect, useState } from 'react'
import { useTransition, animated } from 'react-spring'
import { easeQuad } from 'd3-ease'

import { IExercise, ExerciseType, InjectedSoundProps } from '../../types'
import { ExerciseBaseProps } from '../exercises/types'
import {
  WordRaceExercise,
  ReadingExercise,
  BuildingExercise,
  ComprehensionExercise,
  ListeningExercise,
  withSoundEffectsContext
} from '../../index'
import { isTouchDevice } from '../exercises/BuildingExercise/BuildingExercise'

import './ExerciseTransition.scss'

export enum BookContext {
  reading = 'reading',
  listening = 'listening'
}

const getExerciseComponent = (
  type: ExerciseType,
  bookContext: BookContext = BookContext.reading
): React.FC<ExerciseBaseProps> => {
  switch (type) {
    case ExerciseType.ReadSentence: {
      if (bookContext === BookContext.listening) return ListeningExercise
      return ReadingExercise
    }
    case ExerciseType.MultiChoiseQuestion:
      return ComprehensionExercise
    case ExerciseType.ReadWordRace:
      return WordRaceExercise
    case ExerciseType.SentenceBuilder:
    case ExerciseType.WordBuilder:
      return BuildingExercise
  }
}

interface IProps extends Omit<ExerciseBaseProps, 'exercise'>, InjectedSoundProps {
  currentIndex: number
  exercises: IExercise[]
  trackExerciseActivity: (exerciseId: string, exerciseType: ExerciseType, bookId?: string) => void
  bookId?: string
  demo?: boolean
  bookContext?: BookContext
}

const ExerciseTransition: React.FC<IProps> = ({
  exercises,
  currentIndex,
  onCurrentExerciseCompleted,
  getSanaScore,
  trackExerciseActivity,
  displayDetailedScore = false,
  bookId,
  playSound,
  country,
  bookContext,
  demo
}) => {
  const [animate, setAnimate] = useState(false)
  const transitions = useTransition(exercises[currentIndex], (exercise) => exercise._id, {
    from: { opacity: 1, transform: 'translateX(100%)' },
    enter: { opacity: 1, transform: 'translateX(0)' },
    leave: { opacity: 0.9, transform: 'translateX(0)' },
    config: {
      duration: 660, // if you change this, change the overlay animation duration in .scss too
      easing: easeQuad
    }
  })

  useEffect(() => {
    const exercise = exercises[currentIndex]
    setAnimate(true)
    if (exercise) {
      playSound('transition')
      try {
        trackExerciseActivity(exercise._id, exercise.type, bookId)
      } catch (e) {}
    }
    // eslint-disable-next-line
  }, [currentIndex, exercises])

  if (isTouchDevice()) {
    const ExerciseComponent = getExerciseComponent(exercises[currentIndex].type, bookContext)
    const exerciseProps: ExerciseBaseProps = {
      exercise: exercises[currentIndex],
      country,
      demo,
      getSanaScore,
      onCurrentExerciseCompleted,
      displayDetailedScore
    }
    return (
      <div className={`exercise-transition${animate ? '--animate' : ''}`} onAnimationEnd={() => setAnimate(false)}>
        <ExerciseComponent {...exerciseProps} />
      </div>
    )
  }

  return (
    <div className="exercise-transition">
      {transitions.map(({ item, props, key, state }) => {
        const ExerciseComponent = getExerciseComponent(item.type, bookContext)
        const exerciseProps: ExerciseBaseProps = {
          exercise: item,
          country,
          demo,
          getSanaScore,
          onCurrentExerciseCompleted,
          displayDetailedScore
        }
        return (
          <animated.div key={key} style={{ ...props }} className="exercise-transition__item">
            {state === 'leave' && <div className="exercise-transition__overlay" />}
            <ExerciseComponent {...exerciseProps} />
          </animated.div>
        )
      })}
    </div>
  )
}

export default withSoundEffectsContext(ExerciseTransition)
