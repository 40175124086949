import React from 'react'

import Achievement from '../../audio/achievement.mp3'
import CircularProgressEnd from '../../audio/circular-progress-end.mp3'
import CircularProgressLoading from '../../audio/circular-progress-loading.mp3'
import Correct from '../../audio/correct.mp3'
import Incorrect from '../../audio/incorrect.mp3'
import StarProgress from '../../audio/star-progress.mp3'
import Transition from '../../audio/transition.mp3'
import Intro from '../../audio/intro.mp3'

import ExerciseFinished0 from '../../audio/exercise_finished/EX_FINISHED_alt_0.mp3'
import ExerciseFinished1 from '../../audio/exercise_finished/EX_FINISHED_alt_1.mp3'
import ExerciseFinished2 from '../../audio/exercise_finished/EX_FINISHED_alt_2.mp3'
import ExerciseFinished3 from '../../audio/exercise_finished/EX_FINISHED_alt_3.mp3'
import ExerciseFinished4 from '../../audio/exercise_finished/EX_FINISHED_alt_4.mp3'
import ExerciseFinished5 from '../../audio/exercise_finished/EX_FINISHED_alt_5.mp3'
import ExerciseFinished6 from '../../audio/exercise_finished/EX_FINISHED_alt_6.mp3'
import ExerciseFinished7 from '../../audio/exercise_finished/EX_FINISHED_alt_7.mp3'
import ExerciseFinished8 from '../../audio/exercise_finished/EX_FINISHED_alt_8.mp3'
import ExerciseFinished9 from '../../audio/exercise_finished/EX_FINISHED_alt_9.mp3'
import ExerciseFinished10 from '../../audio/exercise_finished/EX_FINISHED_alt_10.mp3'

import { TrackName, ISoundEffectsContext } from '../../types'

interface ITrackPair {
  name: TrackName
  url: string[]
}

const TRACKS: ITrackPair[] = [
  { name: 'achievement', url: [Achievement] },
  { name: 'circular-progress-end', url: [CircularProgressEnd] },
  { name: 'circular-progress-loading', url: [CircularProgressLoading] },
  { name: 'correct', url: [Correct] },
  { name: 'incorrect', url: [Incorrect] },
  {
    name: 'exercise-finished',
    url: [
      ExerciseFinished0,
      ExerciseFinished1,
      ExerciseFinished2,
      ExerciseFinished3,
      ExerciseFinished4,
      ExerciseFinished5,
      ExerciseFinished6,
      ExerciseFinished7,
      ExerciseFinished8,
      ExerciseFinished9,
      ExerciseFinished10
    ]
  },
  { name: 'star-progress', url: [StarProgress] },
  { name: 'transition', url: [Transition] },
  { name: 'intro', url: [Intro] }
]

const invisibleStyle: React.CSSProperties = {
  width: 0,
  height: 0,
  margin: 0,
  padding: 0,
  opacity: 0,
  position: 'absolute',
  border: 'none'
}

const SoundEffectsContext = React.createContext<ISoundEffectsContext>(({} as unknown) as ISoundEffectsContext)

interface IState {
  tracks: { [key: string]: AudioBuffer[] }
}

// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext

class SoundEffectsProvider extends React.Component<any, IState> {
  constructor(props: any) {
    super(props)
    if (AudioContext) {
      this.baseAudioContext = new AudioContext()
    } else {
      alert("Sorry, but the Web Audio API is not supported by your browser. The application won't work properly.")
    }
  }

  private baseAudioContext: BaseAudioContext | undefined
  state: IState = {
    tracks: {}
  }

  componentDidMount() {
    this.loadAllSounds()
  }

  private simulateUserAction = (callback: () => void) => {
    const button = document.getElementById('sound-effects-helper')
    if (button) {
      button.addEventListener('click', callback)
      button.click()
      button.removeEventListener('click', callback)
    }
  }

  loadAllSounds = () => {
    this.simulateUserAction(() => {
      if (this.baseAudioContext) {
        TRACKS.forEach(({ name, url }) => {
          url.forEach((u) => this.loadAudio(name, u))
        })
      }
    })
  }

  loadAudio = async (name: TrackName, url: string) => {
    if (!this.baseAudioContext) {
      console.warn('No audio context detected!')
      return
    }
    try {
      const rawData = await fetch(url)
      const arrayBuffer = await rawData.arrayBuffer()
      this.baseAudioContext.decodeAudioData(
        arrayBuffer,
        (audioFile) => {
          this.addAudio(name, audioFile)
        },
        (e) => console.warn(`Loading "${name}" track failed.`, e)
      )
    } catch (error) {
      console.warn(`Loading "${name}" track failed.`, error)
    }
  }

  addAudio = (name: TrackName, audioFile: AudioBuffer) => {
    this.setState((state) => ({
      ...state,
      tracks: {
        ...state.tracks,
        [name]: [...(state.tracks[name] || []), audioFile]
      }
    }))
  }

  playSound = (name: TrackName, onEnded?: () => void) =>
    this.simulateUserAction(() => {
      if (!this.baseAudioContext) return
      const { tracks } = this.state
      if (!tracks[name]) {
        console.warn(`No audio buffer "${name}"`)
        return
      }

      const startOffset = name === 'transition' ? 0.65 : 0
      const duration = name === 'exercise-finished' ? 3.1 : undefined

      const source = this.baseAudioContext.createBufferSource()
      source.connect(this.baseAudioContext.destination)
      source.buffer = tracks[name][Math.floor(Math.random() * tracks[name].length)]
      source.addEventListener('ended', () => {
        if (onEnded) onEnded()
      })
      source.start(0, startOffset, duration)
    })

  render() {
    return (
      <SoundEffectsContext.Provider
        value={{
          playSound: this.playSound
        }}>
        {this.props.children}
        <button style={invisibleStyle} id="sound-effects-helper" />
      </SoundEffectsContext.Provider>
    )
  }
}

const SoundEffectsConsumer = SoundEffectsContext.Consumer

export { SoundEffectsContext, SoundEffectsConsumer, SoundEffectsProvider }
