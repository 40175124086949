import React, { Component } from 'react'
import cx from 'classnames'

import Button from '../Button/Button'
import { ReactComponent as SpeakerIcon } from '../../graphics/icons/speaker.svg'
import { buildSanaVoiceSentenceURL } from '../../utils'

import './ListenSentence.scss'

interface IProps {
  sentence: string
  country?: string
  jump?: boolean
  recordingUrl?: string
  hideText?: boolean
  onPlay?: () => void
  onPause?: () => void
  onPlayError?: (error: Error) => void
}
interface IState {
  playing: boolean
}

class ListenSentence extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.audioSentence.onplay = () => this.setState({ playing: true })
    this.audioSentence.onended = () => this.setState({ playing: false })
    this.audioSentence.onpause = () => this.setState({ playing: false })
  }

  private audioSentence = new Audio(
    this.props.recordingUrl
      ? this.props.recordingUrl
      : buildSanaVoiceSentenceURL(this.props.sentence, this.props.country)
  )

  state: IState = {
    playing: false
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.sentence !== this.props.sentence) {
      this.audioSentence.pause()
      this.audioSentence = new Audio(buildSanaVoiceSentenceURL(this.props.sentence, this.props.country))
    }
  }

  componentWillUnmount() {
    this.audioSentence.pause()
  }

  private onClick = async () => {
    if (this.state.playing) {
      this.audioSentence.pause()
      this.props.onPause?.()
    } else {
      this.audioSentence.currentTime = 0
      try {
        await this.audioSentence.play()
        this.props.onPlay?.()
      } catch (error) {
        this.props.onPlayError && this.props.onPlayError(error as Error)
      }
    }
  }

  public stopPlaying = () => {
    if (this.state.playing) {
      this.audioSentence.pause()
    }
  }

  render() {
    const className = cx({
      'listen-sentence': !this.state.playing && !this.props.jump,
      'listen-sentence--playing': this.state.playing,
      'listen-sentence--jump': this.props.jump,
      'listen-sentence--no-text': this.props.hideText
    })

    return (
      <div className={className}>
        <Button variant="outlined" size="small" onClick={this.onClick}>
          <SpeakerIcon /> {this.props.hideText ? '' : 'Listen'}
        </Button>
      </div>
    )
  }
}

export default ListenSentence
