import { Button, Typography } from '@astrid/components'
import { Grid, makeStyles } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import {
  ScreenNames,
  trackButtonClickEvent,
  trackFinishCheckinTask,
  trackOpenCheckinTask,
  trackTypingEvent,
  trackVideoPauseEvent,
  trackVideoPlayEvent
} from 'analytics/analytics'
import { AlertContext } from 'components/AlertContext/AlertContext'
import { AutoSaveField } from 'components/AutoSaveField/AutoSaveField'
import { ConfirmPopup } from 'components/ConfirmPopup/ConfirmPopup'
import { VideoPreview } from 'components/Video/VideoPreview'
import { aspectRatio, VideoRecorder } from 'components/Video/VideoRecorder'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { Colors } from 'MuiTheme'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useCreateCheckInVideoMutation, useLoadActiveTasksQuery } from 'store/services/StudySet/studySetApi'
import { Confirmation } from './components/Confirmation'
import { Step, StepProps } from './components/Step'

const videoContainerWidth = 256
const videoContainerHeight = videoContainerWidth / aspectRatio

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
    '&:first-child': {
      marginTop: 0
    }
  },
  video: {
    borderRadius: theme.shape.borderRadius,
    width: videoContainerWidth
  },
  videoRecordingContainer: {
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    width: videoContainerWidth,
    height: videoContainerHeight,
    position: 'relative',
    background: Colors.veryLightGrey
  },
  deleteButton: {
    marginLeft: 'auto'
  },
  bottomSpace: {
    marginBottom: theme.spacing(2)
  },
  list: {
    paddingLeft: '1em'
  }
}))

type VideoUploadStatus =
  | { type: 'idle' }
  | { type: 'uploading'; percentage: number }
  | { type: 'uploaded' }
  | { type: 'error'; error: string }

export const Checkin = () => {
  const classes = useStyles()
  const { showAlert } = useContext(AlertContext)
  const { taskId, learnerId } = useParams<{ taskId: string; learnerId: string }>()
  const [video, setVideo] = useState<{ file: File; url: string } | null>(null)
  const [videoUploadStatus, setVideoUploadStatus] = useState<VideoUploadStatus>({ type: 'idle' })
  const attempt = useRef(0)
  const [createCheckInVideoMutation] = useCreateCheckInVideoMutation()
  const { task } = useLoadActiveTasksQuery(learnerId, {
    selectFromResult: ({ data }) => ({ task: data?.find((task) => task.taskId === taskId) })
  })
  const isVideoUploaded = videoUploadStatus.type === 'uploaded'

  useTrackScreenShown(isVideoUploaded ? ScreenNames.CheckInSent : ScreenNames.CheckIn)

  const handleSubmit = async () => {
    trackButtonClickEvent({ componentName: 'Send check-in video' })

    if (!video) {
      return
    }

    setVideoUploadStatus({ type: 'uploading', percentage: 0 })

    const formData = new FormData()
    formData.append('taskId', taskId)
    formData.append('video', video.file)

    try {
      await createCheckInVideoMutation({
        data: formData,
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentage = Math.floor((progressEvent.loaded / video.file.size) * 100)
          setVideoUploadStatus({ type: 'uploading', percentage })
        }
      }).unwrap()

      if (taskId) {
        trackFinishCheckinTask(taskId)
      }

      attempt.current = 0
      setVideoUploadStatus({ type: 'uploaded' })
    } catch (error) {
      const errorMessage = `Failed to upload video: ${error as string}`
      setVideoUploadStatus({ type: 'error', error: errorMessage })
      showAlert(errorMessage)
    }
  }

  useEffect(() => {
    trackOpenCheckinTask(taskId)
  }, [taskId])

  const stepProps: StepProps = {
    title: isVideoUploaded ? '' : 'Check-in',
    nextButtonProps: {
      title:
        videoUploadStatus.type === 'uploading' ? `Sending... ${videoUploadStatus.percentage}%` : 'Send check-in video',
      disabled: video === null,
      loading: videoUploadStatus.type === 'uploading' && { progressPercentage: videoUploadStatus.percentage },
      hideIcon: videoUploadStatus.type === 'uploading',
      onClick: isVideoUploaded ? undefined : handleSubmit
    }
  }

  return (
    <Step {...stepProps}>
      {isVideoUploaded ? (
        <Confirmation
          title="Check-in video sent!"
          learnerId={learnerId}
          onBackToProfile={() => trackButtonClickEvent({ componentName: 'back to learner profile' })}
          onBackToTaskList={() => trackButtonClickEvent({ componentName: 'back to task list' })}
        />
      ) : (
        <>
          {task ? (
            <Grid container spacing={4}>
              <Grid item xs={4}>
                {video ? (
                  <>
                    <VideoPreview
                      className={classes.video}
                      src={video.url}
                      onPlay={() => trackVideoPlayEvent({ componentName: 'Check-in' })}
                      onPause={() => trackVideoPauseEvent({ componentName: 'Check-in' })}
                      controls
                    />
                    <ConfirmPopup
                      onConfirm={() => {
                        setVideo(null)
                        trackButtonClickEvent({
                          componentName: 'delete check-in video',
                          interactionValue: 'delete'
                        })
                      }}
                      text="Are you sure you want to start over?"
                      cancelText="No"
                      confirmText="Yes">
                      <Button
                        variant="outlined"
                        label="Delete video"
                        icon={<Delete fontSize="small" />}
                        size="small"
                        className={classes.deleteButton}
                      />
                    </ConfirmPopup>
                  </>
                ) : (
                  <div className={classes.videoRecordingContainer}>
                    <VideoRecorder
                      onSubmit={(video) => setVideo({ file: video, url: URL.createObjectURL(video) })}
                      onStartRecording={() =>
                        trackButtonClickEvent({
                          componentName: 'record video',
                          componentValue: `attempt: ${++attempt.current}`,
                          interactionValue: 'start'
                        })
                      }
                      width={videoContainerWidth}
                      height={videoContainerHeight}
                      autosave
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={8}>
                <AutoSaveField
                  placeholder="Write comments for yourself here."
                  onBlur={(value: string) =>
                    trackTypingEvent({ componentName: 'Check-in video notes', componentValue: value })
                  }
                />
                <Typography variant="h4" className={classes.heading}>
                  Check-in
                </Typography>
                <Typography variant="body">How engaged was the learner this week?</Typography>
                <Typography variant="h4" className={classes.heading}>
                  Instructions
                </Typography>
                <Typography variant="body" className={classes.bottomSpace}>
                  In this video you should celebrate when the learner is hitting our recommended usage targets, and/or
                  encourage them to do more in specific areas.
                </Typography>
                <Typography variant="body">
                  Information on the learner's usage is in the "Learner's activity" section. Targets: 60 minutes/week; 1
                  Tutor Set/week; 2 Reading and Listening stories/week.
                </Typography>
                <Typography variant="h4" className={classes.heading}>
                  Tips
                </Typography>
                <ul className={classes.list}>
                  <li>
                    <Typography variant="body">Video length: 20-30 seconds</Typography>
                  </li>
                  <li>
                    <Typography variant="body">
                      Make the video personal by saying the learner's name and referring to their usage
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body">
                      Prioritize feedback on Minutes studied and Tutor Sets if either of these metrics is red
                    </Typography>
                  </li>
                </ul>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Typography variant="body">Could not find task.</Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Step>
  )
}
