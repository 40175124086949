import React, { useState, useEffect } from 'react'

import './CircularProgressBar.scss'

interface IProps {
  size: number
  percentage: number
  strokeWidth?: number
  progressColor?: string
  backgroundColor?: string
  showPercentage?: boolean
  justForShow?: boolean
  onAnimationEnd?: () => {}
}

interface IState {
  progress: number
}

class CircularProgressBar extends React.Component<IProps, IState> {
  state: IState = {
    progress: 0
  }
  private circularProgressInterval: any

  componentDidMount() {
    this.setupInterval()
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.percentage !== prevProps.percentage) {
      this.setupInterval()
    }
  }

  componentWillUnmount() {
    clearInterval(this.circularProgressInterval)
  }

  private setupInterval = () =>
    setInterval(() => {
      this.setState(({ progress }) => {
        const newProgress = progress + 1
        let { percentage, onAnimationEnd, justForShow } = this.props
        percentage = percentage > 100 ? 100 : percentage

        if (percentage === 0) {
          this.stopInterval()
          if (onAnimationEnd) onAnimationEnd()
          return { progress: 0 }
        }

        if (justForShow) {
          if (newProgress < percentage) {
            return { progress: newProgress }
          } else {
            this.stopInterval()
            if (onAnimationEnd) onAnimationEnd()
            return { progress: percentage }
          }
        }

        if (progress > percentage) {
          return newProgress === 100 ? { progress: 0 } : { progress: newProgress }
        } else if (newProgress < percentage) {
          return { progress: newProgress }
        } else {
          this.stopInterval()
          if (onAnimationEnd) onAnimationEnd()
          return { progress: percentage }
        }
      })
    }, 20)

  private stopInterval = () => clearInterval(this.circularProgressInterval)

  render() {
    const {
      size,
      showPercentage,
      strokeWidth = 20,
      progressColor = 'black',
      backgroundColor = '#ddd',
      children
    } = this.props

    const { progress } = this.state
    const radius = (size - strokeWidth) / 2
    const viewBox = `0 0 ${size} ${size}`
    const dashArray = radius * Math.PI * 2 // Arc length at 100% coverage is the circle circumference
    const dashOffset = dashArray - (dashArray * progress) / 100 // Scale 100% coverage overlay with the actual percent

    return (
      <div className="circular-progress">
        <svg width={size} height={size} viewBox={viewBox}>
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            style={{
              fill: 'none',
              stroke: backgroundColor,
              strokeWidth: `${strokeWidth}px`
            }}
          />
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            transform={`rotate(-90 ${size / 2} ${size / 2})`} // Start progress marker at 12 O'Clock
            style={{
              fill: 'none',
              stroke: progressColor,
              strokeWidth: `${strokeWidth}px`,
              strokeDasharray: dashArray.toFixed(2),
              strokeDashoffset: dashOffset.toFixed(2),
              strokeLinecap: progress === 100 ? 'butt' : 'round'
            }}
          />
          {showPercentage && !children && (
            <text className="circle__text" x="50%" y="50%" dy=".3em" textAnchor="middle">
              {`${progress}%`}
            </text>
          )}
        </svg>
        <div className="circular-progress__children">{children}</div>
      </div>
    )
  }
}

export default CircularProgressBar
