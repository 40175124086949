import { Chip, ListenSentence, Typography } from '@astrid/components'
import { makeStyles } from '@material-ui/core'
import { getScoreColor, getSentenceParts } from 'helpers/Score'
import { Colors } from 'MuiTheme'
import React from 'react'
import { SanaScore } from 'store/services/StudySet/types'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
    width: '100%'
  },
  listenWrapper: {
    height: 46,
    width: 46
  },
  exercise: {
    flex: 1,
    marginTop: '-0.4rem',
    alignSelf: 'center'
  },
  attempts: {
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',

    '& .chip': {
      lineHeight: '46px'
    }
  }
}))

interface Props {
  sentence: string
  sanaScore?: SanaScore
  recordingUrl?: string
  attemptCount?: number
  onPlayAudio?: () => void
  onPauseAudio?: () => void
}

export const Sentence = ({ onPlayAudio, onPauseAudio, sentence, sanaScore, recordingUrl, attemptCount }: Props) => {
  const classes = useStyles()
  const sentenceParts = sanaScore ? getSentenceParts(sentence, sanaScore.word_scores) : []

  return (
    <div className={classes.wrapper}>
      <Typography variant="body" className={classes.exercise}>
        {sentenceParts.length > 0
          ? sentenceParts.map((sentencePart, index) => (
              <React.Fragment key={index}>
                {sentencePart.type === 'characters' ? (
                  <span style={{ color: Colors.green }}>{sentencePart.characters}</span>
                ) : (
                  <span style={{ color: getScoreColor(sentencePart.score) }}>{sentencePart.word}</span>
                )}
              </React.Fragment>
            ))
          : sentence}
      </Typography>
      <div className={classes.listenWrapper}>
        {!!sanaScore && (
          <ListenSentence
            sentence={sanaScore.target_phrase}
            recordingUrl={recordingUrl}
            onPlay={onPlayAudio}
            onPause={onPauseAudio}
            hideText
          />
        )}
      </div>

      <div className={classes.attempts}>{attemptCount !== undefined && <Chip>{attemptCount}</Chip>}</div>
    </div>
  )
}
