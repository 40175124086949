import { TutorEventing } from '@astrid/eventing'

export class Analytics {
  static identify(userId: string) {
    analytics.identify(userId)
  }

  static page() {
    analytics.page()
  }

  static track(event: TutorEventing.Event<object>) {
    analytics.track(event.name, event.props)
  }
}
