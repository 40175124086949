import { Button, Typography } from '@astrid/components'
import { CircularProgress, LinearProgress, makeStyles } from '@material-ui/core'
import { ArrowBackRounded, ArrowForwardRounded } from '@material-ui/icons'
import { Colors } from 'MuiTheme'
import React, { PropsWithChildren } from 'react'

interface NavigationButtonProps {
  title?: React.ReactNode
  disabled?: boolean
  loading?: boolean | { progressPercentage: number }
  hideIcon?: boolean
  onClick?: () => void
}

export interface StepProps {
  title: string
  nextButtonProps?: NavigationButtonProps
  previousButtonProps?: NavigationButtonProps
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  content: {
    flex: '1 1 auto',
    height: 0,
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingRight: 10
  },
  footer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 10
  },
  button: {
    lineHeight: 1,
    display: 'flex',
    gap: theme.spacing(1),
    position: 'relative',
    overflow: 'hidden',
    minWidth: 180,

    '& > *': {
      position: 'relative'
    }
  },
  loader: {
    color: 'grey'
  },
  progress: {
    background: 'transparent',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  },
  progressBar: {
    background: Colors.grey
  }
}))

export const Step = ({
  title,
  nextButtonProps = {},
  previousButtonProps = {},
  children
}: PropsWithChildren<StepProps>) => {
  const classes = useStyles()

  const {
    title: nextText,
    onClick: onNext,
    disabled: disableNext,
    loading: loadingNext,
    hideIcon: hideNextIcon
  } = nextButtonProps

  const {
    title: previousText,
    onClick: onPrevious,
    disabled: disablePrevious,
    loading: loadingPrevious,
    hideIcon: hidePreviousIcon
  } = previousButtonProps

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Typography variant="h3" className={classes.header}>
          {title}
        </Typography>
        {children}
      </div>
      {(onPrevious || onNext) && (
        <footer className={classes.footer}>
          {onPrevious ? (
            <Button
              className={classes.button}
              variant="outlined"
              size="small"
              onClick={onPrevious}
              disabled={disablePrevious || !!loadingPrevious}>
              {!hidePreviousIcon &&
                (loadingPrevious ? (
                  <CircularProgress className={classes.loader} size={18} />
                ) : (
                  <ArrowBackRounded />
                ))}{' '}
              {previousText}
            </Button>
          ) : (
            <span></span>
          )}
          {onNext ? (
            <Button
              className={classes.button}
              variant="filled"
              color="action"
              size="small"
              onClick={onNext}
              disabled={disableNext || !!loadingNext}>
              {typeof loadingNext === 'object' && (
                <LinearProgress
                  className={classes.progress}
                  classes={{ bar: classes.progressBar }}
                  variant="determinate"
                  value={loadingNext.progressPercentage}
                />
              )}
              <span>{nextText}</span>{' '}
              {!hideNextIcon &&
                (loadingNext ? <CircularProgress className={classes.loader} size={18} /> : <ArrowForwardRounded />)}
            </Button>
          ) : (
            <span></span>
          )}
        </footer>
      )}
    </div>
  )
}
