import { AstridLogo, ProfileIcon } from '@astrid/components'
import { makeStyles } from '@material-ui/core/styles'
import { FormatListBulletedRounded, SchoolRounded } from '@material-ui/icons'
import { LearnerList } from 'pages/learner-list/LearnerList'
import { Learner } from 'pages/learner/Learner'
import { Profile } from 'pages/profile/Profile'
import { Tasks } from 'pages/tasks/Tasks'
import React, { useEffect } from 'react'
import { Link, NavLink, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { Login } from './Auth/Login/Login'
import { CreatePassword } from './Auth/CreatePassword/CreatePassword'
import { ROUTES } from './routes'
import styles from './RouteStack.module.scss'
import { Analytics } from 'analytics/analyticsApi'
import { useLoadQuery } from 'store/services/Auth/authApi'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1440px',
    margin: '0 auto',
    padding: `0 ${theme.spacing(4)}px`
  },
  body: {
    maxWidth: '1440px',
    margin: '0 auto',
    padding: `0 ${theme.spacing(8)}px`
  },
  page: {
    margin: `${theme.spacing(2)}px auto`,

    // placeholder for sticky header to work properly
    '&::before': {
      content: '""',
      display: 'block',
      height: '60px'
    }
  },
  header: {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    position: 'fixed',
    width: '100%',
    height: '60px',
    top: 0,
    zIndex: 1400
  },
  headerContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logoLink: {
    height: '50%'
  },
  logo: {
    width: 'auto',
    height: '100%'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem'
  }
}))

export const RouteStack = () => {
  const location = useLocation()
  const classes = useStyles()
  const { data: user, isLoading } = useLoadQuery()

  useEffect(() => {
    if (user) {
      Analytics.identify(user._id)
    }
  }, [user])

  useEffect(() => {
    Analytics.page()
  }, [location])

  const loggedIn = user !== undefined

  if (isLoading) {
    return <></>
  }

  return loggedIn ? (
    <>
      <header className={classes.header}>
        <div className={`${classes.container} ${classes.headerContent}`}>
          <Link to="/" className={classes.logoLink}>
            <AstridLogo className={classes.logo} />
          </Link>
          <div className={classes.controls}>
            <NavLink className={styles.link} to={ROUTES.TASKS} activeClassName={styles.activeLink}>
              <FormatListBulletedRounded /> Task list
            </NavLink>
            <NavLink className={styles.link} to={ROUTES.LEARNERS} activeClassName={styles.activeLink}>
              <SchoolRounded /> Learners
            </NavLink>
            <NavLink className={styles.link} to={ROUTES.PROFILE} activeClassName={styles.activeLink}>
              <ProfileIcon /> Profile
            </NavLink>
          </div>
        </div>
      </header>
      <div className={`${classes.body} ${classes.page}`}>
        <Switch>
          <Route exact path={ROUTES.TASKS} component={Tasks} />
          <Route exact path={`${ROUTES.LEARNERS}/:learnerId`} component={Learner} />
          <Route exact path={`${ROUTES.LEARNERS}/:learnerId/studyset/:studySetId/:step`} component={Learner} />
          <Route exact path={`${ROUTES.LEARNERS}/:learnerId/checkin/:taskId`} component={Learner} />
          <Route exact path={`${ROUTES.LEARNERS}`} component={LearnerList} />
          <Route exact path={ROUTES.PROFILE} component={Profile} />
          <Redirect to={ROUTES.TASKS} />
        </Switch>
      </div>
    </>
  ) : (
    <Switch>
      <Route exact path={ROUTES.ROOT} component={Login} />
      <Route exact path={ROUTES.CREATE_PASSWORD} component={CreatePassword} />
      <Route exact path={ROUTES.RESET_PASSWORD} component={CreatePassword} />
      <Redirect to={ROUTES.ROOT} />
    </Switch>
  )
}
