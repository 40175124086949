import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { TextInput, Button, Typography } from '../..'
import { ValidationRules } from '../../utils/index'
import './PasswordChangeForm.scss'
import CancelConfirmButtons from '../CancelConfirmButtons/CancelConfirmButtons'

interface PasswordChangeFormProps {
  onCancel: () => void
  onSubmit: (newPass: string) => void
}

const validationSchema = Yup.object().shape({
  currentPass: ValidationRules.required,
  newPass: ValidationRules.password,
  newPassRepeat: Yup.string()
    .oneOf([Yup.ref('newPass'), ''], "Passwords don't match")
    .required('Required')
})

const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({ onSubmit, onCancel }) => {
  return (
    <Formik
      initialValues={{
        currentPass: '',
        newPass: '',
        newPassRepeat: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(values.newPass)
      }}>
      {({ values, handleSubmit, touched, errors, handleChange, setFieldTouched }) => (
        <form className="password-change-form">
          <Typography variant="h3">Change Password</Typography>
          <TextInput
            type="password"
            value={values.currentPass}
            name="currentPass"
            label="Current password"
            error={touched.currentPass && !!errors.currentPass}
            helperText={(touched.currentPass && errors.currentPass) || ''}
            onChange={handleChange('currentPass')}
            onBlur={() => setFieldTouched('currentPass')}
          />
          <TextInput
            type="password"
            value={values.newPass}
            name="newPass"
            label="New password"
            error={touched.newPass && !!errors.newPass}
            helperText={(touched.newPass && errors.newPass) || ''}
            onChange={handleChange('newPass')}
            onBlur={() => setFieldTouched('newPass')}
          />
          <TextInput
            type="password"
            value={values.newPassRepeat}
            name="newPassRepeat"
            label="New password repeat"
            error={touched.newPassRepeat && !!errors.newPassRepeat}
            helperText={(touched.newPassRepeat && errors.newPassRepeat) || ''}
            onChange={handleChange('newPassRepeat')}
            onBlur={() => setFieldTouched('newPassRepeat')}
          />
          <CancelConfirmButtons
            className="password-change-form__buttons"
            onCancel={onCancel}
            onConfirm={handleSubmit}
            confirmText="Change password"
          />
        </form>
      )}
    </Formik>
  )
}

export default PasswordChangeForm
