import { Link, makeStyles } from '@material-ui/core'
import { CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@material-ui/icons'
import { Colors } from 'MuiTheme'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

export type TaskStatus = 'disabled' | 'todo' | 'done'

export interface TaskProps {
  status?: TaskStatus
  link: string
  title: string
  onClick?: () => void
}

const useStyles = makeStyles((theme) => ({
  task: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: 'black'
  },

  doneIcon: {
    color: Colors.green
  },

  todoIcon: {
    color: Colors.lightGrey
  },

  disabledTitle: {
    color: Colors.lightGrey
  },

  doneTitle: {
    color: theme.palette.text.secondary
  }
}))

export const Task = ({ status, link, title, onClick }: TaskProps) => {
  const classes = useStyles()

  const Task = () => (
    <span className={classes.task}>
      {status === 'done' && (
        <>
          <CheckBoxRounded className={classes.doneIcon} />
          <span className={classes.doneTitle}>{title}</span>
        </>
      )}

      {status === 'disabled' && (
        <>
          <CheckBoxOutlineBlankRounded className={classes.todoIcon} />
          <span className={classes.disabledTitle}>{title}</span>
        </>
      )}

      {(status === 'todo' || status === undefined) && (
        <>
          <CheckBoxOutlineBlankRounded className={classes.todoIcon} />
          {title}
        </>
      )}
    </span>
  )

  return status === 'disabled' ? (
    <Task />
  ) : (
    <Link component={RouterLink} to={link} underline="none" onClick={() => onClick && onClick()}>
      <Task />
    </Link>
  )
}
