import React, { useEffect, useRef } from 'react'

interface Props extends React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
  duration?: number
}

export const VideoPreview = (props: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    if (videoRef.current) {
      // hack to make video seekable
      videoRef.current.currentTime = props.duration ?? 600 // fallback to something large (seconds)
      timeout = setTimeout(() => {
        if (videoRef.current) {
          videoRef.current.currentTime = 0
        }
      }, 300)
    }

    return () => clearTimeout(timeout)
  }, [props.duration])

  return (
    <video {...props} ref={videoRef} controls>
      <source src={props.src} />
    </video>
  )
}
