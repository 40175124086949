import React, { useCallback } from 'react'
import './Chip.scss'

const Chip: React.FC<{ avatar?: React.ComponentElement<any, any> | string }> = ({ children, avatar = '' }) => (
  <div className="chip">
    {avatar}
    {children}
  </div>
)

const ChipAvatar: React.FC<{ style: React.CSSProperties }> = ({ children, style }) => (
  <span className="chip__avatar" style={style}>
    {children}
  </span>
)

interface ChipsFactoryProps {
  data: any[]
  textKey: string
  sortKey?: string
  bgColor?: string
  avatar?: boolean
  textModifier?: (text: string) => string
  className?: string
  style?: React.CSSProperties
}

const ChipsFactory: React.FC<ChipsFactoryProps> = ({
  data,
  sortKey,
  textKey,
  bgColor,
  avatar,
  textModifier,
  className,
  style
}) => {
  const dataToDisplay = sortKey ? [...data].sort((a, b) => a[sortKey] - b[sortKey]) : data

  const textToDisplay = useCallback(
    (item: any) => {
      if (textKey in item) {
        return textModifier ? textModifier(item[textKey]) : item[textKey]
      }
      console.error('ChipFactory: Provided textKey is invalid.', 'Item:', item, 'textKey:', textKey)
    },
    [textKey, data, textModifier]
  )

  return (
    <div className={`chips-factory ${className ? className : ''}`} style={style}>
      {dataToDisplay.map((item, i) => (
        <span key={`customChip${i}`}>
          <Chip avatar={avatar ? <ChipAvatar style={{ backgroundColor: bgColor }}>{i + 1}</ChipAvatar> : undefined}>
            {textToDisplay(item)}
          </Chip>
        </span>
      ))}
    </div>
  )
}

export { Chip, ChipAvatar, ChipsFactory }
