import { Card, Typography } from '@astrid/components'
import { Grid, makeStyles } from '@material-ui/core'
import { ScreenNames } from 'analytics/analytics'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import React from 'react'
import { useLoadActiveLearnersQuery } from 'store/services/Learner/learnerApi'
import { LearnerTable } from './components/LearnerTable'

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 0,
    marginTop: theme.spacing(2)
  },
  text: {
    marginBottom: theme.spacing(2)
  }
}))

export const LearnerList = () => {
  const classes = useStyles()
  const { data: activeLearners, isFetching, isError } = useLoadActiveLearnersQuery()

  useTrackScreenShown(ScreenNames.LearnerList)

  return (
    <>
      <Typography variant="h3" className={classes.heading}>
        Learners
      </Typography>
      <Typography variant="body" className={classes.text}>
        These are all currently active learners.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {activeLearners && activeLearners.length > 0 ? (
            <LearnerTable learners={activeLearners} />
          ) : (
            <Card>
              {activeLearners?.length === 0 && <Typography variant="body">No active learners</Typography>}
              {isFetching && <Typography variant="body">Loading...</Typography>}
              {isError && <Typography variant="body">Failed to active learners.</Typography>}
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  )
}
