import API from 'api/API'
import { AxiosError, AxiosRequestConfig } from 'axios'

export const baseQuery = (requestConfig: AxiosRequestConfig) =>
  API(requestConfig)
    .then((result) => ({ data: result.data }))
    .catch((error: AxiosError | string | object) => {
      const data = error instanceof Error ? error.response?.data : error.toString()
      return { error: data }
    })
