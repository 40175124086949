import React from 'react'
import { AxiosPromise } from 'axios'

import { demoBookExercises } from './demoBookExercises'
import { ExerciseTransition, ScoreResponse, ArrowRight, ReplayIcon } from '../../index'
import { onRightOrLeftKeyboardArrows } from '../../utils'

import './DemoBook.scss'

interface DemoBookProps {
  getSanaScore: (audio: Blob, phrase: string) => AxiosPromise<ScoreResponse>
}

interface IState {
  index: number
}

class DemoBook extends React.Component<DemoBookProps, IState> {
  state: IState = {
    index: 0
  }
  private keyBindings: any

  componentDidMount() {
    this.keyBindings = this.bindKeyboardArrows()
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.keyBindings)
  }

  next = () => {
    this.setState(({ index }) => {
      if (index + 1 > demoBookExercises.length - 1) {
        return { index: 0 }
      } else {
        return { index: index + 1 }
      }
    })
  }

  prev = () => {
    this.setState(({ index }) => {
      if (index > 0) {
        return { index: index - 1 }
      }
    })
  }

  bindKeyboardArrows = () =>
    onRightOrLeftKeyboardArrows(
      () => {
        // right
        this.next()
      },
      () => {
        // left
        this.prev()
      }
    )

  render() {
    const { getSanaScore } = this.props
    return (
      <div className="demo-book">
        <ExerciseTransition
          currentIndex={this.state.index}
          country="US"
          demo
          trackExerciseActivity={() => {}}
          onCurrentExerciseCompleted={() => {}}
          exercises={demoBookExercises}
          getSanaScore={getSanaScore}
          displayDetailedScore={true}
        />
        <button onClick={this.next} className="demo-book__next-btn">
          {this.state.index === demoBookExercises.length - 1 ? (
            <>
              Repeat all sentences <ReplayIcon />
            </>
          ) : (
            <>
              Next phrase <ArrowRight />
            </>
          )}
        </button>
      </div>
    )
  }
}

export default DemoBook
