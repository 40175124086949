import { makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { ROUTES } from 'routes/routes'
import { StudySetModel } from 'store/services/StudySet/types'
import { steps } from '../../task/steps'
import { Task, TaskStatus } from '../../task/components/Task'

interface Props {
  studySet: StudySetModel
  onListItemClick?: (linkType: string) => void
}

const useStyles = makeStyles((theme) => ({
  taskList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,

    '& li': {
      margin: `${theme.spacing(1)}px 0`
    }
  }
}))

export const TaskList = ({ studySet, onListItemClick }: Props) => {
  const classes = useStyles()
  const { learnerId } = useParams<{ learnerId: string }>()

  const speakingChallengeEntries = studySet.speakingChallenge?.result?.log || []
  const speakingChallengeAttempt = speakingChallengeEntries.length > 0 && speakingChallengeEntries[0]
  const speakingChallengeFeedback = speakingChallengeEntries.length > 1 && speakingChallengeEntries[1]
  const speakingChallengeReformulation = speakingChallengeEntries.length > 2 && speakingChallengeEntries[2]
  const isEvaluationAvailable = speakingChallengeEntries.length > 3
  const areSelfStudiesCompleted = useMemo(
    () =>
      studySet.sections
        .map((section) => section.components)
        .flat()
        .every((study) => study.done),
    [studySet]
  )

  const getStatus = (isDisabled: boolean, isDone: boolean): TaskStatus => {
    if (isDisabled) {
      return 'disabled'
    }

    if (isDone) {
      return 'done'
    }

    return 'todo'
  }

  const tasks = [
    {
      ...steps[0],
      status: getStatus(!areSelfStudiesCompleted, !!studySet.tutorReviewedState?.selfStudyResults)
    },
    {
      ...steps[1],
      status: getStatus(!speakingChallengeAttempt, !!studySet.tutorReviewedState?.speakingChallenge)
    },
    {
      ...steps[2],
      status: getStatus(!speakingChallengeAttempt, !!speakingChallengeFeedback)
    },
    {
      ...steps[3],
      status: getStatus(!speakingChallengeReformulation, !!studySet.tutorReviewedState?.speakingChallengeReformulation)
    },
    {
      ...steps[4],
      status: getStatus(!isEvaluationAvailable, !!studySet.evaluation)
    }
  ]

  return (
    <ul className={classes.taskList}>
      {tasks.map((task, index) => (
        <li key={task.title}>
          <Task
            title={task.title}
            status={task.status}
            link={`${ROUTES.LEARNERS}/${learnerId}/studyset/${studySet._id}/${index}`}
            onClick={() => onListItemClick?.(task.title)}
          />
        </li>
      ))}
    </ul>
  )
}
