import React from 'react'

import { ReactComponent as Star } from '../../../graphics/icons/star.svg'
import Doodle1 from '../doodles/1.svg'
import Doodle2 from '../doodles/2.svg'
import Doodle3 from '../doodles/3.svg'
import { Book, BookProgress, BookType, CEFRLevels } from '../../../types'
import { ReadingIcon, ListeningIcon } from '../../../'

import {
  A11CEFR,
  A12CEFR,
  A13CEFR,
  A21CEFR,
  A22CEFR,
  A23CEFR,
  B11CEFR,
  B12CEFR,
  B13CEFR,
  B21CEFR,
  B22CEFR,
  B23CEFR,
  C1CEFR
} from '../../../'

import './BookCarouselItem.scss'

interface ICarouselItemProps {
  book: Book
  onClick: (bookId: string, bookTitle: string, bookType: BookType) => void
  progress?: BookProgress
  displayTypeIcon?: boolean
}

const CEFRIcon: React.FC<{ lvl: CEFRLevels }> = ({ lvl }) => {
  switch (lvl) {
    case CEFRLevels.A11:
      return <A11CEFR />
    case CEFRLevels.A12:
      return <A12CEFR />
    case CEFRLevels.A13:
      return <A13CEFR />
    case CEFRLevels.A21:
      return <A21CEFR />
    case CEFRLevels.A22:
      return <A22CEFR />
    case CEFRLevels.A23:
      return <A23CEFR />
    case CEFRLevels.B11:
      return <B11CEFR />
    case CEFRLevels.B12:
      return <B12CEFR />
    case CEFRLevels.B13:
      return <B13CEFR />
    case CEFRLevels.B21:
      return <B21CEFR />
    case CEFRLevels.B22:
      return <B22CEFR />
    case CEFRLevels.B23:
      return <B23CEFR />
    case CEFRLevels.C1plus:
      return <C1CEFR />
    default:
      return null
  }
}

const PLACEHOLDER_IMAGES = [Doodle1, Doodle2, Doodle3]

const BookCarouselItem: React.FC<ICarouselItemProps> = ({ book, progress, onClick, displayTypeIcon }) => {
  const insideShadow = 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 41%, rgba(0, 0, 0, 0.6) 108%)'
  const imageUrl = book.imageUrl || PLACEHOLDER_IMAGES[Math.floor(Math.random() * PLACEHOLDER_IMAGES.length)]
  const isBookReadingOrMix = book.bookType === BookType.Reading || book.bookType === BookType.Mix
  const isBookListeningOrMix = book.bookType === BookType.Listening || book.bookType === BookType.Mix

  return (
    <div
      className="book-carousel-item"
      data-testid="BookCarouselItem"
      onClick={() => onClick(book._id, book.title, book.bookType)}>
      <div className="book-carousel-item__cover" style={{ backgroundImage: `${insideShadow}, url(${imageUrl})` }}>
        <div className="book-carousel-item__status">
          <div className="book-carousel-item__status--left">
            {progress?.index !== undefined && !progress.isCompleted && isBookReadingOrMix && (
              <span className="book-carousel-item__badge--jumpBack">Jump back in</span>
            )}
            {isBookReadingOrMix && progress?.isCompleted && (
              <span className="book-carousel-item__badge--read">✔ Read</span>
            )}
            {isBookListeningOrMix && progress?.isCompletedListening && (
              <span className="book-carousel-item__badge--read">✔ Listened</span>
            )}
            {book.recommended && <span className="book-carousel-item__badge--recommended">Recommended</span>}
          </div>
          <div className="book-carousel-item__status--right">
            <span className="book-carousel-item__badge--stars">
              <Star />
              {book.totalPoints}
            </span>
          </div>
        </div>
      </div>
      <div className="book-carousel-item__title">
        <div className="book-carousel-item__title-info">
          {book.cefrLevel && <CEFRIcon lvl={book.cefrLevel} />}
          {displayTypeIcon && isBookReadingOrMix && <ReadingIcon data-role="type" />}
          {displayTypeIcon && isBookListeningOrMix && <ListeningIcon data-role="type" />}
        </div>
        <span className="book-carousel-item__title-text">{book.title}</span>
      </div>
    </div>
  )
}

export default BookCarouselItem
