import { ListenSentence, Typography } from '@astrid/components'
import { makeStyles } from '@material-ui/core'
import { CheckBoxRounded, ContactSupportRounded } from '@material-ui/icons'
import { ExerciseImage } from 'pages/task/components/ExerciseImage'
import { Colors } from 'MuiTheme'
import React from 'react'
import {
  ComprehensionType,
  ExerciseResult,
  ExerciseResultType,
  MultiChoiceQuestionExerciseData
} from 'store/services/StudySet/types'
import { ExerciseOption } from 'pages/task/components/ExerciseOption'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  heading: {
    display: 'flex',
    gap: theme.spacing(1)
  },
  exercise: {
    flex: 1,
    marginTop: '-0.4rem'
  },
  answerText: {
    color: Colors.cobaltBlue
  },
  answerAudio: {
    '& .button--color-default.button--outlined': {
      borderColor: Colors.cobaltBlue,
      boxShadow: `0 4px ${Colors.cobaltBlue}`
    }
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    gap: theme.spacing(2),
    listStyle: 'none'
  },
  attempt: {
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start'
  },
  listenBig: {
    width: 80,
    height: 60,

    '& *': {
      width: '100%',
      height: '100%'
    }
  }
}))

interface Props {
  exerciseResult: ExerciseResult
  noHeading?: boolean
}

export const ComprehensionQuestion = ({ exerciseResult, noHeading }: Props) => {
  const classes = useStyles()
  const data = exerciseResult.exercise.data as MultiChoiceQuestionExerciseData
  const exerciseScore =
    exerciseResult.exerciseResultType === ExerciseResultType.NonReadingScore && exerciseResult.exerciseScore

  let options: string[] = []
  if (data.answerType === ComprehensionType.TEXT) {
    options = data.optionsUs || []
  } else if (data.answerType === ComprehensionType.IMAGE) {
    data.imageOption1 && options.push(data.imageOption1)
    data.imageOption2 && options.push(data.imageOption2)
    data.imageOption3 && options.push(data.imageOption3)
    data.imageOption4 && options.push(data.imageOption4)
  } else if (data.answerType === ComprehensionType.AUDIO) {
    data.audioOptionUs1 && options.push(data.audioOptionUs1)
    data.audioOptionUs2 && options.push(data.audioOptionUs2)
    data.audioOptionUs3 && options.push(data.audioOptionUs3)
    data.audioOptionUs4 && options.push(data.audioOptionUs4)
  }

  return (
    <>
      {!noHeading && (
        <Typography variant="h4" className={classes.heading}>
          <ContactSupportRounded /> Question
        </Typography>
      )}
      <div className={classes.wrapper}>
        {data.questionType === ComprehensionType.IMAGE && data.imageQuestion && (
          <ExerciseImage src={data.imageQuestion} />
        )}
        {data.questionType === ComprehensionType.AUDIO && data.audioQuestionUs && (
          <div className={classes.listenBig}>
            <ListenSentence recordingUrl={data.audioQuestionUs} sentence="" hideText />
          </div>
        )}
        <div className={classes.exercise}>
          <Typography variant="body">{data.questionUs}</Typography>

          <ul className={classes.options}>
            {options.map((option, index) => (
              <li key={option}>
                {data.answerType === ComprehensionType.TEXT && (
                  <ExerciseOption correct={index === data.answerUs}>
                    <Typography
                      variant="body"
                      color="secondary"
                      className={index === data.answerUs ? classes.answerText : ''}>
                      {option}
                    </Typography>
                  </ExerciseOption>
                )}
                {data.answerType === ComprehensionType.IMAGE && (
                  <ExerciseOption correct={index === data.answerUs}>
                    <ExerciseImage src={option} />
                  </ExerciseOption>
                )}
                {data.answerType === ComprehensionType.AUDIO && (
                  <div className={index === data.answerUs ? classes.answerAudio : ''}>
                    <ListenSentence recordingUrl={option} sentence="" hideText />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className={classes.attempt}>
          {!!exerciseScore && (
            <CheckBoxRounded
              fontSize="large"
              htmlColor={exerciseResult.exerciseScore.score === 100 ? Colors.green : Colors.rosyPink}
            />
          )}
        </div>
      </div>
    </>
  )
}
