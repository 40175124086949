import React from 'react'

import { TwistedArrowIcon, CloseIcon, MicrophoneIcon } from '../..'

import './PermissionsPopup.scss'
import Typography from '../Typography/Typography'

interface Props {
  open: boolean
  onClose: () => void
}

const PermissionsPopup: React.FC<Props> = ({ onClose, open }) => {
  if (!open) return null
  return (
    <div className="permissions-popup">
      <div className="permissions-popup__content">
        <CloseIcon onClick={onClose} />
        <div className="permissions-popup__icons">
          <TwistedArrowIcon />
          <MicrophoneIcon />
        </div>
        <Typography variant="h4">
          Please <span>Allow</span> Astrid to use your microphone.
        </Typography>
        <Typography variant="body" color="secondary">
          Astrid works by listening to students read out loud, and giving them feedback.
        </Typography>
      </div>
    </div>
  )
}

export default PermissionsPopup
