import React, { useState, useEffect, useRef } from 'react'

import { WordScore } from '../../../../types'
import { Typography } from '../../../../index'

import './WordWithScore.scss'

interface WordScoreProps {
  score: WordScore
  demo?: boolean
}

const WordWithScore: React.FC<WordScoreProps> = ({ score, demo }) => {
  const [popupOpen, setPopupOpen] = useState(false)
  const tableRef = useRef<HTMLTableElement>(null)
  const subjectiveScore = score.score < 40 ? 'wrong' : score.score < 75 ? 'ok' : 'great'

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleOutsideClick = (event: MouseEvent) => {
    if (!tableRef.current?.contains(event.target as Node)) {
      setPopupOpen(false)
    }
  }

  return (
    <div className="word-score">
      <table className={`word-score__popup${popupOpen ? '--open' : ''}`} ref={tableRef}>
        <thead>
          <tr>
            <th>Score</th>
            <th>IPA</th>
            <th>Sounded like</th>
          </tr>
        </thead>
        <tbody>
          {score.phoneme_scores.map(({ score, phoneme_ipa, sounds_like_ipa }, i) => (
            <tr
              key={`${phoneme_ipa}${i}`}
              className={`word-score__phoneme--${score < 40 ? 'wrong' : score < 75 ? 'ok' : 'great'}`}>
              <td>{score}</td>
              <td>{phoneme_ipa}</td>
              <td>{sounds_like_ipa}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Typography
        variant="exerciseM"
        onBlur={() => setPopupOpen(false)}
        onClick={() => setPopupOpen((state) => !state)}
        className={`word-score__word--${subjectiveScore}`}
        style={{ backgroundColor: popupOpen ? 'rgba(0,0,0,0.07)' : 'transparent' }}>
        {score.word}
      </Typography>
      {demo && score.score < 80 && (
        <div className="word-score__sounded-like">
          {score.phoneme_scores.map(({ sounds_like }, i) => (
            <Typography variant="label" key={`${sounds_like}${i}`}>
              {sounds_like}
            </Typography>
          ))}
        </div>
      )}
    </div>
  )
}

export default WordWithScore
